import i18n from "i18n-js";
import React from "react";
import {
    Button,
    IconButton,
    Link,
    makeStyles,
    Modal,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { slideInUp, slideOutUp } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import {
    AdditionalServiceItemClient,
    AdditionalServiceItemClientCounted,
    DiscountCampaignItem,
    MenuItemClient,
    Utils,
} from "visit-shared";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NumberInput from "../components/NumberInput";
import { isMobile } from "react-device-detect";
import { Dimensions, theme } from "../style/theme";
import { GetServiceAllowedPercentage } from "../components/ConfirmDialog";

const animationStyles = StyleSheet.create({
    slideInDown: {
        animationName: slideInUp,
        animationDuration: "0.5s",
    },
    slideOutDown: {
        animationName: slideOutUp,
        animationDuration: "0.5s",
    },
});

const useStyles = makeStyles((theme) => ({
    blendInBackground: {
        color: theme.palette.background.default,
    },
    additionalServicesHeader: {
        position: "absolute",
        top: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: theme.palette.secondary.main,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        boxShadow: "0px 0px 20px 5px " + theme.palette.secondary.main + "99",
        color: theme.palette.common.white,
        zIndex: 1,
    },
    additionalServicesHeaderBelow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    titleText: {
        flex: 1,
        paddingTop: 5,
        fontFamily: theme.typography.fontFamily,
        fontWeight: "bold",
        textAlign: "center",
        webkitTouchCallout: "none" /* iOS Safari */,
        webkitUserSelect: "none" /* Safari */,
        khtmlUserSelect: "none" /* Konqueror HTML */,
        mozUserSelect: "none" /* Old versions of Firefox */,
        msUserSelect: "none" /* Internet Explorer/Edge */,
        userSelect: "none" /* Non-prefixed version */,
    },
    priceCaptionColor: {
        color: theme.palette.background.paper,
    },
    priceText: {
        flex: 1,
        paddingBottom: 5,
        fontFamily: theme.typography.fontFamily,
        fontWeight: "bold",
        textAlign: "center",
        webkitTouchCallout: "none" /* iOS Safari */,
        webkitUserSelect: "none" /* Safari */,
        khtmlUserSelect: "none" /* Konqueror HTML */,
        mozUserSelect: "none" /* Old versions of Firefox */,
        msUserSelect: "none" /* Internet Explorer/Edge */,
        userSelect: "none" /* Non-prefixed version */,
    },
    headerText: {
        paddingTop: 5,
        paddingBottom: 10,
        fontFamily: theme.typography.fontFamily,
        fontWeight: "bold",
        textAlign: "center",
        color: theme.palette.secondary.main,
    },
    footerText: {
        paddingTop: 15,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        textAlign: "center",
    },
    tableContainer: {
        borderRadius: 20,
        borderColor: theme.palette.secondary.main,
        borderStyle: "solid",
        borderWidth: 1,
        width: "99.7%",
        boxShadow: "0px 0px 20px 5px " + theme.palette.secondary.main + "99",
    },
    tableRow: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.paper,
        },
    },
    headerCell: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontSize: 16,
    },
    headerNumericCell: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontSize: 16,
        textAlign: "right",
    },
    numericCell: {
        fontSize: 16,
        textAlign: "right",
    },
    nameCell: {
        fontSize: 16,
        wordBreak: "break-word",
    },
    description: {
        fontSize: 12,
        fontStyle: "italic",
    },
    table: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
        justifyContent: "top",
        backgroundColor: theme.palette.background.default,
    },
    containerBody: {
        padding: 4,
    },
    titleButton: {
        alignSelf: "center",
        width: 120,
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.default,
        borderRadius: 20,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: theme.palette.background.default,
        },
        margin: 10,
    },
    titleButtonBehind: {
        width: 120,
        fontWeight: "bold",
        color: theme.palette.background.default,
        backgroundColor: theme.palette.background.default,
        borderRadius: 20,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: theme.palette.background.default,
        },
        margin: 10,
    },
    priceOld: {
        textDecoration: theme.palette.error.main + " line-through",
    },
    priceDiscounted: {
        color: theme.palette.error.main,
    },
}));

interface AdditionalServicesProps {
    dimensions: Dimensions;
    open: boolean;
    onClose: any;
    additionalServices: AdditionalServiceItemClient[];
    selectedMenuItems: MenuItemClient[];
    selectedAdditionalServicesRef: React.MutableRefObject<
        AdditionalServiceItemClientCounted[]
    >;
    selectedDiscountCampaignItem: DiscountCampaignItem | null;
}

export default function AdditionalServicesScreen({
    dimensions,
    open,
    onClose,
    additionalServices,
    selectedMenuItems,
    selectedAdditionalServicesRef,
    selectedDiscountCampaignItem,
}: AdditionalServicesProps) {
    React.useEffect(() => {
        if (open) {
            // In case of few navigations creates to many states. Can this be handled better?
            window.history.pushState(null, "", window.location.href);
        }

        window.onpopstate = () => {
            handleClose();
        };

        return () => {
            window.onpopstate = null;
        };
    }, [open]);

    const classes = useStyles();

    const [additionalServicesLocal, setAdditionalServicesLocal] =
        React.useState<AdditionalServiceItemClientCounted[]>([]);

    const [additionalServicesSelected, setAdditionalServicesSelected] =
        React.useState(false);

    React.useEffect(() => {
        for (let i = 0; i < additionalServicesLocal.length; ++i) {
            if (additionalServicesLocal[i].count > 0) {
                setAdditionalServicesSelected(true);
                return;
            }
        }
        setAdditionalServicesSelected(false);
    }, [additionalServicesLocal]);

    const [currentAnimation, setCurrentAnimation] = React.useState(
        animationStyles.slideInDown
    );

    React.useEffect(() => {
        if (!open) {
            setAdditionalServicesLocal([]);
        } else {
            let services: AdditionalServiceItemClientCounted[] = [];
            additionalServices.forEach((item) => {
                services.push({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    price: item.price,
                    durationMins: item.durationMins,
                    allowMultiple: item.allowMultiple,
                    count: 0,
                });
            });
            setAdditionalServicesLocal(services);
        }
    }, [additionalServices, open]);

    const handleClose = () => {
        setCurrentAnimation(animationStyles.slideOutDown);
        setTimeout(() => {
            onClose(false);
            setCurrentAnimation(animationStyles.slideInDown);
        }, 500);
    };

    const saveSelectedAdditionalServices = () => {
        selectedAdditionalServicesRef.current = [];
        additionalServicesLocal.forEach((element) => {
            if (element.count > 0) {
                selectedAdditionalServicesRef.current.push(element);
            }
        });
    };

    const getTotalPrice = () => {
        let price: number = 0;
        price += selectedMenuItems.reduce(
            (a, b) =>
                a +
                b.price *
                    GetServiceAllowedPercentage(
                        b.id,
                        false,
                        selectedDiscountCampaignItem
                    ),
            0
        );

        price += additionalServicesLocal.reduce(
            (a, b) =>
                a +
                b.price *
                    b.count *
                    GetServiceAllowedPercentage(
                        b.id,
                        true,
                        selectedDiscountCampaignItem
                    ),
            0
        );

        return Utils.RoundToMoney(price);
    };

    return (
        <Modal
            open={open}
            hideBackdrop={true}
            className={css(currentAnimation)}
        >
            <div
                style={{
                    background: theme.palette.background.default,
                    minHeight: dimensions.height,
                }}
            >
                <div className={classes.additionalServicesHeader}>
                    <IconButton color="inherit" onClick={handleClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <div>
                        <Typography
                            variant={"h6"}
                            className={classes.titleText}
                        >
                            {selectedMenuItems
                                .map((menuItem) => menuItem.name)
                                .join(", ")}
                        </Typography>
                        <Typography
                            variant={"subtitle1"}
                            className={classes.priceText}
                        >
                            <span className={classes.priceCaptionColor}>
                                {`${i18n.t("totalCost")}: `}
                            </span>
                            {getTotalPrice()}
                        </Typography>
                    </div>
                    <Button
                        className={classes.titleButton}
                        color="secondary"
                        onClick={() => {
                            saveSelectedAdditionalServices();
                            onClose(true);
                        }}
                    >
                        {additionalServicesSelected
                            ? i18n.t("next")
                            : i18n.t("skip")}
                    </Button>
                </div>
                <div
                    style={{
                        maxHeight: dimensions.height,
                        overflow: "auto",
                    }}
                >
                    <div className={classes.additionalServicesHeaderBelow}>
                        <IconButton
                            className={classes.blendInBackground}
                            onClick={handleClose}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <div>
                            <Typography
                                variant={"h6"}
                                className={
                                    classes.titleText +
                                    " " +
                                    classes.blendInBackground
                                }
                            >
                                {selectedMenuItems
                                    .map((menuItem) => menuItem.name)
                                    .join(", ")}
                            </Typography>
                            <Typography
                                variant={"subtitle1"}
                                className={
                                    classes.priceText +
                                    " " +
                                    classes.blendInBackground
                                }
                            >
                                <span className={classes.blendInBackground}>
                                    {`${i18n.t("totalCost")}: `}
                                </span>
                                {getTotalPrice()}
                            </Typography>
                        </div>
                        <Button className={classes.titleButtonBehind}>
                            {additionalServicesSelected
                                ? i18n.t("next")
                                : i18n.t("skip")}
                        </Button>
                    </div>
                    <div className={classes.containerBody}>
                        <Typography
                            variant={"h6"}
                            className={classes.headerText}
                        >
                            {i18n.t("selectAdditionalService")}
                        </Typography>
                        <TableContainer className={classes.tableContainer}>
                            <Table
                                className={classes.table}
                                aria-label="customized table"
                            >
                                <colgroup>
                                    <col style={{ width: "70%" }} />
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "10%" }} />
                                </colgroup>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={classes.headerCell}
                                        >
                                            {i18n.t("title")}
                                        </TableCell>
                                        <TableCell
                                            className={
                                                classes.headerNumericCell
                                            }
                                        >
                                            {isMobile
                                                ? i18n.t("durationShort")
                                                : i18n.t("duration")}
                                        </TableCell>
                                        <TableCell
                                            className={
                                                classes.headerNumericCell
                                            }
                                        >
                                            {i18n.t("price")}
                                        </TableCell>
                                        <TableCell
                                            className={
                                                classes.headerNumericCell
                                            }
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {additionalServicesLocal.map((item) => (
                                        <TableRow
                                            className={classes.tableRow}
                                            key={item.id}
                                            onClick={() => {
                                                if (!item.allowMultiple)
                                                    setAdditionalServicesLocal(
                                                        additionalServicesLocal.map(
                                                            (iter) => {
                                                                if (
                                                                    iter.id ===
                                                                    item.id
                                                                )
                                                                    iter.count =
                                                                        item.count ===
                                                                        1
                                                                            ? 0
                                                                            : 1;
                                                                return iter;
                                                            }
                                                        )
                                                    );
                                            }}
                                        >
                                            <TableCell
                                                className={classes.nameCell}
                                                component="th"
                                                scope="row"
                                            >
                                                <div>
                                                    <Typography>
                                                        {item.name}
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.description
                                                        }
                                                    >
                                                        {item.description}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className={classes.numericCell}
                                            >
                                                {Utils.GetItemDurationString(
                                                    item.durationMins
                                                )}
                                            </TableCell>
                                            <TableCell
                                                className={classes.numericCell}
                                            >
                                                {selectedDiscountCampaignItem ===
                                                    null ||
                                                GetServiceAllowedPercentage(
                                                    item.id,
                                                    true,
                                                    selectedDiscountCampaignItem
                                                ) === 1 ? (
                                                    item.price
                                                ) : (
                                                    <div>
                                                        <div
                                                            className={
                                                                classes.priceOld
                                                            }
                                                        >
                                                            {item.price}
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.priceDiscounted
                                                            }
                                                        >
                                                            {Utils.RoundToMoney(
                                                                item.price *
                                                                    GetServiceAllowedPercentage(
                                                                        item.id,
                                                                        true,
                                                                        selectedDiscountCampaignItem
                                                                    )
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.allowMultiple ? (
                                                    <NumberInput
                                                        label={i18n.t("count")}
                                                        value={item.count}
                                                        onChange={(
                                                            value: number
                                                        ) => {
                                                            setAdditionalServicesLocal(
                                                                additionalServicesLocal.map(
                                                                    (iter) => {
                                                                        if (
                                                                            iter.id ===
                                                                            item.id
                                                                        )
                                                                            iter.count =
                                                                                value;
                                                                        return iter;
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                        errorText=""
                                                    />
                                                ) : (
                                                    <Switch
                                                        style={{
                                                            alignContent:
                                                                "center",
                                                        }}
                                                        checked={
                                                            item.count === 1
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography className={classes.footerText}>
                            {i18n.t("poweredBy")}
                            <Link href="/" color="secondary">
                                {i18n.t("appName")}
                            </Link>
                        </Typography>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
