import {
    AppBar,
    Box,
    makeStyles,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import React from "react";
import { Emails } from "visit-shared";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const policyEn = () => {
    return (
        <>
            <h1>Visit Application Privacy Policy</h1>

            <p>
                At Visit application or Visit website accessible from
                https://visitappt.com, one of our main priorities is the privacy
                of our visitors. This Privacy Policy document contains types of
                information that is collected and recorded by Visit and how we
                use it.
            </p>

            <p>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
            </p>

            <h2>Consent</h2>

            <p>
                By using our website or our mobile application (Android or iOS),
                you hereby consent to our Privacy Policy and agree to its terms.
            </p>

            <h2>Information we collect</h2>

            <p>
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information.
            </p>
            <p>
                If you contact us directly, we may receive additional
                information about you such as your name, email address, phone
                number, the contents of the message and/or attachments you may
                send us, and any other information you may choose to provide.
            </p>
            <p>
                When you register for an Account, we may ask for your contact
                information, including items such as name, date of birth, email
                address and telephone number.
            </p>

            <h2>How we use your information</h2>

            <p>
                We use the information we collect in various ways, including to:
            </p>

            <ul>
                <li>
                    Provide, operate, and maintain our website and mobile
                    application
                </li>
                <li>
                    Improve, personalize, and expand our website and mobile
                    application
                </li>
                <li>
                    Understand and analyze how you use our website and mobile
                    application
                </li>
                <li>
                    Develop new products, services, features, and functionality
                </li>
                <li>
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the website
                    and mobile application, and for marketing and promotional
                    purposes
                </li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
            </ul>

            <h2>Data Deletion</h2>
            <p>
                You can delete your account within Visit mobile application by
                navigating to "Profile Settings" and then to "Delete account"
                section. Please follow instructions on the screen to delete your
                account.
            </p>
            <p>
                Please note that your account will be marked as deleted and all
                data associated with your account will be deleted from our
                servers after 30 days. You can restore your account within 30
                days after deletion.
            </p>
            <p>
                Additionally, you can request your account deletion by
                contacting us via email <b> {Emails.supportEmail} </b>.
            </p>

            <h2>Log Files</h2>

            <p>
                Visit follows a standard procedure of using log files. These
                files log visitors when they visit websites. All hosting
                companies do this and a part of hosting services' analytics. The
                information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
            </p>

            <h2>Cookies and Web Beacons</h2>

            <p>
                Like any other website, Visit uses 'cookies'. These cookies are
                used to store information including visitors' preferences, and
                the pages on the website that the visitor accessed or visited.
                The information is used to optimize the users' experience by
                customizing our web page content based on visitors' browser type
                and/or other information.
            </p>

            <p>
                For more general information on cookies, please read{" "}
                <a href="https://www.cookieconsent.com/">
                    more on the Cookie Consent website
                </a>
                .
            </p>

            <h2>Advertising Partners Privacy Policies</h2>

            <p>
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of Visit.
            </p>

            <p>
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on Visit, which
                are sent directly to users' browser or mobile application. They
                automatically receive your IP address when this occurs. These
                technologies are used to measure the effectiveness of their
                advertising campaigns and/or to personalize the advertising
                content that you see on websites that you visit.
            </p>

            <p>
                Note that Visit has no access to or control over these cookies
                that are used by third-party advertisers.
            </p>

            <h2>Third Party Privacy Policies</h2>

            <p>
                Visit's Privacy Policy does not apply to other advertisers or
                websites. Thus, we are advising you to consult the respective
                Privacy Policies of these third-party ad servers for more
                detailed information. It may include their practices and
                instructions about how to opt-out of certain options.{" "}
            </p>

            <p>
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites.
            </p>

            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

            <p>
                Under the CCPA, among other rights, California consumers have
                the right to:
            </p>
            <p>
                Request that a business that collects a consumer's personal data
                disclose the categories and specific pieces of personal data
                that a business has collected about consumers.
            </p>
            <p>
                Request that a business delete any personal data about the
                consumer that a business has collected.
            </p>
            <p>
                Request that a business that sells a consumer's personal data,
                not sell the consumer's personal data.
            </p>
            <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
            </p>

            <h2>GDPR Data Protection Rights</h2>

            <p>
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
            </p>
            <p>
                The right to access – You have the right to request copies of
                your personal data.
            </p>
            <p>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
            </p>
            <p>
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
            </p>
            <p>
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
            </p>
            <p>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
            </p>
            <p>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
            </p>
            <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
            </p>

            <h2>Children's Information</h2>

            <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
            </p>

            <p>
                Visit does not knowingly collect any Personal Identifiable
                Information from children under the age of 13. If you think that
                your child provided this kind of information on our website, we
                strongly encourage you to contact us immediately and we will do
                our best efforts to promptly remove such information from our
                records.
            </p>

            <h2>Contact Us</h2>

            <p>
                If you have any questions about this Privacy Policy, You can
                contact us by email: <b>{Emails.supportEmail}</b>
            </p>
        </>
    );
};

const policyRu = () => {
    return (
        <>
            <h1> Политика конфиденциальности приложения Visit </h1>

            <p>
                Visit приложение или Visit веб-сайт, доступный с
                https://visitappt.com, одним из наших основных приоритетов
                является конфиденциальность наших посетителей. Этот документ
                Политики конфиденциальности содержит типы информации, которая
                собирается и записывается by Visit и как мы его используем.
            </p>

            <p>
                Если у вас есть дополнительные вопросы или вам нужна
                дополнительная информация о нашей Политике конфиденциальности,
                не стесняйтесь обращаться к нам.
            </p>

            <h2> Согласие </h2>

            <p>
                Используя наш веб-сайт или наше мобильное приложение (Android
                или iOS), вы соглашаетесь с нашей Политикой конфиденциальности и
                соглашаетесь с его условия.
            </p>

            <h2> Информация, которую мы собираем </h2>

            <p>
                Личная информация, которую вас просят предоставить, и причины,
                по которым вас просят предоставить это, будут указаны ясно для
                вас в тот момент, когда мы просим вас предоставить свой
                Персональные данные.
            </p>
            <p>
                Если вы обратитесь к нам напрямую, мы можем получить
                дополнительные информация о вас, такая как ваше имя, адрес
                электронной почты, номер телефона, содержание сообщения и / или
                вложения вы можете отправить нам и любую другую информацию,
                которую вы можете выбрать предоставлять.
            </p>
            <p>
                Когда вы регистрируете учетную запись, мы можем запросить ваш
                контактная информация, включая такие элементы, как имя, дата
                рождение, адрес электронной почты и номер телефона.
            </p>

            <h2> Как мы используем вашу информацию </h2>

            <p>
                Мы используем информацию, которую собираем, различными
                способами, в том числе к:
            </p>

            <ul>
                <li>
                    Предоставлять, эксплуатировать и поддерживать наш веб-сайт и
                    мобильные устройства заявление
                </li>
                <li>
                    Улучшайте, персонализируйте и расширяйте наш веб-сайт и
                    мобильные устройства заявление
                </li>
                <li>
                    Понять и проанализировать, как вы используете наш веб-сайт и
                    мобильное приложение
                </li>
                <li>
                    Разрабатывать новые продукты, услуги, функции и
                    функциональность
                </li>
                <li>
                    Общайтесь с вами напрямую или через один из нашим партнерам,
                    в том числе по обслуживанию клиентов, для предоставления вас
                    с обновлениями и другой информацией, относящейся к веб-сайт
                    и мобильное приложение, а также для маркетинга и рекламные
                    цели
                </li>
                <li> Отправлять вам электронные письма </li>
                <li> Найдите и предотвратите мошенничество. </li>
            </ul>

            <h2>Удаление данных</h2>
            <p>
                Вы можете удалить свою учетную запись в мобильном приложении
                Visit, перейдя в «Настройки профиля», а затем в раздел «Удалить
                учетную запись». Следуйте инструкциям на экране, чтобы удалить
                свою учетную запись.
            </p>
            <p>
                Обратите внимание, что ваша учетная запись будет помечена как
                удаленная, и все данные, связанные с вашей учетной записью,
                будут удалены с наших серверов через 30 дней. Вы можете
                восстановить свою учетную запись в течение 30 дней после
                удаления.
            </p>
            <p>
                Кроме того, вы можете запросить удаление своей учетной записи,
                связавшись с нами по электронной почте{" "}
                <b>{Emails.supportEmail}</b>.
            </p>

            <h2> Файлы журнала </h2>

            <p>
                Посещение следует стандартной процедуре использования файлов
                журнала. Эти файлы регистрируют посетителей, когда они посещают
                веб-сайты. Весь хостинг компании занимаются этим и частью
                аналитики хостинговых услуг. Информация, собираемая файлами
                журнала, включает Интернет протокол (IP) адреса, тип браузера,
                интернет-сервис Провайдер (ISP), отметка даты и времени,
                страницы перехода / выхода, и, возможно, количество кликов. Они
                не связаны с любая информация, позволяющая установить личность.
                Цель информации предназначена для анализа тенденций,
                администрирования сайт, отслеживая движение пользователей по
                сайту, и сбор демографической информации.
            </p>

            <h2> Файлы cookie и веб-маяки </h2>

            <p>
                Как и любой другой веб-сайт, Visit использует файлы cookie. Эти
                файлы cookie используются для хранения информации, в том числе
                посетителей предпочтения и страницы на веб-сайте, которые
                посетитель посещали или посещали. Информация используется для
                оптимизации пользовательский опыт, настраивая содержимое нашей
                веб-страницы на основе о типе браузера посетителей и / или
                другой информации.
            </p>

            <p>
                Для получения более общей информации о файлах cookie,
                пожалуйста, прочтите {""}
                <a href="https://www.cookieconsent.com/">
                    еще веб-сайт согласия на использование файлов cookie
                </a>
                .
            </p>

            <h2> Политика конфиденциальности рекламных партнеров </h2>

            <p>
                Вы можете ознакомиться с этим списком, чтобы найти Политику
                конфиденциальности для каждый из рекламных партнеров Visit.
            </p>

            <p>
                Сторонние рекламные серверы или рекламные сети используют такие
                технологии, как файлы cookie, JavaScript или веб-маяки, которые
                используются в их соответствующие рекламные объявления и ссылки,
                которые появляются при посещении, которые отправляются прямо в
                браузер пользователя или на мобильный заявление. Они
                автоматически получают ваш IP-адрес, когда это происходит. Эти
                технологии используются для измерения эффективность их рекламных
                кампаний и / или персонализировать рекламный контент, который вы
                видите на веб-сайтах что вы посещаете.
            </p>

            <p>
                Обратите внимание, что Visit не имеет доступа к этим файлы
                cookie, которые используются сторонними рекламодателями.
            </p>

            <h2> Политика конфиденциальности третьих лиц </h2>

            <p>
                Политика конфиденциальности Visit не распространяется на других
                рекламодателей. или веб-сайты. Таким образом, мы советуем вам
                проконсультироваться с соответствующие Политики
                конфиденциальности этих сторонних рекламных серверов для более
                подробной информации. Это может включать их практики и
                инструкции о том, как отказаться от определенных параметры.{" "}
            </p>

            <p>
                Вы можете отключить файлы cookie через свой индивидуальный
                параметры браузера. Чтобы узнать более подробную информацию о
                управление файлами cookie в определенных веб-браузерах, это
                может быть можно найти на соответствующих веб-сайтах браузеров.
            </p>

            <h2>
                Права на конфиденциальность CCPA (не продавать мою личную
                информацию)
            </h2>

            <p>
                Согласно CCPA, среди прочих прав, потребители из Калифорнии
                имеют право:
            </p>
            <p>
                Попросите компанию, которая занимается сбором личных данные
                раскрывают категории и конкретные части личных данные, которые
                компания собрала о потребителях.
            </p>
            <p>
                Запросить у компании удаление любых личных данных о потребитель,
                которого собрал бизнес.
            </p>
            <p>
                Попросите компанию, продающую личные вещи потребителя, данные, а
                не продавать личные данные потребителя.
            </p>
            <p>
                Если вы сделаете запрос, у нас будет один месяц, чтобы ответить
                вам. Если вы хотите воспользоваться любым из этих прав,
                пожалуйста, связаться с нами.
            </p>

            <h2> Права на защиту данных GDPR </h2>

            <p>
                Мы хотели бы убедиться, что вы в полной мере осведомлены обо
                всех ваши права на защиту данных. Каждый пользователь имеет
                право на следующий:
            </p>
            <p>
                Право на доступ - вы имеете право запрашивать копии ваших личных
                данных.
            </p>
            <p>
                Право на исправление - вы имеете право потребовать что мы
                исправляем любую информацию, которую вы считаете неточной. Вы
                также имеете право потребовать, чтобы мы завершили информация,
                которую вы считаете неполной.
            </p>
            <p>
                Право на стирание - вы имеете право потребовать, чтобы мы
                стереть ваши личные данные при определенных условиях.
            </p>
            <p>
                Право ограничить обработку - вы имеете право просить нас
                ограничить обработку ваших личных данные при определенных
                условиях.
            </p>
            <p>
                Право на возражение против обработки - вы имеете право возражать
                против обработки нами ваших личных данных в соответствии с
                определенные условия.
            </p>
            <p>
                Право на переносимость данных - вы имеете право потребовать,
                чтобы мы передали собранные нами данные в другой организации или
                непосредственно вам, в соответствии с определенными условия.
            </p>
            <p>
                Если вы сделаете запрос, у нас будет один месяц, чтобы ответить
                вам. Если вы хотите воспользоваться любым из этих прав,
                пожалуйста, связаться с нами.
            </p>

            <h2> Информация для детей </h2>

            <p>
                Еще одна часть нашего приоритета - защита дети во время
                использования Интернета. Мы поощряем родителей и опекуны для
                наблюдения, участия и / или мониторинга и направляют их
                онлайн-активность.
            </p>

            <p>
                Visit не собирает намеренно никаких личных данных, позволяющих
                установить личность. Информация от детей младше 13 лет. Если вы
                думаете что ваш ребенок предоставил такую ​​информацию на нашем
                веб-сайт, мы настоятельно рекомендуем вам немедленно связаться с
                нами и мы сделаем все возможное, чтобы как можно скорее удалить
                такие информация из наших записей.
            </p>

            <h2> Свяжитесь с нами </h2>

            <p>
                Если у вас есть какие-либо вопросы по поводу настоящей Политики
                конфиденциальности, вы можете свяжитесь с нами по электронной
                почте: <b> {Emails.supportEmail} </b>
            </p>
        </>
    );
};

const policyUk = () => {
    return (
        <>
            <h1> Політика конфіденційності програми Visit </h1>

            <p>
                Visit програма або Visit веб-сайт, доступний з
                https://visitappt.com, одним із наших основних пріоритетів є
                конфіденційність наших відвідувачів. Цей документ Політики
                конфіденційності містять типи інформації, яка збирається та
                записується by Visit і як ми його використовуємо.
            </p>

            <p>
                Якщо у вас є додаткові питання або вам потрібна додаткова
                інформація про нашу політику конфіденційності, не соромтеся
                звертатися до нас.
            </p>

            <h2> Згода </h2>

            <p>
                Використовуючи наш веб-сайт або наш мобільний додаток (Android
                або iOS), ви погоджуєтесь з нашою Політикою конфіденційності та
                погоджуєтесь на його умови.
            </p>

            <h2> Інформація, яку ми збираємо </h2>

            <p>
                Особиста інформація, яку вас просять надати, та причини, за
                якими вас просять надати це, будуть вказані ясно для вас у той
                момент, коли ми просимо вас надати свій Персональні дані.
            </p>
            <p>
                Якщо ви звернетеся до нас безпосередньо, ми можемо отримати
                додаткова інформація про вас, така як ваше ім'я, адреса
                електронної пошти, номер телефону, зміст повідомлення та/або
                вкладення ви можете надіслати нам будь-яку іншу інформацію, яку
                ви можете обрати надавати.
            </p>
            <p>
                Коли ви реєструєте обліковий запис, ми можемо запросити ваш
                контактна інформація, включаючи такі елементи, як ім'я, дата
                народження, адреса електронної пошти та номер телефону.
            </p>

            <h2> Як ми використовуємо вашу інформацію </h2>

            <p>
                Ми використовуємо інформацію, яку збираємо, різними способами, у
                тому числі:
            </p>

            <ul>
                <li>
                    Надавати, експлуатувати та підтримувати наш веб-сайт та
                    мобільні пристрої заяву
                </li>
                <li>
                    Покращуйте, персоналізуйте та розширюйте наш веб-сайт та
                    мобільні пристрої заяву
                </li>
                <li>
                    Зрозуміти та проаналізувати, як ви використовуєте наш
                    веб-сайт та мобільний додаток
                </li>
                <li>
                    Розробляти нові продукти, послуги, функції та
                    функціональність
                </li>
                <li>
                    Спілкуйтесь з вами безпосередньо або через один з наших
                    партнерів, у тому числі з обслуговування клієнтів, для
                    надання вас з оновленнями та іншою інформацією, що
                    стосується веб-сайту та мобільний додаток, а також для
                    маркетингу та рекламні цілі
                </li>
                <li> Надсилати вам електронні листи </li>
                <li> Знайдіть і запобігти шахрайству. </li>
            </ul>

            <h2>Видалення даних</h2>
            <p>
                Ви можете видалити свій обліковий запис у мобільній програмі
                Visit, перейшовши до «Налаштувань профілю», а потім до розділу
                «Видалити обліковий запис». Будь ласка, слідуйте інструкціям на
                екрані, щоб видалити свій обліковий запис.
            </p>
            <p>
                Зверніть увагу, що ваш обліковий запис буде позначено як
                видалений, а всі пов’язані з ним дані буде видалено з наших
                серверів через 30 днів. Ви можете відновити обліковий запис
                протягом 30 днів після видалення.
            </p>
            <p>
                Крім того, ви можете подати запит на видалення свого облікового
                запису, зв’язавшись з нами електронною поштою{" "}
                <b> {Emails.supportEmail} </b>.
            </p>

            <h2>Файли журналу</h2>

            <p>
                Відвідування слідує стандартній процедурі використання файлів
                журналу. Ці файли реєструють відвідувачів, коли вони відвідують
                веб сайти. Весь хостинг компанії займаються цим та частиною
                аналітики хостингових послуг Інформація, що збирається файлами
                журналу, включає Інтернет протокол (IP) адреси, тип браузера,
                інтернет-сервіс Провайдер (ISP), позначка дати та часу, сторінки
                переходу/виходу, і, можливо, кількість кліків. Вони не пов'язані
                з будь-якою інформацією, що дозволяє встановити особистість.
                Мета інформації призначена для аналізу тенденцій,
                адміністрування сайт, відстежуючи рух користувачів по сайту, та
                збирання демографічної інформації.
            </p>

            <h2> Файли cookie та веб-маяки </h2>

            <p>
                Як і будь-який інший сайт, Visit використовує файли cookie. Ці
                файли cookie використовуються для зберігання інформації, у тому
                числі відвідувачів переваги та сторінки на веб-сайті, які
                відвідувач відвідували чи відвідували. Інформація
                використовується для оптимізації користувальницький досвід,
                налаштовуючи вміст нашої веб-сторінки на основі про тип браузера
                відвідувачів та/або іншої інформації.
            </p>

            <p>
                Для отримання більш загальної інформації про файли cookie, будь
                ласка, прочитайте {""}
                <a href="https://www.cookieconsent.com/">
                    ще веб-сайт згоди на використання файлів cookie
                </a>
                .
            </p>

            <h2> Політика конфіденційності рекламних партнерів </h2>

            <p>
                Ви можете ознайомитись із цим списком, щоб знайти Політику
                конфіденційності для кожного з рекламних партнерів Visit.
            </p>

            <p>
                Сторонні рекламні сервери або мережі використовують такі
                технології, як файли cookie, JavaScript або веб-маяки, які
                використовуються в їх відповідні рекламні оголошення та
                посилання, які з'являються при відвідуванні, які відправляються
                прямо в браузер користувача або на мобільну заяву. Вони
                автоматично отримують вашу IP-адресу, коли це відбувається. Ці
                технології використовуються для вимірювання ефективності їх
                рекламних кампаній та / або персоналізувати рекламний контент,
                який ви бачите на веб-сайтах, що ви відвідуєте.
            </p>

            <p>
                Зверніть увагу, що Visit не має доступу до цих файлів cookie,
                які використовуються сторонніми рекламодавцями.
            </p>

            <h2> Політика конфіденційності третіх осіб </h2>

            <p>
                Політика конфіденційності Visit не поширюється на інших
                рекламодавців або веб-сайти. Таким чином, ми радимо вам
                проконсультуватися з відповідними політиками конфіденційності
                цих сторонніх рекламних серверів для більш Детальна інформація.
                Це може включати їх практики та інструкції про те, як
                відмовитися від певних параметрів.{" "}
            </p>

            <p>
                Ви можете вимкнути файли cookie через свій індивідуальний
                параметри браузера Щоб дізнатися більш детальну інформацію про
                керування файлами cookie у певних веб-браузерах, це можливо
                можна знайти на відповідних веб-сайтах браузерів.
            </p>

            <h2>
                Права на конфіденційність CCPA (не продавати мою особисту
                інформацію)
            </h2>

            <p>
                Згідно з CCPA, серед інших прав споживачі з Каліфорнії мають
                право:
            </p>
            <p>
                Попросіть компанію, яка займається збором особистих даних
                розкривають категорії та конкретні частини особистих даних, які
                компанія зібрала про споживачів.
            </p>
            <p>
                Запросити у компанії видалення будь-яких особистих даних про
                споживач, якого зібрав бізнес.
            </p>
            <p>
                Попросіть компанію, що продає особисті речі споживача, дані, а
                не продавати особисті дані споживача.
            </p>
            <p>
                Якщо ви зробите запит, у нас буде один місяць, щоб відповісти
                вам. Якщо ви хочете скористатися будь-яким із цих прав, Будь
                ласка, зв'яжіться з нами.
            </p>

            <h2> Права на захист даних GDPR </h2>

            <p>
                Ми хотіли б переконатися, що ви повною мірою обізнані про всі
                ваші права на захист даних. Кожен користувач має право на
                наступний:
            </p>
            <p>
                Право на доступ - ви маєте право вимагати копії ваших особистих
                даних.
            </p>
            <p>
                Право на виправлення - ви маєте право вимагати, що ми
                виправляємо будь-яку інформацію, яку ви вважаєте неточною. Ви
                також маєте право вимагати, щоб ми завершили інформацію, яку ви
                вважаєте неповною.
            </p>
            <p>
                Право на стирання - ви маєте право вимагати, щоб ми стерти ваші
                особисті дані за певних умов.
            </p>
            <p>
                Право обмежити обробку - ви маєте право просити нас обмежити
                обробку ваших особистих даних за певних умовах.
            </p>
            <p>
                Право на заперечення проти обробки - ви маєте право заперечувати
                проти обробки нами ваших особистих даних відповідно до певні
                умови.
            </p>
            <p>
                Право на переносимість даних - ви маєте право вимагати, щоб ми
                передали зібрані нами дані до іншої організації або
                безпосередньо вам, відповідно до певних умов.
            </p>
            <p>
                Якщо ви зробите запит, у нас буде один місяць, щоб
                відповістиетити вам. Якщо ви хочете скористатися будь-яким із
                цих прав, Будь ласка, зв'яжіться з нами.
            </p>

            <h2> Інформація для дітей </h2>

            <p>
                Ще одна частина нашого пріоритету – захист діти під час
                використання Інтернету. Ми заохочуємо батьків та опікуни для
                спостереження, участі та/або моніторингу та спрямовують їх
                онлайн-активність.
            </p>

            <p>
                Visit не збирає навмисне жодних особистих даних, що дозволяють
                встановити особистість. Інформація від дітей віком до 13 років.
                Якщо ви думаєте що ваша дитина надала таку інформацію на нашому
                веб-сайт, ми рекомендуємо вам негайно зв'язатися з нами і ми
                зробимо все можливе, щоб якнайшвидше видалити такі відомості з
                наших записів.
            </p>

            <h2> Зв'яжіться з нами </h2>

            <p>
                Якщо у вас є якісь питання щодо цієї Політики конфіденційності,
                ви можете зв'яжіться з нами по електронній поштою:{" "}
                <b> {Emails.supportEmail} </b>
            </p>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
}));

export default function PrivacyPolicyScreen() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="secondary">
                <Tabs value={value} onChange={handleChange} variant="fullWidth">
                    <Tab label="English" />
                    <Tab label="Русский" />
                    <Tab label="Українська" />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {policyEn()}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {policyRu()}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {policyUk()}
            </TabPanel>
        </div>
    );
}
