import React from 'react';
import { isMobile } from 'react-device-detect';
import {
    Card,
    //CardActions,
    CardContent,
    CardHeader,
    //Collapse,
    //IconButton,
    makeStyles,
    Typography
} from '@material-ui/core';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import PT from '../PromoTexts';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,
        margin: 10,
        marginTop: 20,
        borderRadius: 10
    },
    header: {
        backgroundColor: theme.palette.secondary.main
    },
    iconContainer: {
        position: 'relative'
    },
    icon: {
        position: 'absolute',
        width: 50,
        height: 50,
        padding: 10,
        left: '50%',
        top: 30,
        marginLeft: -35,
        marginTop: -30,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.main,
        borderRadius: '50%',
        boxShadow: '0px 0px 10px 3px ' + theme.palette.secondary.main + '99'
    },
    content: {
        marginTop: 10
    },
    captionText: {
        fontWeight: 'bold'
    },
    bullet: {
        padding: 5
    },
    actions: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    details: {
        alignSelf: 'center'
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    }
}));

export default function InfoCard({ icon, SL, cardData }) {
    const classes = useStyles();
    // const [expanded, setExpanded] = React.useState(false);

    // const handleExpandClick = () => {
    //     setExpanded(!expanded);
    // };

    return (
        <>
            <div className={classes.iconContainer}>
                <div className={classes.icon}>{icon}</div>
            </div>
            <Card className={classes.root}>
                <CardHeader className={classes.header}></CardHeader>

                <CardContent className={classes.content}>
                    <Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        color="primary"
                        className={classes.captionText}
                    >
                        {cardData.header}
                    </Typography>
                    <Typography variant={isMobile ? 'h6' : 'h5'}>
                        <ul>
                            {cardData.bullets.map((item) => (
                                <li key={item} className={classes.bullet}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </Typography>
                </CardContent>
                {/* <CardActions disableSpacing className={classes.actions}>
                    <Typography
                        className={classes.details}
                        variant="h6"
                        color="secondary"
                    >
                        {PT[SL].details}
                    </Typography>
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        className={
                            classes.expand +
                            ' ' +
                            (expanded && classes.expandOpen)
                        }
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography>{cardData.details}</Typography>
                    </CardContent>
                </Collapse> */}
            </Card>
        </>
    );
}
