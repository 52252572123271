import React from 'react';
import {
    AppBar,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    Toolbar,
    useScrollTrigger
} from '@material-ui/core';
import { theme } from '../style/theme';
import MenuIcon from '@material-ui/icons/Menu';
import PT from '../PromoTexts';
import DownloadModal from './DownloadModal';

const useStyles = makeStyles({
    drawer: {
        width: 250
    },
    languageSelect: {
        marginRight: 20,
        color: theme.palette.common.white
    },
    page: {
        marginRight: 20
    },
    listItem: {
        color: theme.palette.primary.main
    },
    growDiv: {
        flexGrow: 1
    },
    languageIcon: {
        fill: theme.palette.common.white
    },
    downloadButton: {
        borderRadius: 10,
        fontWeight: 'bold'
    }
});

function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return React.cloneElement(children, {
        elevation: trigger ? 10 : 0
    });
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(
        getWindowDimensions()
    );

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const MENU_WIDTH = 740;

export default function PromoAppBar({ selectPage, SL, setSelectedLocale }) {
    const classes = useStyles();
    const { width } = useWindowDimensions();

    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [pages] = React.useState([
        'mainPage',
        'contactsPage',
        'privacyPolicyPage',
        'termsConditionsPage'
    ]);

    const [openDownloadModal, setOpenDownloadModal] = React.useState(false);

    const onExitedRegionSelect = (node: HTMLElement): void => {
        // At this point it can only be a child of the Select root
        (document.activeElement as HTMLElement).blur();
    };

    const list = () => (
        <div className={classes.drawer}>
            <List>
                {pages.map((item) => (
                    <ListItem
                        className={classes.listItem}
                        button
                        key={item}
                        onClick={() => {
                            selectPage(item);
                            setDrawerOpen(false);
                        }}
                    >
                        <ListItemText primary={PT[SL][item]} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                {list()}
            </Drawer>
            <DownloadModal
                SL={SL}
                open={openDownloadModal}
                setOpen={setOpenDownloadModal}
            />
            <ElevationScroll>
                <AppBar position="sticky" color="secondary">
                    <Toolbar>
                        {width <= MENU_WIDTH ? (
                            <IconButton
                                color="inherit"
                                onClick={() => setDrawerOpen(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                        ) : (
                            pages.map((item) => (
                                <Button
                                    key={item}
                                    variant="text"
                                    color="inherit"
                                    className={classes.page}
                                    onClick={() => selectPage(item)}
                                >
                                    {PT[SL][item]}
                                </Button>
                            ))
                        )}
                        <div className={classes.growDiv} />
                        <Select
                            className={classes.languageSelect}
                            inputProps={{
                                classes: { icon: classes.languageIcon }
                            }}
                            value={SL}
                            label="Locale"
                            onChange={(event) =>
                                setSelectedLocale(event.target.value)
                            }
                            MenuProps={{ onExited: onExitedRegionSelect }}
                        >
                            <MenuItem value={'en'}>EN</MenuItem>
                            <MenuItem value={'ru'}>RU</MenuItem>
                            <MenuItem value={'uk'}>UA</MenuItem>
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.downloadButton}
                            onClick={() => setOpenDownloadModal(true)}
                        >
                            {PT[SL].downloadFree}
                        </Button>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
        </>
    );
}
