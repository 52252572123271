import i18n from "i18n-js";
import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    Link,
    makeStyles,
    Modal,
    Typography,
} from "@material-ui/core";
import { slideInDown, slideOutDown } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import {
    AdditionalServiceItemClient,
    AgendaItem,
    ClientInfoInterface,
    MenuItemClient,
    Utils,
} from "visit-shared";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ActiveBookingCard from "../components/ActiveBookingCard";
import Network from "../network";
import { Dimensions, theme } from "../style/theme";

const animationStyles = StyleSheet.create({
    slideInDown: {
        animationName: slideInDown,
        animationDuration: "0.5s",
    },
    slideOutDown: {
        animationName: slideOutDown,
        animationDuration: "0.5s",
    },
});

const useStyles = makeStyles((theme) => ({
    blendInBackground: {
        color: theme.palette.background.default,
    },
    activeBookingsHeader: {
        position: "absolute",
        top: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.secondary.main,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        boxShadow: "0px 0px 20px 5px " + theme.palette.secondary.main + "99",
        color: theme.palette.common.white,
        zIndex: 1,
    },
    activeBookingsText: {
        marginRight: 50,
        flex: 1,
        paddingTop: 5,
        paddingBottom: 10,
        fontFamily: theme.typography.fontFamily,
        fontWeight: "bold",
        textAlign: "center",
        webkitTouchCallout: "none" /* iOS Safari */,
        webkitUserSelect: "none" /* Safari */,
        khtmlUserSelect: "none" /* Konqueror HTML */,
        mozUserSelect: "none" /* Old versions of Firefox */,
        msUserSelect: "none" /* Internet Explorer/Edge */,
        userSelect: "none" /* Non-prefixed version */,
    },
    noAptsPlannedText: {
        color: theme.palette.secondary.main,
        fontFamily: theme.typography.fontFamily,
        fontWeight: "bold",
        textAlign: "center",
    },
    footerText: {
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        textAlign: "center",
    },
    cancelBookingTitle: {
        color: theme.palette.primary.main,
        width: 250,
    },
}));

interface ActiveBookingsProps {
    dimensions: Dimensions;
    open: boolean;
    onClose: any;
    onAgendaItemMove: any;
    activeBookings: AgendaItem[];
    menu: MenuItemClient[];
    additionalServices: AdditionalServiceItemClient[];
    userData: ClientInfoInterface;
}

export default function ActiveBookingsScreen({
    dimensions,
    open,
    onClose,
    onAgendaItemMove,
    activeBookings,
    menu,
    additionalServices,
    userData,
}: ActiveBookingsProps) {
    React.useEffect(() => {
        if (open) {
            // In case of few navigations creates to many states. Can this be handled better?
            window.history.pushState(null, "", window.location.href);
        }

        window.onpopstate = () => {
            handleClose();
        };

        return () => {
            window.onpopstate = null;
        };
    }, [open]);

    const classes = useStyles();

    const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
    const selectedItem = React.useRef<AgendaItem | null>(null);

    const [currentAnimation, setCurrentAnimation] = React.useState(
        animationStyles.slideInDown
    );

    const handleClose = () => {
        setCurrentAnimation(animationStyles.slideOutDown);
        setTimeout(() => {
            onClose();
            setCurrentAnimation(animationStyles.slideInDown);
        }, 500);
    };

    const onCancel = (item: AgendaItem) => {
        selectedItem.current = item;
        setCancelDialogOpen(true);
    };

    const onCancelConfirm = async () => {
        if (selectedItem.current) {
            const reqObj: AgendaItem = {
                id: selectedItem.current!.id,
                date: Utils.FormatDateForTransfer(
                    new Date(selectedItem.current!.date)
                ),
                timestartMin: selectedItem.current!.timestartMin,
                timeendMin: selectedItem.current!.timeendMin,
                // clientId is set on server side
                clientMobile: selectedItem.current!.clientMobile,
                clientName: selectedItem.current!.clientName,
                comment: selectedItem.current!.comment,
                customFields: {},
                menuId: selectedItem.current!.menuId,
                addMenuIds: selectedItem.current!.addMenuIds,
                addServiceIds: selectedItem.current!.addServiceIds,
                callmeback: selectedItem.current!.callmeback,
                discountId: selectedItem.current!.discountId,
            };
            const resp = await Network.Post("usercancelagendaitem", reqObj);
            if (resp.status === 200) {
                selectedItem.current = null;
                setCancelDialogOpen(false);
                handleClose();
            } else {
                // Should not happen
                alert(i18n.t("cancelBookingError"));
            }
        }
    };

    return (
        <Modal
            open={open}
            hideBackdrop={true}
            className={css(currentAnimation)}
        >
            <>
                <div
                    style={{
                        background: theme.palette.background.default,
                        minHeight: dimensions.height,
                    }}
                >
                    <div
                        className={classes.activeBookingsHeader}
                        onClick={handleClose}
                    >
                        <IconButton color="inherit" onClick={handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography
                            variant={"h6"}
                            className={classes.activeBookingsText}
                        >
                            {i18n.t("myBookings")}
                        </Typography>
                    </div>
                    <div
                        style={{
                            maxHeight: dimensions.height,
                            overflow: "auto",
                        }}
                    >
                        <div>
                            <Typography
                                variant={"h6"}
                                className={
                                    classes.activeBookingsText +
                                    " " +
                                    classes.blendInBackground
                                }
                            >
                                {i18n.t("myBookings")}
                            </Typography>
                        </div>
                        {activeBookings.length > 0 ? (
                            activeBookings.map((item) => (
                                <ActiveBookingCard
                                    key={item.id}
                                    parentOpen={open}
                                    item={item}
                                    menu={menu}
                                    additionalServices={additionalServices}
                                    mobile={userData.userMobile}
                                    onCancel={onCancel}
                                    onMove={onAgendaItemMove}
                                    userData={userData}
                                />
                            ))
                        ) : (
                            <div
                                style={{
                                    height: dimensions.height * 0.9,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography
                                    variant={"h6"}
                                    className={classes.noAptsPlannedText}
                                >
                                    {i18n.t("noActiveBookings")}
                                </Typography>
                            </div>
                        )}
                        <Typography className={classes.footerText}>
                            {i18n.t("poweredBy")}
                            <Link href="/" color="secondary">
                                {i18n.t("appName")}
                            </Link>
                        </Typography>
                    </div>
                </div>
                {/* TODO: Fix Warning: findDOMNode is deprecated in StrictMode. */}
                <Dialog
                    open={cancelDialogOpen}
                    onClose={() => setCancelDialogOpen(false)}
                >
                    <DialogTitle className={classes.cancelBookingTitle}>
                        {i18n.t("cancelBookingQuestion")}
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            onClick={() => setCancelDialogOpen(false)}
                            variant="outlined"
                            color="secondary"
                        >
                            {i18n.t("cancel")}
                        </Button>
                        <Button
                            onClick={onCancelConfirm}
                            variant="contained"
                            color="secondary"
                        >
                            {i18n.t("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </Modal>
    );
}
