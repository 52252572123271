import React from "react";
import Network from "./network";
import { ResetLocalization, ResetTimeFormat } from "./localization";
import ServicesMenu from "./screens/ServicesMenuScreen";
import { ClientInfoInterface, Utils } from "visit-shared";
import { ThemeProvider } from "@material-ui/core";
import { theme, userUITheme } from "./style/theme";
import NoUserFoundScreen from "./screens/NoUserFoundScreen";
import PromoScreen from "./screens/PromoScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import TermsConditionsScreen from "./screens/TermsConditionsScreen";
import OnlineBookingDisabledScreen from "./screens/OnlineBookingDisabledScreen";

function App() {
    const hasBeenCalled = React.useRef(false);

    const [showPromo, setShowPromo] = React.useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = React.useState(false);
    const [showTermsConditions, setShowTermsConditions] = React.useState(false);
    const [showContacts, setShowContacts] = React.useState(false);
    const [userData, setUserData] = React.useState<ClientInfoInterface | null>(
        null
    );

    const [isLoading, setIsLoading] = React.useState(true);

    const constructor = async () => {
        if (hasBeenCalled.current) return;
        hasBeenCalled.current = true;

        if (
            window.location.pathname === "/" ||
            window.location.pathname === "/index.html"
        ) {
            setShowPromo(true);
        } else if (window.location.pathname === "/contacts") {
            setShowPromo(true);
            setShowContacts(true);
        } else if (window.location.pathname === "/privacy") {
            setShowPrivacyPolicy(true);
        } else if (window.location.pathname === "/terms") {
            setShowTermsConditions(true);
        } else {
            const resp = await Network.Get(
                `getclientinfo${
                    window.location.pathname
                }/${Utils.GetDateOnlyISOString(new Date())}`
            );
            if (resp.status === 200) {
                ResetLocalization(resp.data.clientLanguage);
                ResetTimeFormat(resp.data.clientTimeFormat);
                setUserData(resp.data);
            }
        }
        setIsLoading(false);
    };

    React.useEffect(() => {
        constructor();
    });

    if (isLoading) return null;

    return (
        <ThemeProvider
            theme={
                showPromo || showPrivacyPolicy || showTermsConditions
                    ? theme
                    : userUITheme
            }
        >
            {showPromo ? (
                <PromoScreen
                    selectedPage={showContacts ? "contactsPage" : undefined}
                />
            ) : showPrivacyPolicy ? (
                <PrivacyPolicyScreen />
            ) : showTermsConditions ? (
                <TermsConditionsScreen />
            ) : !userData || userData.deleted ? (
                <NoUserFoundScreen
                    userDeleted={!userData ? false : userData!.deleted}
                />
            ) : !userData.clientsCanBookOnline ? (
                <OnlineBookingDisabledScreen />
            ) : (
                <ServicesMenu userData={userData!} />
            )}
        </ThemeProvider>
    );
}

export default App;
