import i18n from "i18n-js";
import React from "react";
import { isMobile } from "react-device-detect";
import { Link, makeStyles, Typography } from "@material-ui/core";
import oops from "../assets/oops.png";
import { theme } from "../style/theme";

const useStyles = makeStyles({
    index: {
        backgroundColor: theme.palette.background.default,
        minHeight: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 20,
    },
    img: {
        height: isMobile ? "150px" : "300px",
    },
    textFirst: {
        color: theme.palette.text.primary,
        textAlign: "center",
        paddingTop: 40,
        fontWeight: "bold",
    },
    textSecond: {
        color: theme.palette.text.primary,
        textAlign: "center",
        paddingTop: 40,
    },
    username: {
        color: theme.palette.error.main,
    },
});

export default function NoUserFoundScreen({ userDeleted }) {
    const classes = useStyles();

    return (
        <div className={classes.index}>
            <img src={oops} className={classes.img} alt="Error logo" />
            <Typography
                variant={isMobile ? "h4" : "h3"}
                className={classes.textFirst}
            >
                {i18n.t("userNotFoundMessageP1")}
                <span className={classes.username}>
                    {window.location.pathname.substr(1)}
                </span>
                {userDeleted
                    ? i18n.t("userNotFoundMessageP2a")
                    : i18n.t("userNotFoundMessageP2")}
            </Typography>
            <Typography
                variant={isMobile ? "h5" : "h5"}
                className={classes.textSecond}
            >
                {i18n.t("userNotFoundMessageP3")}
                <Link href="/" color="secondary">
                    {i18n.t("userNotFoundMessageP4")}
                </Link>
                {"."}
            </Typography>
        </div>
    );
}
