import React from "react";
import { makeStyles } from "@material-ui/core";
import MaterialUiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles((theme) => ({
    cssLabel: {
        color: theme.palette.secondary.main,
    },

    cssOutlinedInput: {
        borderRadius: 20,
        "&$cssFocused $notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
        },
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: "1px",
        borderColor: `${theme.palette.secondary.main} !important`,
    },
}));

export default function PhoneInput({
    errorText,
    label,
    value,
    onChange,
    defaultCountryCode,
    ...props
}) {
    const classes = useStyles();
    return (
        <MaterialUiPhoneNumber
            autoComplete={props.autoComplete}
            fullWidth
            id="filled-error-helper-text"
            label={label}
            InputLabelProps={{
                classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                },
            }}
            value={value}
            helperText={errorText}
            InputProps={{
                readOnly: props.readOnly,
                classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                },
            }}
            error={errorText !== ""}
            variant="outlined"
            defaultCountry={defaultCountryCode}
            onChange={onChange}
            autoFormat={false}
            disableAreaCodes={true}
        />
    );
}
