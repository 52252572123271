import i18n from "i18n-js";
import React from "react";
import {
    AdditionalServiceItemClient,
    AdditionalServiceItemClientCounted,
    AgendaItem,
    ClientInfoInterface,
    MenuItemClient,
    Utils,
} from "visit-shared";
import { LocaleConfig } from "../localization";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import BlockIcon from "@material-ui/icons/Block";
import CallMadeIcon from "@material-ui/icons/CallMade";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: 20,
        borderRadius: 20,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
    },
    cardActions: {
        marginTop: 3,
    },
    button: {
        borderRadius: 20,
    },
    growDiv: {
        flexGrow: 1,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        color: theme.palette.secondary.main,
    },
    phoneIcon: {
        marginTop: 15,
        marginRight: 5,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

export interface ActiveBookingCardProps {
    parentOpen: boolean;
    item: AgendaItem;
    menu: MenuItemClient[];
    additionalServices: AdditionalServiceItemClient[];
    onCancel: any;
    onMove: any;
    mobile: string;
    userData: ClientInfoInterface;
}

export default function ActiveBookingCard({
    parentOpen,
    item,
    menu,
    additionalServices,
    onCancel,
    onMove,
    mobile,
    userData,
}: ActiveBookingCardProps) {
    const classes = useStyles();

    const [additionalServicesLocal, setAdditionalServicesLocal] =
        React.useState<AdditionalServiceItemClientCounted[]>([]);

    React.useEffect(() => {
        if (!parentOpen) {
            setAdditionalServicesLocal([]);
        } else {
            let services: AdditionalServiceItemClientCounted[] = [];
            additionalServices.forEach((service) => {
                let count = item.addServiceIds.filter(
                    (idx) => idx === service.id
                ).length;

                if (count > 0)
                    services.push({
                        id: service.id,
                        name: service.name,
                        description: service.description,
                        price: service.price,
                        durationMins: service.durationMins,
                        allowMultiple: service.allowMultiple,
                        count: count,
                    });
            });
            setAdditionalServicesLocal(services);
        }
    }, [additionalServices, item, parentOpen]);

    const [itemName, setItemName] = React.useState<string>("");

    const [itemDuration, setItemDuration] = React.useState(0);

    React.useEffect(() => {
        if (!parentOpen) {
            setItemName("");
            setItemDuration(0);
        } else {
            let name: string = "";

            let menuFilterRes = menu.filter((it) => it.id === item.menuId);
            if (menuFilterRes.length > 0) {
                name = menu.filter((it) => it.id === item.menuId)[0].name;
            }

            menu.forEach((menuItem) => {
                let count = item.addMenuIds.filter(
                    (idx) => idx === menuItem.id
                ).length;

                if (count > 0) name += ", " + menuItem.name;
            });
            setItemName(name);

            let duration = 0;

            if (menuFilterRes.length > 0) {
                duration = menuFilterRes[0].durationMins;
            }

            menu.forEach((menuItem) => {
                let count = item.addMenuIds.filter(
                    (idx) => idx === menuItem.id
                ).length;

                if (count > 0) duration += menuItem.durationMins;
            });

            additionalServices.forEach((addServiceItem) => {
                let count = item.addServiceIds.filter(
                    (idx) => idx === addServiceItem.id
                ).length;

                if (count > 0) duration += addServiceItem.durationMins;
            });

            setItemDuration(duration);
        }
    }, [menu, item, parentOpen]);

    return (
        <Card className={classes.card}>
            <CardActions className={classes.cardActions}>
                {userData.clientsCanCancel && (
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        startIcon={<BlockIcon />}
                        onClick={() => {
                            onCancel(item);
                        }}
                    >
                        {i18n.t("cancelBooking")}
                    </Button>
                )}
                <div className={classes.growDiv} />
                {userData.clientsCanMove && (
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        startIcon={<CallMadeIcon />}
                        onClick={() => {
                            onMove(item, additionalServicesLocal);
                        }}
                    >
                        {i18n.t("moveBooking")}
                    </Button>
                )}
            </CardActions>
            <CardHeader
                title={`${Utils.DurationToTimeString(
                    item.timestartMin
                )} - ${Utils.DurationToTimeString(
                    item.timestartMin + itemDuration
                )}`}
                subheader={`${
                    LocaleConfig.locales[LocaleConfig.defaultLocale].dayNames![
                        new Date(item.date).getDay()
                    ]
                }  (${Utils.GetDateMonthString(item.date)})`}
                action={
                    <IconButton
                        onClick={() => window.open("tel:" + mobile)}
                        className={classes.phoneIcon}
                    >
                        <PhoneEnabledIcon />
                    </IconButton>
                }
            />
            <CardContent className={classes.row}>
                <div>
                    <Typography variant={isMobile ? "h5" : "h6"}>
                        {itemName}
                    </Typography>
                    {additionalServicesLocal.length > 0 && (
                        <div>
                            {additionalServicesLocal.map((elem) => (
                                <Typography
                                    key={elem.id}
                                    variant={isMobile ? "h6" : "subtitle1"}
                                >
                                    {elem.count > 1
                                        ? `- ${elem.name} ${elem.count}x`
                                        : `- ${elem.name}`}
                                </Typography>
                            ))}
                        </div>
                    )}
                </div>
                <Typography variant={isMobile ? "h5" : "h6"}>
                    {item.price!}
                </Typography>
            </CardContent>
        </Card>
    );
}
