import i18n from "i18n-js";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { isMobile } from "react-device-detect";
import {
    StringValidators,
    Utils,
    AgendaItem,
    MenuItemClient,
    ClientInfoInterface,
    ClientMoveAgendaItem,
    AdditionalServiceItemClientCounted,
    DiscountCampaignItem,
    ServiceDiscount,
} from "visit-shared";
import { LocaleConfig } from "../localization";
import Network from "../network";
import TextInput, { TextInputItem } from "./TextInput";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
} from "@material-ui/core";
import { slideInUp, slideOutDown } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import { Dimensions, theme } from "../style/theme";
import PhoneInput from "./PhoneInput";
import DiscountsInput from "./DiscountsInput";

const animationStyles = StyleSheet.create({
    slideInUp: {
        animationName: slideInUp,
        animationDuration: "0.5s",
    },
    slideOutDown: {
        animationName: slideOutDown,
        animationDuration: "0.5s",
    },
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
        backgroundColor: theme.palette.secondary.main,
        height: 56,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    titleButton: {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.default,
        borderRadius: 15,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: theme.palette.background.default,
        },
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    background: {
        backgroundColor: theme.palette.background.default,
        height: "100%",
    },
    list: {
        margin: 8,
    },
    confirmDlgTitle: {
        color: theme.palette.primary.main,
    },
    confirmDlgBody: {
        color: theme.palette.secondary.main,
        fontWeight: "bold",
        textAlign: isMobile ? "center" : "left",
    },
    confirmDlgBodyMobileCenter: {
        textAlign: "center",
    },
    confirmDlgButton: {
        fontWeight: "bold",
        color: theme.palette.background.default,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 15,
    },
}));

export function GetServiceAllowedPercentage(
    id: number,
    isAddService: boolean,
    selectedDiscountCampaignItem: DiscountCampaignItem | null
): number {
    if (selectedDiscountCampaignItem === null) return 1;

    let searchRes: ServiceDiscount[];
    if (isAddService)
        searchRes = selectedDiscountCampaignItem.addServiceItems.filter(
            (it) => it.id === id
        );
    else
        searchRes = selectedDiscountCampaignItem.menuItems.filter(
            (it) => it.id === id
        );

    if (searchRes.length > 0) return searchRes[0].allowedPercentage;
    else return 1;
}

interface ConfirmDialogProps {
    dimensions: Dimensions;
    startTime: Date | undefined;
    menuItems: MenuItemClient[];
    additionalServices: AdditionalServiceItemClientCounted[];
    replaceAgendaItem: AgendaItem | null;
    isOpen: boolean;
    handleClose: any;
    backToMenu: any;
    userData: ClientInfoInterface;
    autoFillName: string;
    autoFillMobile: string;

    discountCampaignItems: DiscountCampaignItem[];
    updateDiscountsData: any;
    updatePromoCode: any;
    updateSelectedDiscountCampaign: any;
    selectedDiscountCampaignItemId: number;
    promoCode: TextInputItem;
    promoCodeInfoText: string;
    selectedDiscountCampaignItem: DiscountCampaignItem | null;
}

export default function ConfirmDialog({
    dimensions,
    startTime,
    menuItems,
    additionalServices,
    replaceAgendaItem,
    isOpen,
    handleClose,
    backToMenu,
    userData,
    autoFillName,
    autoFillMobile,

    discountCampaignItems,
    updateDiscountsData,
    updatePromoCode,
    updateSelectedDiscountCampaign,
    selectedDiscountCampaignItemId,
    promoCode,
    promoCodeInfoText,
    selectedDiscountCampaignItem,
}: ConfirmDialogProps) {
    const classes = useStyles();
    const [currentAnimation, setCurrentAnimation] = React.useState(
        animationStyles.slideInUp
    );

    const [userIsBlocked, setUserIsBlocked] = React.useState(false);

    const [durationStartMin, setDurationMinStart] = React.useState(0);
    const [durationEndMin, setDurationMinEnd] = React.useState(0);
    const [name, setName] = React.useState({ value: autoFillName, error: "" });
    const [mobile, setMobile] = React.useState({
        value: autoFillMobile,
        error: "",
    });
    const [price, setPrice] = React.useState(0);
    const [discountApplied, setDiscountApplied] = React.useState(false);

    const [
        selectedDiscountCampaignItemError,
        setSelectedDiscountCampaignItemError,
    ] = React.useState("");

    const [comment, setComment] = React.useState({ value: "", error: "" });
    const [callmeback, setCallmeback] = React.useState(false);

    const [confirmDlgOpen, setConfirmDlgOpen] = React.useState(false);
    const [confirmDlgHeader, setConfirmDlgHeader] = React.useState("");
    const [confirmDlgBody, setConfirmDlgBody] = React.useState("");
    const [confirmDlgBodyExt] = React.useState(`
    ${i18n.t("youCanAdjustBookingPart1")}${
        userData.clientsCanMove ? i18n.t("youCanAdjustBookingPart2") : ""
    }${
        userData.clientsCanCancel ? i18n.t("youCanAdjustBookingPart3") : ""
    } ${i18n.t("youCanAdjustBookingPart4")}`);

    const getTotalProcedureDuration = () => {
        let duration = menuItems.reduce((a, b) => a + b.durationMins, 0);
        duration += additionalServices.reduce(
            (a, b) => (a += b.durationMins * b.count),
            0
        );

        // Taking into account appointment additional time settings
        duration += userData.apptAddTimeMin;

        return duration;
    };

    const updateDurations = () => {
        if (startTime !== undefined) {
            const newDurationMinStart =
                startTime!.getHours() * 60 + startTime!.getMinutes();
            const newDurationMinEnd =
                newDurationMinStart + getTotalProcedureDuration();
            setDurationMinStart(newDurationMinStart);
            setDurationMinEnd(newDurationMinEnd);
        }
    };

    React.useEffect(() => {
        if (isOpen) updateDurations();
    }, [isOpen, startTime, menuItems, additionalServices]);

    React.useEffect(() => {
        if (isOpen) {
            let discountApplied = false;
            let price =
                menuItems.reduce((a, b) => {
                    let allowedPercentage = GetServiceAllowedPercentage(
                        b.id,
                        false,
                        selectedDiscountCampaignItem
                    );
                    if (!discountApplied && allowedPercentage !== 1)
                        discountApplied = true;
                    return a + b.price * allowedPercentage;
                }, 0) +
                additionalServices.reduce((a, b) => {
                    let allowedPercentage = GetServiceAllowedPercentage(
                        b.id,
                        true,
                        selectedDiscountCampaignItem
                    );
                    if (!discountApplied && allowedPercentage !== 1)
                        discountApplied = true;
                    return a + b.count * b.price * allowedPercentage;
                }, 0);
            setPrice(Utils.RoundToMoney(price));
            setDiscountApplied(discountApplied);
        }
    }, [isOpen, menuItems, additionalServices, selectedDiscountCampaignItem]);

    const headerString = () => {
        if (startTime !== undefined) {
            let month = "" + (startTime!.getMonth() + 1),
                day = "" + startTime!.getDate();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return `${Utils.DurationToTimeString(
                durationStartMin
            )} - ${Utils.DurationToTimeString(
                durationEndMin - userData.apptAddTimeMin
            )} (${day}/${month})`;
        } else return "";
    };

    const getAddServiceItems = (
        addServiceItems: AdditionalServiceItemClientCounted[]
    ): Array<number> => {
        let items: Array<number> = [];
        for (let i = 0; i < addServiceItems.length; ++i) {
            for (let j = 0; j < addServiceItems[i].count; ++j) {
                items.push(Number.parseInt(addServiceItems[i].id.toString()));
            }
        }
        return items;
    };

    const getAddMenuIds = (menuItems: MenuItemClient[]) => {
        // Ignore first item, as it is the main one
        let ids: number[] = [];
        for (let i = 1; i < menuItems.length; ++i) {
            ids.push(Number.parseInt(menuItems[i].id.toString()));
        }
        return ids;
    };

    const getAvailableDiscountCampaignItems = (): DiscountCampaignItem[] => {
        if (startTime === undefined) return [];

        let startTimeMidnight = new Date(startTime);
        startTimeMidnight.setHours(0, 0, 0, 0);

        return discountCampaignItems.filter(
            (item) =>
                new Date(item.startDate) <= startTimeMidnight &&
                (item.endDate === null ||
                    new Date(item.endDate) >= startTimeMidnight)
        );
    };

    React.useEffect(() => {
        if (!isOpen) return;
        // In case of agenda item move check if discount is still applicable
        if (replaceAgendaItem && replaceAgendaItem!.discountId !== -1) {
            const searchRes = getAvailableDiscountCampaignItems().filter(
                (it) => it.id === replaceAgendaItem.discountId
            );

            if (searchRes.length > 0) {
                updateSelectedDiscountCampaign(searchRes[0]);
            } else {
                updateSelectedDiscountCampaign(null);
                setSelectedDiscountCampaignItemError(
                    i18n.t("discountCampaignWillNotBeAppliedError")
                );
            }
        } else if (selectedDiscountCampaignItem !== null) {
            const searchRes = getAvailableDiscountCampaignItems().filter(
                (it) => it.id === selectedDiscountCampaignItem.id
            );

            if (searchRes.length === 0) {
                updateSelectedDiscountCampaign(null);
                setSelectedDiscountCampaignItemError(
                    i18n.t("discountCampaignItemDatesNotInRangeError")
                );
            }
        }
    }, [replaceAgendaItem, startTime, isOpen]);

    const confirm = async () => {
        const nameError = StringValidators.nameValidator(name.value);
        const mobileError = StringValidators.mobileValidator(mobile.value);
        const commentError = StringValidators.commentValidator(comment.value);

        if (nameError || mobileError || commentError) {
            setName({ ...name, error: nameError });
            setMobile({ ...mobile, error: mobileError });
            setComment({ ...comment, error: commentError });
            return;
        }

        let reqObj: AgendaItem | ClientMoveAgendaItem;

        if (replaceAgendaItem !== null) {
            reqObj = {
                id: replaceAgendaItem.id,
                date: Utils.FormatDateForTransfer(startTime!),
                datePrev: Utils.FormatDateForTransfer(
                    new Date(replaceAgendaItem.date)
                ),
                timestartMin: durationStartMin,
                timeendMin: durationEndMin,
                timestartPrevMin: replaceAgendaItem.timestartMin,
                timeendPrevMin: replaceAgendaItem.timeendMin,
                // clientId is set on server side
                clientMobile: mobile.value,
                clientName: name.value,
                comment: comment.value,
                customFields: {},
                menuId: Number.parseInt(menuItems[0].id.toString()),
                addMenuIds: getAddMenuIds(menuItems),
                callmeback: callmeback,
                addServiceIds: getAddServiceItems(additionalServices),
                discountId: selectedDiscountCampaignItemId,
            } as ClientMoveAgendaItem;
        } else {
            reqObj = {
                id: 1, // Ignored
                date: Utils.FormatDateForTransfer(startTime!),
                timestartMin: durationStartMin,
                timeendMin: durationEndMin,
                // clientId is set on server side
                clientMobile: mobile.value,
                clientName: name.value,
                comment: comment.value,
                customFields: {},
                menuId: Number.parseInt(menuItems[0].id.toString()),
                addMenuIds: getAddMenuIds(menuItems),
                callmeback: callmeback,
                addServiceIds: getAddServiceItems(additionalServices),
                discountId: selectedDiscountCampaignItemId,
            } as AgendaItem;
        }

        let resp = await Network.Post(
            replaceAgendaItem !== null
                ? "userreplaceagendaitem"
                : "useraddagendaitem",
            reqObj
        );
        if (resp.status === 200) {
            setConfirmDlgHeader(i18n.t("successfulAgendaItem"));

            let month = "" + (startTime!.getMonth() + 1),
                day = "" + startTime!.getDate();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            setConfirmDlgBody(
                `${i18n.t("youAreBookedAt")} ${Utils.DurationToTimeString(
                    durationStartMin
                )} - ${
                    LocaleConfig.locales[LocaleConfig.defaultLocale].dayNames[
                        startTime!.getDay()
                    ]
                }  (${day}/${month}).`
            );

            setComment({ value: "", error: "" });
            handleClose(true);
            setConfirmDlgOpen(true);
        } else if (resp.status === 403) {
            setConfirmDlgHeader(i18n.t("errorTitle"));
            setConfirmDlgBody(i18n.t("agendaItemAlreadyExists"));

            setComment({ value: "", error: "" });
            setCallmeback(false);
            handleClose(true);
            setConfirmDlgOpen(true);
        } else if (resp.status === 451) {
            setConfirmDlgHeader(i18n.t("errorTitle"));
            setConfirmDlgBody(i18n.t("userBlockedMessage"));
            setUserIsBlocked(true);

            setComment({ value: "", error: "" });
            setCallmeback(false);
            handleClose(true);
            setConfirmDlgOpen(true);
        } else if (resp.status === 409) {
            setSelectedDiscountCampaignItemError(
                i18n.t("discountCampaignItemNewUsersOnlyError")
            );
        } else {
            //Should not get here
            alert(i18n.t("unexpectedError"));
        }
    };

    return (
        <div>
            {/* TODO: Fix Warning: findDOMNode is deprecated in StrictMode. */}
            <Dialog open={confirmDlgOpen}>
                <DialogTitle className={classes.confirmDlgTitle}>
                    {confirmDlgHeader}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.confirmDlgBody}>
                        <span>{confirmDlgBody}</span>
                        {!userIsBlocked && callmeback && (
                            <p>{i18n.t("youWillBeContacted")}</p>
                        )}
                        {!userIsBlocked && <p>{confirmDlgBodyExt}</p>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setConfirmDlgOpen(false);
                            setCallmeback(false);
                            backToMenu(true);
                        }}
                        color="primary"
                        autoFocus
                        className={classes.confirmDlgButton}
                    >
                        {i18n.t("ok")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal open={isOpen} className={css(currentAnimation)}>
                <div className={classes.background}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    setCurrentAnimation(
                                        animationStyles.slideOutDown
                                    );
                                    setTimeout(() => {
                                        setComment({ value: "", error: "" });
                                        setCallmeback(false);
                                        handleClose(false);
                                        setCurrentAnimation(
                                            animationStyles.slideInUp
                                        );
                                    }, 500);
                                }}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {headerString()}
                            </Typography>
                            <Button
                                color="inherit"
                                onClick={confirm}
                                className={classes.titleButton}
                            >
                                {i18n.t("confirmApt")}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    {/* Height adjustment is calculated based on app bar height and list margins */}
                    <List
                        className={classes.list}
                        style={{
                            height: dimensions.height - 8 - 56 - 8,
                            overflow: "auto",
                        }}
                    >
                        <ListItem>
                            <TextInput
                                readOnly={true}
                                multiline
                                label={
                                    menuItems.length > 1
                                        ? i18n.t("services")
                                        : i18n.t("service")
                                }
                                value={menuItems
                                    .map((item) => item.name)
                                    .join("\n")}
                                onChange={null}
                                errorText=""
                            />
                        </ListItem>
                        {additionalServices.length > 0 && (
                            <ListItem>
                                <TextInput
                                    readOnly={true}
                                    multiline
                                    label={i18n.t("addServices")}
                                    value={additionalServices
                                        .map((item) =>
                                            item.count > 1
                                                ? `- ${item.name} ${item.count}x`
                                                : `- ${item.name}`
                                        )
                                        .join("\n")}
                                    onChange={null}
                                    errorText=""
                                />
                            </ListItem>
                        )}
                        <ListItem>
                            <TextInput
                                autoComplete="name"
                                label={i18n.t("name")}
                                value={name.value}
                                onChange={(event: any) => {
                                    setName({
                                        value: event.target.value,
                                        error: "",
                                    });
                                }}
                                errorText={name.error}
                            />
                        </ListItem>
                        <ListItem>
                            <PhoneInput
                                defaultCountryCode={userData.countryCode.toLowerCase()}
                                autoComplete="tel"
                                label={i18n.t("mobile")}
                                value={mobile.value}
                                onChange={(text: any) => {
                                    setMobile({
                                        value: text,
                                        error: "",
                                    });
                                }}
                                errorText={mobile.error}
                            />
                        </ListItem>
                        <ListItem>
                            <DiscountsInput
                                dimensions={dimensions}
                                discountCampaignItems={getAvailableDiscountCampaignItems()}
                                updateDiscountsData={updateDiscountsData}
                                updatePromoCode={updatePromoCode}
                                updateSelectedDiscountCampaign={
                                    updateSelectedDiscountCampaign
                                }
                                selectedDiscountCampaignItemId={
                                    selectedDiscountCampaignItemId
                                }
                                promoCode={promoCode}
                                promoCodeInfoText={promoCodeInfoText}
                                selectedDiscountCampaignItem={
                                    selectedDiscountCampaignItem
                                }
                                selectedDiscountCampaignItemError={
                                    selectedDiscountCampaignItemError
                                }
                                setSelectedDiscountCampaignItemError={
                                    setSelectedDiscountCampaignItemError
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <TextInput
                                readOnly={true}
                                label={i18n.t("price")}
                                inputRedHighlighted={discountApplied}
                                value={price}
                                onChange={null}
                                errorText=""
                            />
                        </ListItem>
                        <ListItem>
                            <TextInput
                                multiline
                                label={i18n.t("comment")}
                                value={comment.value}
                                onChange={(event: any) => {
                                    setComment({
                                        value: event.target.value,
                                        error: "",
                                    });
                                }}
                                errorText={comment.error}
                            />
                        </ListItem>
                        <ListItem>
                            <FormControlLabel
                                style={{ color: theme.palette.secondary.main }}
                                control={
                                    <Checkbox
                                        value={callmeback}
                                        onChange={(event, checked) => {
                                            setCallmeback(checked);
                                        }}
                                    />
                                }
                                label={i18n.t("callmeback")}
                            />
                        </ListItem>
                    </List>
                </div>
            </Modal>
        </div>
    );
}
