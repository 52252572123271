import React from "react";
import { Grid, IconButton, makeStyles, TextField } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    cssLabel: {
        color: theme.palette.secondary.main,
    },

    cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
            borderColor: `${theme.palette.secondary.main} !important`,
        },
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: "2px",
        borderColor: `${theme.palette.secondary.main} !important`,
    },
}));

const positiveNumbersOnlyCheck = new RegExp("^[0-9]+$");
export default function NumberInput({
    errorText,
    label,
    value,
    onChange,
    ...props
}) {
    const classes = useStyles();
    return (
        <Grid container style={{ display: "flex", flexDirection: "column" }}>
            <Grid item>
                <IconButton
                    color="secondary"
                    component="span"
                    onClick={() => onChange(value + 1)}
                >
                    <AddRoundedIcon fontSize={isMobile ? "large" : "medium"} />
                </IconButton>
            </Grid>
            <Grid item>
                <TextField
                    size="small"
                    style={{ width: 35 }}
                    id="filled-error-helper-text"
                    InputLabelProps={{
                        shrink: true,
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                        },
                    }}
                    value={value}
                    onChange={(event) => {
                        if (positiveNumbersOnlyCheck.test(event.target.value)) {
                            onChange(Number.parseInt(event.target.value));
                        }
                    }}
                    helperText={errorText}
                    inputProps={{
                        style: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            textAlign: "center",
                        },
                    }}
                    InputProps={{
                        readOnly: props.readOnly,
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        },
                    }}
                    error={errorText !== ""}
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <IconButton
                    color="secondary"
                    component="span"
                    onClick={() => {
                        if (value > 0) onChange(value - 1);
                    }}
                >
                    <RemoveRoundedIcon
                        fontSize={isMobile ? "large" : "medium"}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
}
