import React from 'react';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        height: '100%',
        background: theme.palette.secondary.main
    },
    icon: { height: '40%', color: theme.palette.common.white }
}));

export default function SideButton({ handleClick, isLeft }) {
    const classes = useStyles();

    return (
        <ButtonBase
            className={classes.button}
            aria-label="upload picture"
            component="span"
            onClick={() => {
                handleClick(isLeft);
            }}
        >
            {isLeft ? (
                <ChevronLeft
                    className={classes.icon}
                    preserveAspectRatio={'none'}
                />
            ) : (
                <ChevronRight
                    className={classes.icon}
                    preserveAspectRatio={'none'}
                />
            )}
        </ButtonBase>
    );
}
