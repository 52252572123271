import i18n from "i18n-js";
import { isMobile } from "react-device-detect";
import { makeStyles, Typography } from "@material-ui/core";
import sorry from "../assets/sorry.png";
import { theme } from "../style/theme";

const useStyles = makeStyles({
    index: {
        backgroundColor: theme.palette.background.default,
        minHeight: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 20,
    },
    img: {
        height: isMobile ? "250px" : "300px",
    },
    textFirst: {
        color: theme.palette.secondary.main,
        textAlign: "center",
        paddingTop: 40,
        fontWeight: "bold",
    },
    textSecond: {
        color: theme.palette.primary.main,
        textAlign: "center",
        paddingTop: 40,
    },
});

export default function OnlineBookingDisabledScreen() {
    const classes = useStyles();

    return (
        <div className={classes.index}>
            <img src={sorry} className={classes.img} alt="Error logo" />
            <Typography
                variant={isMobile ? "h4" : "h3"}
                className={classes.textFirst}
            >
                {i18n.t("onlineBookingDisabledP1")}
            </Typography>
            <Typography
                variant={isMobile ? "h5" : "h5"}
                className={classes.textSecond}
            >
                {i18n.t("onlineBookingDisabledP2")}
            </Typography>
        </div>
    );
}
