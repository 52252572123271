import i18n from "i18n-js";
import React from "react";
import Network from "../network";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TimeTableScreen from "./TimeTableScreen";
import {
    Utils,
    MenuItemClient,
    ClientInfoInterface,
    AgendaItem,
    AdditionalServiceItemClientCounted,
    AdditionalServiceItemClient,
    DiscountCampaignItem,
} from "visit-shared";
import { Badge, Button, Link, Switch, Typography } from "@material-ui/core";
import ActiveBookingsScreen from "./ActiveBookingsScreen";
import AdditionalServicesScreen from "./AdditionalServicesScreen";
import { userUITheme } from "../style/theme";
import { slideInRight } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import { GetServiceAllowedPercentage } from "../components/ConfirmDialog";
import { TextInputItem } from "../components/TextInput";
import DiscountsInput from "../components/DiscountsInput";

const nextButtonAnimationStyles = StyleSheet.create({
    slideInRight: {
        animationName: slideInRight,
        animationDuration: "0.5s",
    },
});

const useStyles = makeStyles((theme) => ({
    table: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "top",
        justifyContent: "top",
        backgroundColor: theme.palette.background.default,
        overflowX: "hidden", // Fixed "Next" button appearance animation when scrolled down in Chrome mobile
    },
    containerBody: {
        padding: 4,
    },
    activeBookingsHeader: {
        position: "absolute",
        top: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.secondary.main,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        boxShadow: "0px 0px 20px 5px " + theme.palette.secondary.main + "99",
        color: theme.palette.common.white,
        zIndex: 1,
    },
    activeBookingsHeaderBelow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
    blendInBackground: {
        color: theme.palette.background.default,
    },
    activeBookingsButtonArea: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
    },
    activeBookingsButton: {
        textTransform: "none",
        whiteSpace: "nowrap",
        fontFamily: theme.typography.fontFamily,
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
        color: theme.palette.common.white,
        borderWidth: "3px",
        borderColor: theme.palette.common.white,
        height: "35px",
        borderRadius: 15,
        marginBottom: 10,
        marginTop: 11,
        marginLeft: 4,
    },
    activeBookingsButtonHide: {
        color: theme.palette.background.default,
        borderColor: theme.palette.background.default,
    },
    titleButton: {
        width: 120,
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.default,
        borderRadius: 20,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: theme.palette.background.default,
        },
        margin: 10,
    },
    titleButtonBehind: {
        width: 120,
        fontWeight: "bold",
        color: theme.palette.background.default,
        backgroundColor: theme.palette.background.default,
        borderRadius: 20,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: theme.palette.background.default,
        },
        margin: 10,
    },
    headerText: {
        paddingTop: 5,
        paddingBottom: 10,
        fontFamily: theme.typography.fontFamily,
        fontWeight: "bold",
        textAlign: "center",
        color: theme.palette.secondary.main,
    },
    footerText: {
        paddingTop: 15,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        textAlign: "center",
    },
    tableContainer: {
        borderRadius: 20,
        borderColor: theme.palette.secondary.main,
        borderStyle: "solid",
        borderWidth: 1,
        width: "99.7%",
        boxShadow: "0px 0px 20px 5px " + theme.palette.secondary.main + "99",
    },
    tableRow: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.paper,
        },
    },
    headerCell: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontSize: 16,
    },
    headerNumericCell: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontSize: 16,
        textAlign: "right",
    },
    numericCell: {
        fontSize: 16,
        textAlign: "right",
    },
    nameCell: {
        fontSize: 16,
        wordBreak: "break-word",
    },
    description: {
        fontSize: 12,
        fontStyle: "italic",
    },
    discountsView: {
        marginTop: 16,
    },
    priceOld: {
        textDecoration: theme.palette.error.main + " line-through",
    },
    priceDiscounted: {
        color: theme.palette.error.main,
    },
}));

interface ServicesMenuProps {
    userData: ClientInfoInterface;
}

export default function ServicesMenu({ userData }: ServicesMenuProps) {
    const [dimensions, setDimensions] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const classes = useStyles();
    const hasBeenCalled = React.useRef(false);
    const [menuItems, setMenuItems] = React.useState<MenuItemClient[]>([]);
    const [additionalServices, setAdditionalServices] = React.useState<
        AdditionalServiceItemClient[]
    >([]);
    const [selectedMenuItems, setSelectedMenuItems] = React.useState<
        MenuItemClient[]
    >([]);
    const selectedAdditionalServices = React.useRef<
        AdditionalServiceItemClientCounted[]
    >([]);
    const replaceAgendaItem = React.useRef<AgendaItem | null>(null);

    const [activeBookings, setActiveBookings] = React.useState<AgendaItem[]>(
        []
    );

    const [promoCode, setPromoCode] = React.useState<TextInputItem>({
        value: "",
        error: "",
    });
    const [promoCodeInfoText, setPromoCodeInfoText] = React.useState("");
    const updatePromoCode = (
        promoCode: TextInputItem,
        applied: boolean = false
    ) => {
        setPromoCode({ ...promoCode });

        if (applied) setPromoCodeInfoText(i18n.t("promoCodeApplied"));
        else setPromoCodeInfoText("");
    };

    const [discountCampaignItems, setDiscountCampaignItems] = React.useState<
        DiscountCampaignItem[]
    >([]);

    const [selectedDiscountCampaignItemId, setSelectedDiscountCampaignItemId] =
        React.useState<number>(-1);
    const [selectedDiscountCampaignItem, setSelectedDiscountCampaignItem] =
        React.useState<DiscountCampaignItem | null>(null);

    const updateSelectedDiscountCampaign = (
        item: DiscountCampaignItem | null
    ) => {
        if (item) {
            setSelectedDiscountCampaignItem(item);
            setSelectedDiscountCampaignItemId(item.id);
        } else {
            setSelectedDiscountCampaignItem(null);
            setSelectedDiscountCampaignItemId(-1);
        }
    };

    const updateActiveBookings = async () => {
        const now = new Date();
        const nowDate = Utils.FormatDateForTransfer(now);
        const nowTime = now.getHours() * 60 + now.getMinutes();

        let bookingsResp = await Network.Get(
            `activebookings/${encodeURIComponent(nowDate)}&${nowTime}`
        );
        if (bookingsResp.status === 200) {
            const items = bookingsResp.data as AgendaItem[];
            items.forEach((it) => {
                it.id = Number.parseInt(it.id.toString());
                it.menuId = Number.parseInt(it.menuId.toString());
                it.addMenuIds = it.addMenuIds.map((item) =>
                    Number.parseInt(item.toString())
                );
                it.addServiceIds = it.addServiceIds.map((item) =>
                    Number.parseInt(item.toString())
                );
                it.discountId = Number.parseInt(it.discountId.toString());
            });
            setActiveBookings(items);
            if (items.length > 0) {
                setAutoFillName(items[0].clientName);
                setAutoFillMobile(items[0].clientMobile);
            }
        }
    };

    const [autoFillName, setAutoFillName] = React.useState("");
    const [autoFillMobile, setAutoFillMobile] = React.useState("");

    const [timeTableOpen, setTimeTableOpen] = React.useState(false);
    const handleTimeTableOpen = (
        items: MenuItemClient[],
        additionalServices: AdditionalServiceItemClientCounted[]
    ) => {
        setSelectedMenuItems(items);
        selectedAdditionalServices.current = additionalServices;
        setTimeTableOpen(true);
    };

    const handleTimeTableClose = (doUpdate: boolean) => {
        if (doUpdate) {
            updateActiveBookings();
            handleActiveBookingsOpen();
        }
        setSelectedMenuItems([]);
        selectedAdditionalServices.current = [];
        replaceAgendaItem.current = null;
        setTimeTableOpen(false);
    };

    const [activeBookingsOpen, setActiveBookingsOpen] = React.useState(false);

    const handleActiveBookingsOpen = () => {
        setActiveBookingsOpen(true);
    };

    const handleActiveBookignsClose = () => {
        setActiveBookingsOpen(false);
        updateActiveBookings();
    };

    const onAgendaItemMove = (
        item: AgendaItem,
        additionalServices: AdditionalServiceItemClientCounted[]
    ) => {
        const menuIt = menuItems.filter(
            (menuIt) =>
                menuIt.id === item.menuId ||
                item.addMenuIds.indexOf(menuIt.id) !== -1
        );
        if (menuIt.length > 0) {
            handleActiveBookignsClose();
            replaceAgendaItem.current = item;
            handleTimeTableOpen(menuIt, additionalServices);
        }
    };

    const [additionalServicesOpen, setAdditionalServicesOpen] =
        React.useState(false);

    const handleAdditionalServicesOpen = (items: MenuItemClient[]) => {
        setSelectedMenuItems(items);
        setAdditionalServicesOpen(true);
    };

    const handleAdditionalServicesClose = (goForward: boolean) => {
        if (goForward) {
            // selectedMenuItems and selectedAdditionalServices set at this point
            setTimeTableOpen(true);
        }
        setAdditionalServicesOpen(false);
    };

    const constructor = async () => {
        if (hasBeenCalled.current) return;
        hasBeenCalled.current = true;

        updateActiveBookings();

        let menuResp = await Network.Get(`usermenu`);
        if (menuResp.status === 200) {
            const data = menuResp.data as MenuItemClient[];
            data.forEach((item) => {
                item.id = Number.parseInt(item.id.toString());
                item.price = Number.parseFloat(item.price.toString());
            });
            setMenuItems(data);
        }

        let addServicesResp = await Network.Get(`useraddservices`);
        if (addServicesResp.status === 200) {
            const data = addServicesResp.data as AdditionalServiceItemClient[];
            data.forEach((item) => {
                item.id = Number.parseInt(item.id.toString());
                item.price = Number.parseFloat(item.price.toString());
            });
            setAdditionalServices(data);
        }

        await updateDiscountsData();
    };

    const updateDiscountsData = async () => {
        let bookingLink = window.location.search.startsWith("?d")
            ? window.location.search.substring(1)
            : "null";

        // Cut any additional parameters attached
        let additionalParametersStart = bookingLink.indexOf("&");
        if (additionalParametersStart !== -1)
            bookingLink = bookingLink.substring(0, additionalParametersStart);

        let promoCodeReq = promoCode.value === "" ? "null" : promoCode.value;

        let discountsResp = await Network.Get(
            `getdiscounts/${encodeURIComponent(
                Utils.FormatDateForTransfer(new Date())
            )}/${promoCodeReq}/${bookingLink}`
        );
        if (discountsResp.status === 200) {
            const data = discountsResp.data as DiscountCampaignItem[];
            data.forEach(
                (item) => (item.id = Number.parseInt(item.id.toString()))
            );
            setDiscountCampaignItems(data);

            let bookingLink = window.location.search.startsWith("?d")
                ? window.location.search.substring(1)
                : "null";

            let promoCodeReq =
                promoCode.value === "" ? "null" : promoCode.value;

            if (promoCodeReq !== "null") {
                const searchRes = data.filter(
                    (it) => it.promoCode === promoCodeReq
                );
                if (searchRes.length > 0) {
                    const promoCodeDiscount = searchRes[0];
                    updateSelectedDiscountCampaign(promoCodeDiscount);
                    updatePromoCode(promoCode, true);
                } else {
                    updateSelectedDiscountCampaign(null);
                    updatePromoCode({
                        ...promoCode,
                        error: i18n.t("promoCodeNotFound"),
                    });
                }
            } else if (bookingLink !== "null") {
                const searchRes = data.filter(
                    (it) => it.bookingLink === bookingLink
                );
                if (searchRes.length > 0) {
                    const bookingLinkDiscount = searchRes[0];
                    updateSelectedDiscountCampaign(bookingLinkDiscount);
                }
            }
        }
    };

    React.useEffect(() => {
        constructor();
    });

    return (
        <div
            style={{
                background: userUITheme.palette.background.default,
                minHeight: dimensions.height,
            }}
        >
            <Modal open={timeTableOpen} hideBackdrop={true}>
                <div>
                    <TimeTableScreen
                        dimensions={dimensions}
                        onClose={handleTimeTableClose}
                        menuItems={selectedMenuItems}
                        additionalServices={selectedAdditionalServices.current}
                        userData={userData}
                        autoFillName={autoFillName}
                        autoFillMobile={autoFillMobile}
                        replaceAgendaItem={replaceAgendaItem.current}
                        discountCampaignItems={discountCampaignItems}
                        updateDiscountsData={updateDiscountsData}
                        updatePromoCode={updatePromoCode}
                        updateSelectedDiscountCampaign={
                            updateSelectedDiscountCampaign
                        }
                        selectedDiscountCampaignItemId={
                            selectedDiscountCampaignItemId
                        }
                        promoCode={promoCode}
                        promoCodeInfoText={promoCodeInfoText}
                        selectedDiscountCampaignItem={
                            selectedDiscountCampaignItem
                        }
                    />
                </div>
            </Modal>
            <ActiveBookingsScreen
                dimensions={dimensions}
                open={activeBookingsOpen}
                onClose={handleActiveBookignsClose}
                onAgendaItemMove={onAgendaItemMove}
                activeBookings={activeBookings}
                menu={menuItems}
                additionalServices={additionalServices}
                userData={userData}
            />
            <AdditionalServicesScreen
                dimensions={dimensions}
                open={additionalServicesOpen}
                onClose={handleAdditionalServicesClose}
                additionalServices={additionalServices}
                selectedMenuItems={selectedMenuItems}
                selectedAdditionalServicesRef={selectedAdditionalServices}
                selectedDiscountCampaignItem={selectedDiscountCampaignItem}
            />
            <div className={classes.container}>
                <div className={classes.activeBookingsHeader}>
                    <div className={classes.activeBookingsButtonArea}>
                        <Button
                            variant="outlined"
                            className={classes.activeBookingsButton}
                            onClick={handleActiveBookingsOpen}
                            endIcon={
                                <Badge
                                    overlap="rectangular"
                                    badgeContent={activeBookings.length}
                                    color="primary"
                                    anchorOrigin={{
                                        horizontal: "right",
                                        vertical: "bottom",
                                    }}
                                    showZero
                                >
                                    <AssignmentRoundedIcon fontSize="small" />
                                </Badge>
                            }
                        >
                            {i18n.t("myBookings")}
                        </Button>
                    </div>
                    {selectedMenuItems.length > 0 && (
                        <Button
                            className={
                                classes.titleButton +
                                " " +
                                css(nextButtonAnimationStyles.slideInRight)
                            }
                            color="secondary"
                            onClick={() => {
                                // Button is not visible when no items selected, but check just in case
                                if (selectedMenuItems.length > 0) {
                                    if (additionalServices.length === 0)
                                        handleTimeTableOpen(
                                            selectedMenuItems,
                                            []
                                        );
                                    else
                                        handleAdditionalServicesOpen(
                                            selectedMenuItems
                                        );
                                }
                            }}
                        >
                            {i18n.t("next")}
                        </Button>
                    )}
                </div>
                <div
                    style={{
                        maxHeight: dimensions.height,
                        overflow: "auto",
                    }}
                >
                    <div className={classes.activeBookingsHeaderBelow}>
                        <div className={classes.activeBookingsButtonArea}>
                            <Button
                                variant="outlined"
                                className={
                                    classes.activeBookingsButton +
                                    " " +
                                    classes.activeBookingsButtonHide
                                }
                                endIcon={
                                    <AssignmentRoundedIcon fontSize="medium" />
                                }
                            >
                                {i18n.t("myBookings")}
                            </Button>
                        </div>
                        {selectedMenuItems.length > 0 && (
                            <Button className={classes.titleButtonBehind}>
                                {i18n.t("next")}
                            </Button>
                        )}
                    </div>
                    <div className={classes.containerBody}>
                        <div className={classes.discountsView}>
                            <DiscountsInput
                                dimensions={dimensions}
                                discountCampaignItems={discountCampaignItems}
                                selectedDiscountCampaignItem={
                                    selectedDiscountCampaignItem
                                }
                                updateDiscountsData={updateDiscountsData}
                                updatePromoCode={updatePromoCode}
                                updateSelectedDiscountCampaign={
                                    updateSelectedDiscountCampaign
                                }
                                selectedDiscountCampaignItemId={
                                    selectedDiscountCampaignItemId
                                }
                                promoCode={promoCode}
                                promoCodeInfoText={promoCodeInfoText}
                            />
                        </div>
                        <Typography
                            variant={"h6"}
                            className={classes.headerText}
                        >
                            {i18n.t("selectServices")}
                        </Typography>
                        <TableContainer className={classes.tableContainer}>
                            <Table
                                className={classes.table}
                                aria-label="customized table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className={classes.headerCell}
                                        >
                                            {i18n.t("title")}
                                        </TableCell>
                                        <TableCell
                                            className={
                                                classes.headerNumericCell
                                            }
                                        >
                                            {i18n.t("duration")}
                                        </TableCell>
                                        <TableCell
                                            className={
                                                classes.headerNumericCell
                                            }
                                        >
                                            {i18n.t("price")}
                                        </TableCell>
                                        <TableCell
                                            className={
                                                classes.headerNumericCell
                                            }
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {menuItems.map((item) => (
                                        <TableRow
                                            className={classes.tableRow}
                                            key={item.id}
                                            onClick={() => {
                                                let index =
                                                    selectedMenuItems.findIndex(
                                                        (value) =>
                                                            value.id === item.id
                                                    );
                                                if (index !== -1) {
                                                    selectedMenuItems.splice(
                                                        index,
                                                        1
                                                    );
                                                } else {
                                                    selectedMenuItems.push(
                                                        item
                                                    );
                                                }
                                                setSelectedMenuItems([
                                                    ...selectedMenuItems,
                                                ]);
                                            }}
                                        >
                                            <TableCell
                                                className={classes.nameCell}
                                                component="th"
                                                scope="row"
                                            >
                                                <div>
                                                    <Typography>
                                                        {item.name}
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.description
                                                        }
                                                    >
                                                        {item.description}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className={classes.numericCell}
                                            >
                                                {Utils.GetItemDurationString(
                                                    item.durationMins
                                                )}
                                            </TableCell>
                                            <TableCell
                                                className={classes.numericCell}
                                            >
                                                {selectedDiscountCampaignItem ===
                                                    null ||
                                                GetServiceAllowedPercentage(
                                                    item.id,
                                                    false,
                                                    selectedDiscountCampaignItem
                                                ) === 1 ? (
                                                    item.price
                                                ) : (
                                                    <div>
                                                        <div
                                                            className={
                                                                classes.priceOld
                                                            }
                                                        >
                                                            {item.price}
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.priceDiscounted
                                                            }
                                                        >
                                                            {Utils.RoundToMoney(
                                                                item.price *
                                                                    GetServiceAllowedPercentage(
                                                                        item.id,
                                                                        false,
                                                                        selectedDiscountCampaignItem
                                                                    )
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </TableCell>

                                            <TableCell align="center">
                                                <Switch
                                                    style={{
                                                        alignContent: "center",
                                                    }}
                                                    checked={
                                                        selectedMenuItems.findIndex(
                                                            (value) =>
                                                                value.id ===
                                                                item.id
                                                        ) !== -1
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography className={classes.footerText}>
                            {i18n.t("poweredBy")}
                            <Link href="/" color="secondary">
                                {i18n.t("appName")}
                            </Link>
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}
