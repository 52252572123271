import i18n from "i18n-js";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DiscountCampaignItem, StringValidators, Utils } from "visit-shared";
import DropDown from "./DropDown";
import { Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import TextInput, { TextInputItem } from "./TextInput";
import DiscountsInfoDialog from "./DiscountsInfoDialog";
import { Dimensions } from "../style/theme";

const useStyles = makeStyles((theme) => ({
    discountsView: {
        width: "100%",
    },
    promoCodeInputView: {
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
    },
    promoCodeButton: {
        marginLeft: 5,
        borderRadius: 18,
        height: 56,
    },
    promoCodeButtonIcon: {
        fontSize: 36,
    },
    discountsInputView: {
        display: "flex",
        flexDirection: "row",
    },
    discountsInfoButton: {
        marginLeft: 5,
        borderRadius: 18,
        height: 56,
    },
    discountsInfoButtonIcon: {
        fontSize: 36,
    },
}));

let TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);

interface DiscountsInputProps {
    dimensions: Dimensions;
    discountCampaignItems: DiscountCampaignItem[];
    updateDiscountsData: any;
    updatePromoCode: any;
    updateSelectedDiscountCampaign: any;
    selectedDiscountCampaignItemId: number;
    promoCode: TextInputItem;
    promoCodeInfoText: string;
    selectedDiscountCampaignItem: DiscountCampaignItem | null;
    selectedDiscountCampaignItemError?: string;
    setSelectedDiscountCampaignItemError?: any;
}

export default function DiscountsInput({
    dimensions,
    discountCampaignItems,
    updateDiscountsData,
    updatePromoCode,
    updateSelectedDiscountCampaign,
    selectedDiscountCampaignItemId,
    promoCode,
    promoCodeInfoText,
    selectedDiscountCampaignItem,
    selectedDiscountCampaignItemError,
    setSelectedDiscountCampaignItemError,
}: DiscountsInputProps) {
    const classes = useStyles();

    const [showDiscountsInfoDialog, setShowDiscountsInfoDialog] =
        React.useState(false);

    function discountItemNameDisplay(item: DiscountCampaignItem) {
        const startDate = new Date(item.startDate);
        let dateRangeString = "";

        if (startDate > TODAY) {
            dateRangeString += ` ${i18n.t(
                "durationFromLowerCase"
            )} ${Utils.GetDateMonthString(startDate)}`;
        }
        if (item.endDate !== null) {
            dateRangeString += ` ${i18n.t(
                "durationTo"
            )} ${Utils.GetDateMonthString(item.endDate)}`;
        }
        return item.newUsersOnly
            ? `${item.name} ${dateRangeString} (${i18n.t("newUsersOnly")})`
            : `${item.name} ${dateRangeString}`;
    }

    const processPromoCode = () => {
        const promoCodeError =
            promoCode.value === ""
                ? i18n.t("fieldCantBeEmpty")
                : StringValidators.promoCodeValidator(promoCode.value);

        if (promoCodeError) {
            updatePromoCode({ ...promoCode, error: promoCodeError });
            return;
        }

        updateDiscountsData();
    };

    return discountCampaignItems.length > 0 ? (
        <div className={classes.discountsView}>
            <div className={classes.discountsInputView}>
                <DropDown
                    includeNone
                    dropDownItems={discountCampaignItems}
                    label={i18n.t("discount")}
                    itemNameDisplayFunc={discountItemNameDisplay}
                    onChange={(event) => {
                        let id = event.target.value as number;
                        const searchRes = discountCampaignItems.filter(
                            (it) => it.id === id
                        );
                        if (searchRes.length > 0)
                            updateSelectedDiscountCampaign(searchRes[0]);
                        else updateSelectedDiscountCampaign(null);

                        if (
                            selectedDiscountCampaignItemError !== undefined &&
                            selectedDiscountCampaignItemError !== "" &&
                            setSelectedDiscountCampaignItemError !== undefined
                        ) {
                            setSelectedDiscountCampaignItemError("");
                        }
                    }}
                    value={selectedDiscountCampaignItemId}
                    errorText={
                        selectedDiscountCampaignItemError !== undefined
                            ? selectedDiscountCampaignItemError
                            : ""
                    }
                />
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.discountsInfoButton}
                    onClick={() => setShowDiscountsInfoDialog(true)}
                >
                    <InfoIcon className={classes.discountsInfoButtonIcon} />
                </Button>
            </div>
            <div className={classes.promoCodeInputView}>
                <TextInput
                    label={i18n.t("promoCode")}
                    value={promoCode.value}
                    onChange={(event: any) => {
                        updatePromoCode({
                            value: event.target.value,
                            error: "",
                        });
                    }}
                    errorText={promoCode.error}
                    infoText={promoCodeInfoText}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.promoCodeButton}
                    onClick={processPromoCode}
                >
                    <CheckCircleIcon className={classes.promoCodeButtonIcon} />
                </Button>
            </div>
            <DiscountsInfoDialog
                dimensions={dimensions}
                isOpen={showDiscountsInfoDialog}
                handleClose={() => setShowDiscountsInfoDialog(false)}
                today={TODAY}
                discountCampaignItems={discountCampaignItems}
                updateSelectedDiscountCampaign={updateSelectedDiscountCampaign}
                selectedDiscountCampaignItem={selectedDiscountCampaignItem}
            />
        </div>
    ) : null;
}
