import React from 'react';
import {
    Box,
    Modal,
    Typography,
    makeStyles,
    Button,
    SvgIcon,
    Link
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { theme } from '../style/theme';
import PT, { AppStoreLink, GooglePlayLink } from '../PromoTexts';
import qrAppStore from '../assets/astore.svg';
import qrGooglePlay from '../assets/gplay.svg';

const useStyles = makeStyles({
    container: {
        minWidth: 300,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        padding: 20
    },
    captionText: {
        margin: 10,
        color: theme.palette.secondary.main,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    downloadContainer: {
        marginTop: 20,
        display: 'flex',
        flex: 1,
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center'
    },
    buttonImageContainer: {
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center'
    },
    qrCodeImg: {
        width: 250,
        height: 250,
        margin: 10
    },
    downloadButton: {
        width: '100%',
        margin: isMobile ? 0 : 10,
        borderRadius: 10,
        fontWeight: 'bold',
        textTransform: 'none'
    },
    shadow: {
        boxShadow: '3px 3px 3px 2px ' + theme.palette.secondary.main + '99'
    },
    downloadSvg: {
        marginTop: -3,
        width: '25px',
        height: '25px'
    },
    agreement: {
        color: theme.palette.text.primary,
        textAlign: 'center',
        paddingTop: 20
    }
});

export default function DownloadModal({ SL, open, setOpen }) {
    const classes = useStyles();

    const navigateTo = (link) => {
        window.location.href = link;
    };

    const copyToClipboard = (link) => {
        navigator.clipboard.writeText(link);
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box className={classes.container}>
                <Typography className={classes.captionText} variant="h4">
                    {PT[SL].downloadVisitFree}
                </Typography>
                <div className={classes.downloadContainer}>
                    {
                        <div className={classes.buttonImageContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={
                                    classes.downloadButton +
                                    ' ' +
                                    classes.shadow
                                }
                                onClick={() => navigateTo(AppStoreLink)}
                                startIcon={
                                    <SvgIcon className={classes.downloadSvg}>
                                        <svg viewBox="0 0 24 24">
                                            <path
                                                fill="currentColor"
                                                d="M18.71,19.5C17.88,20.74 17,21.95 15.66,21.97C14.32,22 13.89,21.18 12.37,21.18C10.84,21.18 10.37,21.95 9.1,22C7.79,22.05 6.8,20.68 5.96,19.47C4.25,17 2.94,12.45 4.7,9.39C5.57,7.87 7.13,6.91 8.82,6.88C10.1,6.86 11.32,7.75 12.11,7.75C12.89,7.75 14.37,6.68 15.92,6.84C16.57,6.87 18.39,7.1 19.56,8.82C19.47,8.88 17.39,10.1 17.41,12.63C17.44,15.65 20.06,16.66 20.09,16.67C20.06,16.74 19.67,18.11 18.71,19.5M13,3.5C13.73,2.67 14.94,2.04 15.94,2C16.07,3.17 15.6,4.35 14.9,5.19C14.21,6.04 13.07,6.7 11.95,6.61C11.8,5.46 12.36,4.26 13,3.5Z"
                                            />
                                        </svg>
                                    </SvgIcon>
                                }
                            >
                                App Store
                            </Button>
                            {!isMobile && (
                                <>
                                    <img
                                        src={qrAppStore}
                                        className={classes.qrCodeImg}
                                        alt="QR code App Store"
                                    />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.downloadButton}
                                        onClick={() =>
                                            copyToClipboard(AppStoreLink)
                                        }
                                    >
                                        {PT[SL].copyLink}
                                    </Button>
                                </>
                            )}
                        </div>
                    }
                    <div className={classes.buttonImageContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={
                                classes.downloadButton + ' ' + classes.shadow
                            }
                            onClick={() => navigateTo(GooglePlayLink)}
                            startIcon={
                                <SvgIcon className={classes.downloadSvg}>
                                    <svg viewBox="0 0 24 24">
                                        <path
                                            fill="currentColor"
                                            d="M3,20.5V3.5C3,2.91 3.34,2.39 3.84,2.15L13.69,12L3.84,21.85C3.34,21.6 3,21.09 3,20.5M16.81,15.12L6.05,21.34L14.54,12.85L16.81,15.12M20.16,10.81C20.5,11.08 20.75,11.5 20.75,12C20.75,12.5 20.53,12.9 20.18,13.18L17.89,14.5L15.39,12L17.89,9.5L20.16,10.81M6.05,2.66L16.81,8.88L14.54,11.15L6.05,2.66Z"
                                        />
                                    </svg>
                                </SvgIcon>
                            }
                        >
                            Google Play
                        </Button>
                        {!isMobile && (
                            <>
                                <img
                                    src={qrGooglePlay}
                                    className={classes.qrCodeImg}
                                    alt="QR code Google Play"
                                />
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.downloadButton}
                                    onClick={() =>
                                        copyToClipboard(GooglePlayLink)
                                    }
                                >
                                    {PT[SL].copyLink}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <Typography className={classes.agreement}>
                    {PT[SL].agreementP1}
                    <Link href="/privacy" color="secondary">
                        {PT[SL].agreementP2}
                    </Link>
                    {', '}
                    <Link href="/terms" color="secondary">
                        {PT[SL].agreementP3}
                    </Link>
                </Typography>
            </Box>
        </Modal>
    );
}
