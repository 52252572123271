import { Emails } from "visit-shared";

export const CompanyEmail = Emails.supportEmail;
export const AppStoreLink = "https://apps.apple.com/app/ltj-visit/id1601051941";
export const GooglePlayLink =
    "https://play.google.com/store/apps/details?id=com.ltjdevelopment.visit";

const en = {
    promoCaption: "Free app for online client appointment booking",
    mainPage: "Main",
    contactsPage: "Contacts",
    privacyPolicyPage: "Privacy Policy",
    termsConditionsPage: "Terms and Conditions",
    downloadFree: "Download for Free",
    downloadVisitFree: "Download Visit for free",
    copyLink: "Copy download link",
    agreementP1: "By installing an application you agree to ",
    agreementP2: "Privacy Policy",
    agreementP3: "Terms and Conditions",
    details: "Details",
    contacts: "Contacts:",
    mail: "Email: ",
    navigate: "Navigate",
    onlineBookingCard: {
        header: "Online client appointment booking",
        bullets: [
            "Personal link for online appointment booking",
            "Free web interface for your clients",
            "Access to online appointment booking without registration for your clients",
            "Ability to cancel/move appointments for your clients",
            "Convenient notification on new client appointments, cancelation and appointment move",
            "Full control over appointment booking settings (allowed times, amount of slots, booking step, client control, interface language, time format, control of the ability to cancel and transfer appointments by clients)",
        ],
        details: "",
    },
    bussinessAutomationCard: {
        header: "Automation of business processes",
        bullets: [
            "Client appointment booking in a few clicks",
            "Client database and management",
            "Automatic appointment reminders",
            "Automatic appointment duplication",
            "Management of work schedule, costs and services",
            "Unlimited client message templates",
            "Automated notification sending to the client via SMS, messengers (Telegram, WhatsApp, Viber) and social networks (Instagram)",
            "The ability to plan a vacation or breaks from several hours to several months",
            "Convenient appointment schedule views (schedule, week, 3 days)",
        ],
        details: "",
    },
    discountsCard: {
        header: "Discounts",
        bullets: [
            "Create your own flexible discount campaigns",
            "Manage promo codes and discount booking links",
            "Define date ranges for your discounts",
            "Allow clients to pick from a list of discounts you define",
            "Create automatic discounts for new clients only",
            "Put your discount campagins on hold when needed",
        ],
    },
    statisticsCard: {
        header: "Statistics",
        bullets: [
            "Number of unique views and posts",
            "Optimization of the workload of the days of the week",
            "Detailed statistics by groups of income and expenses (day, week, month)",
            "Automatic calculation of planned and real income and expenses",
            "Convenient data graphs",
            "Useful data about each customer (number of actual and planned appointments, income by client, client return rate, client non-arrival rate, tips)",
        ],
        details: "",
    },
    weWorkForYouCard: {
        header: "We work for You",
        bullets: [
            "Completely free app",
            "All data is stored securely on Amazon servers",
            "Ability to export all your data in convenient spreadsheet format supported by all table processors",
            "Advanced security protocols for data transmission, integration with Google",
            "Complete anonymity of data. We do not disclose information about our clients to third parties",
            "Ability to delete your data from our servers",
            "Convenient feedback form",
            "Request new functionality or localization and we will add it to the next release",
            "Found an error? Please let us know and we will fix it as soon as possible",
        ],
        details: "",
    },
};
const ru = {
    promoCaption: "Бесплатное приложение для онлайн-записи клиентов",
    mainPage: "Главная",
    contactsPage: "Контакты",
    privacyPolicyPage: "Политика конфиденциальности",
    termsConditionsPage: "Условия использования",
    downloadFree: "Скачать Бесплатно",
    downloadVisitFree: "Скачать Visit бесплатно",
    copyLink: "Скопировать ссылку для скачивания",
    agreementP1: "Устанавливая приложение Вы соглашаетесь с ",
    agreementP2: "Политикой конфиденциальности",
    agreementP3: "Условиями использования",
    details: "Детали",
    contacts: "Контакты:",
    mail: "Почта: ",
    navigate: "Перейти",
    onlineBookingCard: {
        header: "Онлайн запись клиентов",
        bullets: [
            "Персональная ссылка для размещения онлайн-бронирования",
            "Бесплатный веб-интерфейс для Ваших клиентов",
            "Доступ к онлайн-бронированию без регистрации для Ваших клиентов",
            "Возможность отмены/переноса записи для Ваших клиентов",
            "Удобные уведомления о бронировании, отмене и переносе брони",
            "Полный контроль над настройками бронирования (время, количество записей, шаг бронирования, контроль клиентов, язык интерфейса, формат времени, контроль возможности отменять и переносить записи клиентами)",
        ],
        details: "",
    },
    bussinessAutomationCard: {
        header: "Автоматизация бизнес-процессов",
        bullets: [
            "Запись клиентов в несколько кликов",
            "База данных и управление клиентами",
            "Автоматические напоминания о записях",
            "Автоматическое дублирование записей",
            "Управление рабочим графиком, расходами и услугами",
            "Неограниченное количество шаблонов сообщений клиенту",
            "Автоматизация отправки уведомлений клиенту по SMS, мессенджеры (Telegram, WhatsApp, Viber) и соц. сети (Instagram)",
            "Возможность планировать отпуск и перерывы от нескольких часов до нескольких месяцев",
            "Выбор наиболее удобного отображения графика записей (расписание, неделя, 3 дня)",
        ],
        details: "",
    },
    discountsCard: {
        header: "Скидки",
        bullets: [
            "Создавайте свои собственные гибкие скидочные кампании",
            "Управляйте промокодами и ссылками на бронирование со скидкой",
            "Определяйте диапазоны дат действия Ваших скидок",
            "Позволяйте клиентам выбирать из списка скидок, которые Вы определяете",
            "Создавайте автоматические скидки только для новых клиентов",
            "Приостанавливайте свои скидочные кампании при необходимости",
        ],
    },
    statisticsCard: {
        header: "Статистика",
        bullets: [
            "Количество уникальных просмотров и записей",
            "Оптимизация загруженности дней недели",
            "Детальная статистика по группам доходов и расходов (день, неделя, месяц)",
            "Автоматический подсчет планируемых и реальных доходов и расходов",
            "Удобные графики данных",
            "Данные о каждом клиенте (количество фактических и планируемых записей, доход по клиенту, коэффициент возврата клиентов, коэфициент неприхода клиентов, чаевые)",
        ],
        details: "",
    },
    weWorkForYouCard: {
        header: "Мы работаем для Вас",
        bullets: [
            "Полностью бесплатное приложение",
            "Все данные хранятся в защищенном виде на серверах Amazon",
            "Возможность экспортировать все Ваши данные в удобном табличном формате, поддерживаемым всеми табличными процессорами",
            "Передовые протоколы защиты передачи данных, интеграция с Google",
            "Полная анонимность данных. Мы не разглашаем информацию о клиентах третьим лицам",
            "Возможность удалить Ваши данные с наших серверов",
            "Удобная форма обратной связи",
            "Запросите новый функционал или локализацию и мы добавим ее в следующий релиз",
            "Увидели ошибку? Пожалуйста, сообщите нам и мы исправим ее в кратчайшие сроки",
        ],
        details: "",
    },
};
const uk = {
    promoCaption: "Безкоштовний додаток для онлайн-запису клієнтів",
    mainPage: "Головна",
    contactsPage: "Контакти",
    privacyPolicyPage: "Політика конфіденційності",
    termsConditionsPage: "Умови використання",
    downloadFree: "Завантажити Безкоштовно",
    downloadVisitFree: "Завантажити Visit безкоштовно",
    copyLink: "Копіювати посилання для завантаження",
    agreementP1: "Інсталюючи додаток Ви погоджуєтесь з ",
    agreementP2: "Політикою конфіденційності",
    agreementP3: "Умовами використання",
    details: "Деталі",
    contacts: "Контакти:",
    mail: "Пошта: ",
    navigate: "Перейти",
    onlineBookingCard: {
        header: "Онлайн запис клієнтів",
        bullets: [
            "Персональне посилання для розміщення онлайн-бронювань",
            "Безкоштовний веб-інтерфейс для Ваших клієнтів",
            "Доступ до онлайн-бронювання без реєстрації для Ваших клієнтів",
            "Можливість відміни/перенесення запису для Ваших клієнтів",
            "Зручні сповіщення про бронювання, скасування та перенесення броні",
            "Повний контроль над налаштуваннями бронювання (час, кількість записів, крок броні, контроль клієнтів, мова інтерфейсу, формат часу, контроль можливості відміняти та переносити записи клієнтами)",
        ],
        details: "",
    },
    bussinessAutomationCard: {
        header: "Автоматизація бізнес-процесів",
        bullets: [
            "Запис клієнтів у кілька кліків",
            "База даних та управління клієнтами",
            "Автоматичні нагадування про записи",
            "Автоматичне дублювання записів",
            "Управління робочим графіком, витратами та послугами",
            "Необмежена кількість шаблонів повідомлень клієнту",
            "Автоматизація відправлення повідомлень клієнту по SMS, месенджери (Telegram, WhatsApp, Viber) та соц. мережі (Instagram)",
            "Можливість планувати відпустку та перерви від кількох годин до кількох місяців",
            "Вибір найбільш зручного відображення графіку записів (розклад, тиждень, 3 дні)",
        ],
        details: "",
    },
    discountsCard: {
        header: "Знижки",
        bullets: [
            "Створюйте свої власні гнучкі кампанії знижок",
            "Керуйте промокодами та посиланнями на бронювання зі знижкою",
            "Визначайте діапазони дат дії Ваших знижок",
            "Дозволяйте клієнтам вибирати зі списку знижок, які Ви визначаєте",
            "Створюйте автоматичні знижки лише для нових клієнтів",
            "Призупиняйте свої кампанії знижок при потребі",
        ],
    },
    statisticsCard: {
        header: "Статистика",
        bullets: [
            "Кількість унікальних переглядів та записів",
            "Оптимізація завантаженості днів тижня",
            "Детальна статистика за групами доходів та витрат (день, тиждень, місяць)",
            "Автоматичний підрахунок запланованих та реальних доходів та витрат",
            "Зручні графіки даних",
            "Дані про кожного клієнта (кількість фактичних та планованих записів, дохід по клієнту, коефіцієнт повернення клієнтів, коефіцієнт неприходу клієнтів, чайові)",
        ],
        details: "",
    },
    weWorkForYouCard: {
        header: "Ми працюємо для Вас",
        bullets: [
            "Повністю безкоштовний додаток",
            "Всі дані зберігаються в захищеному вигляді на серверах Amazon",
            "Можливість експорту всіх Ваших даних у зручному табличному форматі, який підтримується всіма табличними процесорами",
            "Передові протоколи захисту передачі даних, інтеграція з Google",
            "Повна анонімність даних. Ми не розголошуємо інформацію про наших клієнтів третім особам",
            "Можливість видалити Ваші дані з наших серверів",
            "Зручна форма зворотного зв'язку",
            "Запросіть новий функціонал або локалізацію і ми додамо її до наступного релізу",
            "Побачили помилку? Будь ласка, повідомте нам і ми виправимо її в найкоротший термін",
        ],
        details: "",
    },
};

const PromoTexts = { en, ru, uk };

export default PromoTexts;
