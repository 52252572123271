import i18n from "i18n-js";
import React from "react";
import {
    makeStyles,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cssLabel: {
        color: theme.palette.secondary.main,
    },

    cssOutlinedInput: {
        borderRadius: 20,
        "&$cssFocused $notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
        },
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: "1px",
        borderColor: `${theme.palette.secondary.main} !important`,
    },
    selectRoot: {
        //...other styles
        "&:focus": {
            backgroundColor: theme.palette.background.default,
        },
    },
    menuItem: {
        borderRadius: 20,
        whiteSpace: 'normal'
    }
}));

export interface DropDownItem {
    id: number;
    name: string;
}

export interface DropDownProps {
    errorText: string;
    label: string;
    value: number;
    onChange: any;
    dropDownItems: DropDownItem[];
}

export default function DropDown({
    errorText,
    label,
    value,
    onChange,
    dropDownItems,
    ...props
}) {
    const inputLabel = React.useRef<any>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel.current) setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const classes = useStyles();
    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel
                ref={inputLabel}
                classes={{
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                }}
                error={errorText !== ""}
            >
                {label}
            </InputLabel>
            <Select
                value={value}
                label={label}
                onChange={onChange}
                input={
                    <OutlinedInput
                        labelWidth={labelWidth}
                        classes={{
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        }}
                    />
                }
                classes={{ root: classes.selectRoot }}
            >
                {props.includeNone && (
                    <MenuItem className={classes.menuItem} value={-1}>
                        {i18n.t("none")}
                    </MenuItem>
                )}
                {dropDownItems.map((item) => (
                    <MenuItem
                        key={item.id}
                        className={classes.menuItem}
                        value={item.id}
                    >
                        {props.itemNameDisplayFunc
                            ? props.itemNameDisplayFunc(item)
                            : item.name}
                    </MenuItem>
                ))}
            </Select>
            {errorText !== "" && (
                <FormHelperText error>{errorText}</FormHelperText>
            )}
        </FormControl>
    );
}
