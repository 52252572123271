import i18n from "i18n-js";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { DiscountCampaignItem, Utils } from "visit-shared";
import { slideInUp, slideOutDown } from "react-animations";
import { StyleSheet, css } from "aphrodite";
import { Dimensions } from "../style/theme";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutlineRounded";
import CheckIcon from "@material-ui/icons/CheckRounded";

const animationStyles = StyleSheet.create({
    slideInUp: {
        animationName: slideInUp,
        animationDuration: "0.5s",
    },
    slideOutDown: {
        animationName: slideOutDown,
        animationDuration: "0.5s",
    },
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
        backgroundColor: theme.palette.secondary.main,
        height: 56,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
    title: {
        marginLeft: 32,
        textAlign: "center",
        flex: 1,
    },
    background: {
        backgroundColor: theme.palette.background.default,
        height: "100%",
    },
    list: {
        margin: 8,
    },
    card: {
        textAlign: "center",
        margin: 20,
        borderRadius: 20,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.common.white,
    },
    infoText: {
        textAlign: "left",
        color: theme.palette.text.primary,
    },
    newUsersOnlyText: {
        marginTop: 5,
        marginRight: 5,
        marginBottom: -15,
        color: theme.palette.error.main,
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "row",
    },
    newUsersOnlyIcon: {
        marginTop: 3,
        marginLeft: 5,
    },
    applyButton: {
        borderRadius: 20,
        marginLeft: 10,
        marginRight: 10,
    },
    appliedButton: {
        borderWidth: "2px",
        borderRadius: 20,
        marginLeft: 10,
        marginRight: 10,
    },
    appliedIcon: {
        marginTop: -3,
    },
}));

interface DiscountsInfoDialogProps {
    dimensions: Dimensions;
    isOpen: boolean;
    handleClose: any;
    today: Date;

    discountCampaignItems: DiscountCampaignItem[];
    updateSelectedDiscountCampaign: any;
    selectedDiscountCampaignItem: DiscountCampaignItem | null;
}

export default function DiscountsInfoDialog({
    dimensions,
    isOpen,
    handleClose,
    today,

    discountCampaignItems,
    updateSelectedDiscountCampaign,
    selectedDiscountCampaignItem,
}: DiscountsInfoDialogProps) {
    const classes = useStyles();
    const [currentAnimation, setCurrentAnimation] = React.useState(
        animationStyles.slideInUp
    );
    function dateRangeString(item: DiscountCampaignItem) {
        const startDate = new Date(item.startDate);
        let dateRangeString = "";

        if (startDate > today) {
            dateRangeString += ` ${i18n.t(
                "durationFromLowerCase"
            )} ${Utils.GetDateMonthString(startDate)}`;
        }
        if (item.endDate !== null) {
            dateRangeString += ` ${i18n.t(
                "durationTo"
            )} ${Utils.GetDateMonthString(item.endDate)}`;
        }
        return dateRangeString;
    }

    return (
        <div>
            {/* TODO: Fix Warning: findDOMNode is deprecated in StrictMode. */}
            <Modal open={isOpen} className={css(currentAnimation)}>
                <div className={classes.background}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                {i18n.t("discounts")}
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    setCurrentAnimation(
                                        animationStyles.slideOutDown
                                    );
                                    setTimeout(() => {
                                        handleClose();
                                        setCurrentAnimation(
                                            animationStyles.slideInUp
                                        );
                                    }, 500);
                                }}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {/* Height adjustment is calculated based on app bar height */}
                    <div
                        style={{
                            height: dimensions.height - 56,
                            overflow: "auto",
                        }}
                    >
                        {discountCampaignItems.map((item) => (
                            <Card key={item.id} className={classes.card}>
                                {item.newUsersOnly && (
                                    <div className={classes.newUsersOnlyText}>
                                        <Typography variant="h6">
                                            {i18n.t("newUsersOnly")}
                                        </Typography>
                                        <ErrorOutlineIcon
                                            className={classes.newUsersOnlyIcon}
                                            color="inherit"
                                        />
                                    </div>
                                )}
                                <CardHeader
                                    titleTypographyProps={{
                                        style: { fontWeight: "bold" },
                                    }}
                                    subheaderTypographyProps={{
                                        fontWeight: "bold",
                                    }}
                                    disableTypography={false}
                                    title={item.name}
                                    subheader={dateRangeString(item)}
                                />
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        className={classes.infoText}
                                    >
                                        {item.message}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    {selectedDiscountCampaignItem !== null &&
                                    item.id ===
                                        selectedDiscountCampaignItem!.id ? (
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth
                                            className={classes.appliedButton}
                                            endIcon={
                                                <CheckIcon
                                                    className={
                                                        classes.appliedIcon
                                                    }
                                                />
                                            }
                                        >
                                            {i18n.t("applied")}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.applyButton}
                                            onClick={() =>
                                                updateSelectedDiscountCampaign(
                                                    item
                                                )
                                            }
                                        >
                                            {i18n.t("apply")}
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        ))}
                    </div>
                </div>
            </Modal>
        </div>
    );
}
