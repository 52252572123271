import i18n from "i18n-js";
import {
    Localization,
    Utils,
    TimeFormat,
    TimeFormatOptions,
} from "visit-shared";

// i18n-js Version should be consistent across all packages (mobile, web, visit-shared).
// Visit shared should not have own node-modules inside mobile or web

i18n.translations = {
    en: Localization.en,
    ru: Localization.ru,
    uk: Localization.uk,
};

i18n.locale = navigator.language;
i18n.fallbacks = true;

export let LocaleConfig: any = {};
LocaleConfig.locales = [];

LocaleConfig.locales["en"] = Localization.en.agendaConfig;

LocaleConfig.locales["ru"] = Localization.ru.agendaConfig;

LocaleConfig.locales["uk"] = Localization.uk.agendaConfig;

LocaleConfig.defaultLocale = Utils.GetCurrentTranslation();

export function ResetLocalization(newLocale: string) {
    i18n.locale = newLocale;
    LocaleConfig.defaultLocale = Utils.GetCurrentTranslation();
}

export function ResetTimeFormat(newTimeFormat: TimeFormatOptions) {
    TimeFormat.SetTimeFormat(newTimeFormat);
}
