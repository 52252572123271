import { createTheme } from "@material-ui/core";
import { Theme } from "visit-shared";

declare module "@material-ui/core/styles" {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

export interface Dimensions {
    height: number;
    width: number;
}

export const theme = createTheme({
    palette: {
        primary: {
            main: Theme.colors.primary,
        },
        secondary: {
            main: Theme.colors.secondary,
        },
        text: {
            primary: Theme.colors.text,
        },
        background: {
            default: Theme.colors.background,
            paper: Theme.colors.surface,
        },
        error: {
            main: Theme.colors.error,
        },
    },
    typography: {
        fontFamily: "Inter",
    },
});

export const userUITheme = createTheme({
    palette: {
        primary: {
            main: Theme.colors.primary,
        },
        secondary: {
            main: Theme.colors.secondary,
        },
        text: {
            primary: Theme.colors.text,
        },
        background: {
            default: Theme.colors.background,
            paper: Theme.colors.surface,
        },
        error: {
            main: Theme.colors.error,
        },
    },
    typography: {
        fontFamily: "Rubik",
    },
});
