// @ts-nocheck
import * as React from "react";
import { isMobile } from "react-device-detect";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import differenceInMinutes from "date-fns/differenceInMinutes";
import format from "date-fns/format";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import range from "lodash/range";
import round from "lodash/round";
import upperCase from "lodash/upperCase";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Dimensions, userUITheme } from "../style/theme";
import { Utils } from "visit-shared";

export const DEFAULT_HOURS_INTERVAL = { from: 7, to: 24 };

export type ClassNames = {
    event: string;
};

export enum EventType {
    eBusy = 0,
    eBreak = 1,
    eFree = 2,
}

export interface Event {
    id: number | string;
    name: string;
    startTime: Date;
    endTime: Date;
    type: EventType;
    [key: string]: unknown;
}

export interface Events {
    [day: string]: Event[];
}

export interface HourPreviewProps {
    hour: string;
    defaultAttributes: React.HTMLAttributes<HTMLDivElement>;
}

export interface EventPreviewProps {
    event: Event;
    defaultAttributes: React.HTMLAttributes<HTMLDivElement>;
}

export interface EventsListProps {
    day: string;
    events: Events;
    renderEvent: React.FC<EventPreviewProps>;
    hoursInterval: typeof DEFAULT_HOURS_INTERVAL;
    rowHeight: number;
}

export interface DayColumnPreviewProps {
    dimensions: any;
    events: Events;
    day: string;
    index: number;
    rowHeight: number;
    getDayLabel: (day: string) => string;
    renderEvent: React.FC<EventPreviewProps>;
    hoursInterval: typeof DEFAULT_HOURS_INTERVAL;
}

export interface HoursListProps {
    hoursInterval: typeof DEFAULT_HOURS_INTERVAL;
    rowHeight: number;
    renderHour: React.FC<HourPreviewProps>;
}

export interface TimeTableProps {
    dimensions: Dimensions;
    events: Events;
    hoursInterval?: typeof DEFAULT_HOURS_INTERVAL;
    cornerButtonCallback?: any;
    getDayLabel?: (day: string) => string;
    renderEvent?: React.FC<EventPreviewProps>;
    renderHour?: React.FC<HourPreviewProps>;
}

const useStyles = makeStyles((theme) => ({
    event: {
        borderWidth: "0px 1px 0px 1px",
        borderStyle: "solid",
        borderColor: "#e4d2e4", // UNIQUE COLOR DEFINED ONLY HERE
        position: "absolute",
        width: "100%",
        height: "15vh",
        lineHeight: "15vh",
        fontSize: "0.7em",
        fontWeight: "300",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        textAlign: "center",
        backgroundClip: "border-box",
        mozBoxSizing: "border-box",
        webkitBoxSizing: "border-box",
        boxSizing: "border-box",
    },
    eventBusy: {
        backgroundColor: "darkred",
        opacity: 0,
    },
    eventBreak: {
        backgroundColor: "gray",
        opacity: 0,
    },
    eventFree: {
        borderRadius: "5px",
        borderWidth: "1px 1px 1px 1px",
        fontWeight: "500",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.1)",
    },
    eventFontMobile: {
        width: "97.5%",
        fontSize: "0.8em",
    },
    eventFontDesktop: {
        width: "99.5%",
        fontSize: "0.9em",
    },
}));

const getRowHeight = (from: number, to: number, totalHeight: number) => {
    const numberOfRows = to - from + 1;

    return round(totalHeight / numberOfRows, 5);
};

export const getDefaultDayLabel = (day: string) => upperCase(day);

const getEventPositionStyles = ({
    event,
    hoursInterval,
    rowHeight,
}: {
    event: Event;
    hoursInterval: typeof DEFAULT_HOURS_INTERVAL;
    rowHeight: number;
}) => {
    let startOfDay = setMinutes(
        setHours(event.startTime, hoursInterval.from),
        0
    );

    let minutesFromStartOfDay = round(
        differenceInMinutes(event.startTime, startOfDay)
    );

    let minutes = round(differenceInMinutes(event.endTime, event.startTime));
    return {
        height: (minutes * rowHeight) / 60 + "px",
        marginTop: (minutesFromStartOfDay * rowHeight) / 60 + "px",
    };
};

export const HourPreview: React.FC<HourPreviewProps> = ({
    hour,
    defaultAttributes,
}) => (
    <div {...defaultAttributes} key={hour}>
        {hour}
    </div>
);

export const EventPreview: React.FC<EventPreviewProps> = ({
    event,
    defaultAttributes,
}) => {
    return (
        <div {...defaultAttributes} title={event.name} key={event.id}>
            <Box
                component="span"
                sx={{ lineHeight: "initial", textAlign: "center" }}
            >
                {event.name}
            </Box>
            <Box
                component="span"
                sx={{ lineHeight: "initial", textAlign: "center" }}
            >
                {format(event.startTime, "HH:mm")} -{" "}
                {format(event.endTime, "HH:mm")}
            </Box>
        </div>
    );
};

export const EventsList = ({
    events,
    day,
    hoursInterval,
    rowHeight,
    renderEvent,
}: EventsListProps) => {
    const classNames = useStyles();
    return (events[day] || []).map((event) => {
        let typeSpecificClassName;
        switch (event.type) {
            case EventType.eBusy:
                typeSpecificClassName = classNames.eventBusy;
                break;
            case EventType.eBreak:
                typeSpecificClassName = classNames.eventBreak;
                break;
            case EventType.eFree:
                typeSpecificClassName = classNames.eventFree;
                typeSpecificClassName += isMobile
                    ? " " + classNames.eventFontMobile
                    : " " + classNames.eventFontDesktop;
                break;
        }

        return renderEvent({
            event,
            defaultAttributes: {
                className: `${classNames.event} ${typeSpecificClassName}`,
                style: getEventPositionStyles({
                    event,
                    hoursInterval,
                    rowHeight,
                }),
            },
        });
    });
};

const DayColumnPreview = ({
    dimensions,
    events,
    day,
    index,
    rowHeight,
    getDayLabel,
    renderEvent,
    hoursInterval,
}: DayColumnPreviewProps) => {
    const dayLabel = getDayLabel(day).split(" ");
    return (
        <div
            className={day}
            style={{
                position: "relative",
                height: dimensions.height,
                float: "left",
                backgroundColor: "#fff",
                backgroundImage: `linear-gradient(${userUITheme.palette.background.default} 50%, ${userUITheme.palette.background.paper} 50%)`,
                backgroundSize: `1px ${2 * rowHeight}px`,
                width: `calc((100% - 5rem) / ${Object.keys(events).length})`,
            }}
            key={`${day}-${index}`}
        >
            <Box
                component="div"
                sx={{
                    backgroundColor: userUITheme.palette.secondary.main,
                    color: userUITheme.palette.common.white,
                    fontSize: "12px",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    zIndex: 2,
                    height: `${rowHeight}px`,
                }}
            >
                <span>{dayLabel[0]}</span>
                <span>{dayLabel[1]}</span>
            </Box>
            {EventsList({
                events,
                day,
                renderEvent,
                hoursInterval,
                rowHeight,
            })}
        </div>
    );
};

export const HoursList = ({
    hoursInterval,
    rowHeight,
    renderHour,
}: HoursListProps) => {
    return range(hoursInterval.from, hoursInterval.to).map((hour) =>
        renderHour({
            hour: `${Utils.HoursMinutesToCurrentFormat(hour, 0)}`,
            defaultAttributes: {
                style: {
                    backgroundColor: userUITheme.palette.secondary.main,
                    color: userUITheme.palette.common.white,
                    fontSize: "12px",
                    textAlign: "center",
                    width: "5rem",
                    fontWeight: "bold",
                    height: `${rowHeight}px`,
                },
            },
        })
    );
};

export const TimeTable = ({
    dimensions,
    events,
    hoursInterval = DEFAULT_HOURS_INTERVAL,
    cornerButtonCallback = () => {},
    getDayLabel = getDefaultDayLabel,
    renderEvent = EventPreview,
    renderHour = HourPreview,
}: TimeTableProps) => {
    const [rowHeight, setRowHeight] = React.useState<number>(0);
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (ref.current) {
            const totalHeight = (ref.current as HTMLDivElement).clientHeight;
            setRowHeight(
                getRowHeight(hoursInterval.from, hoursInterval.to, totalHeight)
            );
        }
    }, [hoursInterval, dimensions]);

    return (
        <Box
            component="div"
            sx={{
                height: dimensions.height,
                margin: 0,
                color: "#efefef",
                overflow: "hidden",
                fontFamily: userUITheme.typography.fontFamily,
            }}
            ref={ref}
        >
            <Box
                component="div"
                sx={{
                    position: "relative",
                    height: dimensions.height,
                    float: "left",
                    backgroundColor: "#fff",
                    backgroundImage: `linear-gradient(${userUITheme.palette.background.default} 50%, ${userUITheme.palette.background.paper} 50%)`,
                }}
            >
                <Box
                    component="div"
                    sx={{
                        backgroundColor: userUITheme.palette.secondary.main,
                        color: userUITheme.palette.common.white,
                        fontSize: "12px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        zIndex: 2,
                        height: `${rowHeight}px`,
                    }}
                >
                    <Button
                        color="inherit"
                        aria-label="back to menu"
                        onClick={cornerButtonCallback}
                    >
                        <ArrowBackIcon />
                    </Button>
                </Box>
                {HoursList({ hoursInterval, renderHour, rowHeight })}
            </Box>

            {Object.keys(events).map((day, index) =>
                DayColumnPreview({
                    dimensions,
                    events,
                    day,
                    index,
                    rowHeight,
                    getDayLabel,
                    renderEvent,
                    hoursInterval,
                })
            )}
        </Box>
    );
};

TimeTable.defaultProps = {
    hoursInterval: "",
    timeLabel: "Time",
    renderHour: HourPreview,
    renderEvent: EventPreview,
    getDayLabel: getDefaultDayLabel,
};

export default TimeTable;
