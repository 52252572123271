import i18n from 'i18n-js';
import axios from 'axios';
import Logger from './logger';

export interface NetworkResponseInterface {
    data: any | null;
    status: number;
}

export default class Network {
    static host: string = 'api';
    static timeout: number = 5000;

    public static async Get(
        request: string,
        params: any = null
    ): Promise<NetworkResponseInterface> {
        try {
            const result = await axios.get(request, {
                params: params,
                baseURL: Network.host,
                timeout: Network.timeout
            });
            return { data: result.data, status: result.status };
        } catch (err: any) {
            Logger.Log(`request ${request} failed with ${err}`);
            if (err.response)
                return { data: err.response.data, status: err.response.status };
            else {
                alert(i18n.t('noConnectionToServer'));
                return { data: null, status: 504 };
            }
        }
    }

    public static async Post(
        request: string,
        data: any
    ): Promise<NetworkResponseInterface> {
        try {
            const result = await axios.post(request, data, {
                baseURL: Network.host,
                timeout: Network.timeout
            });
            return { data: result.data, status: result.status };
        } catch (err: any) {
            Logger.Log(`request ${request} failed with ${err}`);
            if (err.response)
                return { data: err.response.data, status: err.response.status };
            else {
                alert(i18n.t('noConnectionToServer'));
                return { data: null, status: 504 };
            }
        }
    }
}
