import {
    AppBar,
    Box,
    makeStyles,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import React from "react";
import { Emails } from "visit-shared";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const termsEn = () => {
    return (
        <div>
            <h1>Terms and Conditions</h1> <p>Updated at 2021-12-08</p>
            <br /> <h1>General Terms</h1>
            <p>
                <span>
                    By accessing and placing an order with{" "}
                    <a href="https://visitappt.com/terms">Visit</a>, you confirm
                    that you are in agreement with and bound by the terms of
                    service contained in the Terms and Conditions outlined
                    below. These terms apply to the entire website and any email
                    or other type of communication between you and{" "}
                    <a href="https://visitappt.com/terms">Visit</a>.
                    <br />
                    <br />
                    Under no circumstances shall{" "}
                    <a href="https://visitappt.com/terms">Visit</a> team be
                    liable for any direct, indirect, special, incidental or
                    consequential damages, including, but not limited to, loss
                    of data or profit, arising out of the use, or the inability
                    to use, the materials on this site, even if
                    <a href="https://visitappt.com/terms">Visit</a> team or an
                    authorized representative has been advised of the
                    possibility of such damages. If your use of materials from
                    this site results in the need for servicing, repair or
                    correction of equipment or data, you assume any costs
                    thereof.
                    <br />
                    <br /> <a href="https://visitappt.com/terms">Visit</a> will
                    not be responsible for any outcome that may occur during the
                    course of usage of our resources. We reserve the rights to
                    change prices and revise the resources usage policy in any
                    moment.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>License</h1>
            <p>
                <span>
                    <a href="https://visitappt.com/terms">Visit</a> grants you a
                    revocable, non-exclusive, non- transferable, limited license
                    to download, install and use our service strictly in
                    accordance with the terms of this Agreement.
                    <br />
                    <br />
                    These Terms and Conditions are a contract between you and{" "}
                    <a href="https://visitappt.com/terms">Visit</a> (referred to
                    in these Terms and Conditions as "
                    <a href="https://visitappt.com/terms">Visit</a>", "us", "we"
                    or "our"), the provider of the{" "}
                    <a href="https://visitappt.com/terms">Visit</a> website and
                    the services accessible from the{" "}
                    <a href="https://visitappt.com/terms">Visit</a> website
                    (which are collectively referred to in these Terms and
                    Conditions as the "
                    <a href="https://visitappt.com/terms">Visit</a> Service").
                    <br />
                    <br />
                    You are agreeing to be bound by these Terms and Conditions.
                    If you do not agree to these Terms and Conditions, please do
                    not use the Service. In these Terms and Conditions, "you"
                    refers both to you as an individual and to the entity you
                    represent. If you violate any of these Terms and Conditions,
                    we reserve the right to cancel your account or block access
                    to your account without notice.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Definitions and key terms</h1>
            <p>
                <span>For this Terms and Conditions:</span>
            </p>
            <p>
                <br />
            </p>
            <ul>
                <li>
                    <span>
                        <strong>Cookie:</strong> small amount of data generated
                        by a website and saved by your web browser. It is used
                        to identify your browser, provide analytics, remember
                        information about you such as your language preference
                        or login information.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Company:</strong> when this policy mentions
                        “Company,” “we,” “us,” or “our,” it refers to
                        <a href="https://visitappt.com/terms">Visit</a> that is
                        responsible for your information under this Privacy
                        Policy.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Customer:</strong> refers to the company,
                        organization or person that signs up to use the
                        <a href="https://visitappt.com/terms">Visit</a> Service
                        to manage the relationships with your consumers or
                        service users.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Device:</strong> any internet connected device
                        such as a phone, tablet, computer or any other device
                        that can be used to visit{" "}
                        <a href="https://visitappt.com/terms">Visit</a>
                        and use the services.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>IP address:</strong> Every device connected to
                        the Internet is assigned a number known as an Internet
                        protocol (IP) address. These numbers are usually
                        assigned in geographic blocks. An IP address can often
                        be used to identify the location from which a device is
                        connecting to the Internet.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Personnel:</strong> refers to those individuals
                        who are employed by{" "}
                        <a href="https://visitappt.com/terms">Visit</a> or are
                        under contract to perform a service on behalf of one of
                        the parties.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Personal Data:</strong> any information that
                        directly, indirectly, or in connection with other
                        information — including a personal identification number
                        — allows for the identification or identifiability of a
                        natural person.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Service:</strong> refers to the service provided
                        by <a href="https://visitappt.com/terms">Visit</a> as
                        described in the relative terms (if available) and on
                        this platform.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Third-party service:</strong> refers to
                        advertisers, contest sponsors, promotional and marketing
                        partners, and others who provide our content or whose
                        products or services we think may interest you.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Website:</strong>{" "}
                        <a href="https://visitappt.com/terms">Visit</a>’s site,
                        which can be accessed via this URL:{" "}
                        <a href="https://visitappt.com/terms">
                            https://visitappt.com
                        </a>
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>You:</strong> a person or entity that is
                        registered with{" "}
                        <a href="https://visitappt.com/terms">Visit</a> to use
                        the Services.
                        <br />
                    </span>
                </li>
            </ul>
            <p>
                <br />
            </p>
            <h1>Restrictions</h1>
            <p>
                <span>
                    You agree not to, and you will not permit others to:
                </span>
            </p>
            <ul>
                <li>
                    <span>
                        License, sell, rent, lease, assign, distribute,
                        transmit, host, outsource, disclose or otherwise
                        commercially exploit the service or make the platform
                        available to any third party.
                    </span>
                </li>
                <li>
                    <span>
                        Modify, make derivative works of, disassemble, decrypt,
                        reverse compile or reverse engineer any part of the
                        service.
                    </span>
                </li>
                <li>
                    <span>
                        Remove, alter or obscure any proprietary notice
                        (including any notice of copyright or trademark) of or
                        its affiliates, partners, suppliers or the licensors of
                        the service.
                    </span>
                </li>
            </ul>
            <p>
                <br />
            </p>
            <h1>Payment</h1>
            <p>
                <span>
                    If you register to any of our recurring payment plans, you
                    agree to pay all fees or charges to your account for the
                    Service in accordance with the fees, charges and billing
                    terms in effect at the time that each fee or charge is due
                    and payable. Unless otherwise indicated in an order form,
                    you must provide us with a valid credit card (Visa,
                    MasterCard, or any other issuer accepted by us) (“Payment
                    Provider”) as a condition to signing up for the Premium
                    plan. Your Payment Provider agreement governs your use of
                    the designated credit card account, and you must refer to
                    that agreement and not these Terms to determine your rights
                    and liabilities with respect to your Payment Provider. By
                    providing us with your credit card number and associated
                    payment information, you agree that we are authorized to
                    verify information immediately, and subsequently invoice
                    your account for all fees and charges due and payable to us
                    hereunder and that no additional notice or consent is
                    required. You agree to immediately notify us of any change
                    in your billing address or the credit card used for payment
                    hereunder. We reserve the right at any time to change its
                    prices and billing methods, either immediately upon posting
                    on our Site or by e-mail delivery to your organization’s
                    administrator(s). Any attorney fees, court costs, or other
                    costs incurred in collection of delinquent undisputed
                    amounts shall be the responsibility of and paid for by you.
                    No contract will exist between you and us for the Service
                    until we accept your order by a confirmatory e-mail, SMS/MMS
                    message, or other appropriate means of communication. You
                    are responsible for any third-party fees that you may incur
                    when using the Service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Return and Refund Policy</h1>
            <p>
                <span>
                    Thanks for shopping with us. We appreciate the fact that you
                    like to buy the stuff we build. We also want to make sure
                    you have a rewarding experience while you’re exploring,
                    evaluating, and purchasing our products.
                    <br />
                    As with any shopping experience, there are terms and
                    conditions that apply to transactions at our company. We’ll
                    be as brief as our attorneys will allow. The main thing to
                    remember is that by placing an order or making a purchase
                    from us, you agree to the terms along with our Privacy
                    Policy.
                    <br />
                    If, for any reason, You are not completely satisfied with
                    any good or service that we provide, don't hesitate to
                    contact us and we will discuss any of the issues you are
                    going through with our product.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Your Suggestions</h1>
            <p>
                <span>
                    Any feedback, comments, ideas, improvements or suggestions
                    (collectively, "Suggestions") provided by you to us with
                    respect to the service shall remain the sole and exclusive
                    property of us. We shall be free to use, copy, modify,
                    publish, or redistribute the Suggestions for any purpose and
                    in any way without any credit or any compensation to you.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Your Consent</h1>
            <p>
                <span>
                    We've updated our Terms and Conditions to provide you with
                    complete transparency into what is being set when you visit
                    our site and how it's being used. By using our service,
                    registering an account, or making a purchase, you hereby
                    consent to our Terms and Conditions.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Links to Other Websites</h1>
            <p>
                <span>
                    Our service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party's site. We strongly advise
                    You to review the Terms and Conditions of every site You
                    visit. We have no control over and assume no responsibility
                    for the content, Terms and Conditions or practices of any
                    third party sites or services.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Cookies</h1>
            <p>
                <span>
                    We use "Cookies" to identify the areas of our website that
                    you have visited. A Cookie is a small piece of data stored
                    on your computer or mobile device by your web browser. We
                    use Cookies to enhance the performance and functionality of
                    our service but are non-essential to their use. However,
                    without these cookies, certain functionality like videos may
                    become unavailable or you would be required to enter your
                    login details every time you visit our platform as we would
                    not be able to remember that you had logged in previously.
                    Most web browsers can be set to disable the use of Cookies.
                    However, if you disable Cookies, you may not be able to
                    access functionality on our website correctly or at all. We
                    never place Personally Identifiable Information in Cookies.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Changes To Our Terms and Conditions</h1>
            <p>
                <span>
                    You acknowledge and agree that we may stop (permanently or
                    temporarily) providing the Service (or any features within
                    the Service) to you or to users generally at our sole
                    discretion, without prior notice to you. You may stop using
                    the Service at any time. You do not need to specifically
                    inform us when you stop using the Service. You acknowledge
                    and agree that if we disable access to your account, you may
                    be prevented from accessing the Service, your account
                    details or any files or other materials which is contained
                    in your account. If we decide to change our Terms and
                    Conditions, we will post those changes on this page, and/or
                    update the Terms and Conditions modification date below.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Modifications to Our service</h1>
            <p>
                <span>
                    We reserve the right to modify, suspend or discontinue,
                    temporarily or permanently, the service or any service to
                    which it connects, with or without notice and without
                    liability to you.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Updates to Our service</h1>
            <p>
                <span>
                    We may from time to time provide enhancements or
                    improvements to the features/ functionality of the service,
                    which may include patches, bug fixes, updates, upgrades and
                    other modifications ("Updates"). Updates may modify or
                    delete certain features and/or functionalities of the
                    service. You agree that we have no obligation to (i) provide
                    any Updates, or (ii) continue to provide or enable any
                    particular features and/or functionalities of the service to
                    you. You further agree that all Updates will be (i) deemed
                    to constitute an integral part of the service, and (ii)
                    subject to the terms and conditions of this Agreement.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Third-Party Services</h1>
            <p>
                <span>
                    We may display, include or make available third-party
                    content (including data, information, applications and other
                    products services) or provide links to third-party websites
                    or services ("Third- Party Services"). You acknowledge and
                    agree that we shall not be responsible for any Third-Party
                    Services, including their accuracy, completeness,
                    timeliness, validity, copyright compliance, legality,
                    decency, quality or any other aspect thereof. We do not
                    assume and shall not have any liability or responsibility to
                    you or any other person or entity for any Third-Party
                    Services. Third-Party Services and links thereto are
                    provided solely as a convenience to you and you access and
                    use them entirely at your own risk and subject to such third
                    parties' terms and conditions.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Term and Termination</h1>
            <p>
                <span>
                    This Agreement shall remain in effect until terminated by
                    you or us. We may, in its sole discretion, at any time and
                    for any or no reason, suspend or terminate this Agreement
                    with or without prior notice. This Agreement will terminate
                    immediately, without prior notice from us, in the event that
                    you fail to comply with any provision of this Agreement. You
                    may also terminate this Agreement by deleting the service
                    and all copies thereof from your computer. Upon termination
                    of this Agreement, you shall cease all use of the service
                    and delete all copies of the service from your computer.
                    Termination of this Agreement will not limit any of our
                    rights or remedies at law or in equity in case of breach by
                    you (during the term of this Agreement) of any of your
                    obligations under the present Agreement.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Term and Termination</h1>
            <p>
                <span>
                    If you are a copyright owner or such owner’s agent and
                    believe any material from us constitutes an infringement on
                    your copyright, please contact us setting forth the
                    following information: (a) a physical or electronic
                    signature of the copyright owner or a person authorized to
                    act on his behalf; (b) identification of the material that
                    is claimed to be infringing; (c) your contact information,
                    including your address, telephone number, and an email; (d)
                    a statement by you that you have a good faith belief that
                    use of the material is not authorized by the copyright
                    owners; and (e) the a statement that the information in the
                    notification is accurate, and, under penalty of perjury you
                    are authorized to act on behalf of the owner.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Indemnification</h1>
            <p>
                <span>
                    You agree to indemnify and hold us and our parents,
                    subsidiaries, affiliates, officers, employees, agents,
                    partners and licensors (if any) harmless from any claim or
                    demand, including reasonable attorneys' fees, due to or
                    arising out of your: (a) use of the service; (b) violation
                    of this Agreement or any law or regulation; or (c) violation
                    of any right of a third party.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>No Warranties</h1>
            <p>
                <span>
                    The service is provided to you "AS IS" and "AS AVAILABLE"
                    and with all faults and defects without warranty of any
                    kind. To the maximum extent permitted under applicable law,
                    we, on our own behalf and on behalf of our affiliates and
                    our respective licensors and service providers, expressly
                    disclaims all warranties, whether express, implied,
                    statutory or otherwise, with respect to the service,
                    including all implied warranties of merchantability, fitness
                    for a particular purpose, title and non- infringement, and
                    warranties that may arise out of course of dealing, course
                    of performance, usage or trade practice. Without limitation
                    to the foregoing, we provide no warranty or undertaking, and
                    makes no representation of any kind that the service will
                    meet your requirements, achieve any intended results, be
                    compatible or work with any other software, websites,
                    systems or services, operate without interruption, meet any
                    performance or reliability standards or be error free or
                    that any errors or defects can or will be corrected.
                    <br />
                    <br />
                    Without limiting the foregoing, neither us nor any provider
                    makes any representation or warranty of any kind, express or
                    implied: (i) as to the operation or availability of the
                    service, or the information, content, and materials or
                    products included thereon; (ii) that the service will be
                    uninterrupted or error-free; (iii) as to the accuracy,
                    reliability, or currency of any information or content
                    provided through the service; or (iv) that the service, its
                    servers, the content, or e-mails sent from or on behalf of
                    us are free of viruses, scripts, trojan horses, worms,
                    malware, timebombs or other harmful components. Some
                    jurisdictions do not allow the exclusion of or limitations
                    on implied warranties or the limitations on the applicable
                    statutory rights of a consumer, so some or all of the above
                    exclusions and limitations may not apply to you.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Limitation of Liability</h1>
            <p>
                <span>
                    Notwithstanding any damages that you might incur, the entire
                    liability of us and any of our suppliers under any provision
                    of this Agreement and your exclusive remedy for all of the
                    foregoing shall be limited to the amount actually paid by
                    you for the service. To the maximum extent permitted by
                    applicable law, in no event shall we or our suppliers be
                    liable for any special, incidental, indirect, or
                    consequential damages whatsoever (including, but not limited
                    to, damages for loss of profits, for loss of data or other
                    information, for business interruption, for personal injury,
                    for loss of privacy arising out of or in any way related to
                    the use of or inability to use the service, third-party
                    software and/or third-party hardware used with the service,
                    or otherwise in connection with any provision of this
                    Agreement), even if we or any supplier has been advised of
                    the possibility of such damages and even if the remedy fails
                    of its essential purpose. Some states/jurisdictions do not
                    allow the exclusion or limitation of incidental or
                    consequential damages, so the above limitation or exclusion
                    may not apply to you.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Severability</h1>
            <p>
                <span>
                    If any provision of this Agreement is held to be
                    unenforceable or invalid, such provision will be changed and
                    interpreted to accomplish the objectives of such provision
                    to the greatest extent possible under applicable law and the
                    remaining provisions will continue in full force and effect.
                    <br />
                    <br />
                    This Agreement, together with the Privacy Policy and any
                    other legal notices published by us on the Services, shall
                    constitute the entire agreement between you and us
                    concerning the Services. If any provision of this Agreement
                    is deemed invalid by a court of competent jurisdiction, the
                    invalidity of such provision shall not affect the validity
                    of the remaining provisions of this Agreement, which shall
                    remain in full force and effect. No waiver of any term of
                    this Agreement shall be deemed a further or continuing
                    waiver of such term or any other term, and our failure to
                    assert any right or provision under this Agreement shall not
                    constitute a waiver of such right or provision. YOU AND US
                    AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO
                    THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE
                    CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS
                    PERMANENTLY BARRED.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Waiver</h1>
            <p>
                <span>
                    Except as provided herein, the failure to exercise a right
                    or to require performance of an obligation under this
                    Agreement shall not effect a party's ability to exercise
                    such right or require such performance at any time
                    thereafter nor shall be the waiver of a breach constitute
                    waiver of any subsequent breach.
                    <br />
                    <br />
                    No failure to exercise, and no delay in exercising, on the
                    part of either party, any right or any power under this
                    Agreement shall operate as a waiver of that right or power.
                    Nor shall any single or partial exercise of any right or
                    power under this Agreement preclude further exercise of that
                    or any other right granted herein. In the event of a
                    conflict between this Agreement and any applicable purchase
                    or other terms, the terms of this Agreement shall govern.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Amendments to this Agreement</h1>
            <p>
                <span>
                    We reserve the right, at its sole discretion, to modify or
                    replace this Agreement at any time. If a revision is
                    material we will provide at least 30 days' notice prior to
                    any new terms taking effect. What constitutes a material
                    change will be determined at our sole discretion. By
                    continuing to access or use our service after any revisions
                    become effective, you agree to be bound by the revised
                    terms. If you do not agree to the new terms, you are no
                    longer authorized to use our service.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Entire Agreement</h1>
            <p>
                <span>
                    The Agreement constitutes the entire agreement between you
                    and us regarding your use of the service and supersedes all
                    prior and contemporaneous written or oral agreements between
                    you and us. You may be subject to additional terms and
                    conditions that apply when you use or purchase other
                    services from us, which we will provide to you at the time
                    of such use or purchase.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Updates to Our Terms</h1>
            <p>
                <span>
                    We may change our Service and policies, and we may need to
                    make changes to these Terms so that they accurately reflect
                    our Service and policies. Unless otherwise required by law,
                    we will notify you (for example, through our Service) before
                    we make changes to these Terms and give you an opportunity
                    to review them before they go into effect. Then, if you
                    continue to use the Service, you will be bound by the
                    updated Terms. If you do not want to agree to these or any
                    updated Terms, you can delete your account.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Intellectual Property</h1>
            <p>
                <span>
                    Our platform and its entire contents, features and
                    functionality (including but not limited to all information,
                    software, text, displays, images, video and audio, and the
                    design, selection and arrangement thereof), are owned by us,
                    its licensors or other providers of such material and are
                    protected by and international copyright, trademark, patent,
                    trade secret and other intellectual property or proprietary
                    rights laws. The material may not be copied, modified,
                    reproduced, downloaded or distributed in any way, in whole
                    or in part, without the express prior written permission of
                    us, unless and except as is expressly provided in these
                    Terms and Conditions. Any unauthorized use of the material
                    is prohibited.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Agreement to Arbitrate</h1>
            <p>
                <span>
                    This section applies to any dispute EXCEPT IT DOESN’T
                    INCLUDE A DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR
                    EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF
                    YOUR OR Visit’s INTELLECTUAL PROPERTY RIGHTS. The term
                    “dispute” means any dispute, action, or other controversy
                    between you and us concerning the Services or this
                    agreement, whether in contract, warranty, tort, statute,
                    regulation, ordinance, or any other legal or equitable
                    basis. “Dispute” will be given the broadest possible meaning
                    allowable under law.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Notice of Dispute</h1>
            <p>
                <span>
                    In the event of a dispute, you or us must give the other a
                    Notice of Dispute, which is a written statement that sets
                    forth the name, address, and contact information of the
                    party giving it, the facts giving rise to the dispute, and
                    the relief requested. You must send any Notice of Dispute
                    via email to: . We will send any Notice of Dispute to you by
                    mail to your address if we have it, or otherwise to your
                    email address. You and us will attempt to resolve any
                    dispute through informal negotiation within sixty (60) days
                    from the date the Notice of Dispute is sent. After sixty
                    (60) days, you or us may commence arbitration.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Binding Arbitration</h1>
            <p>
                <span>
                    If you and us don’t resolve any dispute by informal
                    negotiation, any other effort to resolve the dispute will be
                    conducted exclusively by binding arbitration as described in
                    this section. You are giving up the right to litigate (or
                    participate in as a party or class member) all disputes in
                    court before a judge or jury. The dispute shall be settled
                    by binding arbitration in accordance with the commercial
                    arbitration rules of the American Arbitration Association.
                    Either party may seek any interim or preliminary injunctive
                    relief from any court of competent jurisdiction, as
                    necessary to protect the party’s rights or property pending
                    the completion of arbitration. Any and all legal,
                    accounting, and other costs, fees, and expenses incurred by
                    the prevailing party shall be borne by the non-prevailing
                    party.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Submissions and Privacy</h1>
            <p>
                <span>
                    In the event that you submit or post any ideas, creative
                    suggestions, designs, photographs, information,
                    advertisements, data or proposals, including ideas for new
                    or improved products, services, features, technologies or
                    promotions, you expressly agree that such submissions will
                    automatically be treated as non- confidential and
                    non-proprietary and will become the sole property of us
                    without any compensation or credit to you whatsoever. We and
                    our affiliates shall have no obligations with respect to
                    such submissions or posts and may use the ideas contained in
                    such submissions or posts for any purposes in any medium in
                    perpetuity, including, but not limited to, developing,
                    manufacturing, and marketing products and services using
                    such ideas.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Promotions</h1>
            <p>
                <span>
                    We may, from time to time, include contests, promotions,
                    sweepstakes, or other activities (“Promotions”) that require
                    you to submit material or information concerning yourself.
                    Please note that all Promotions may be governed by separate
                    rules that may contain certain eligibility requirements,
                    such as restrictions as to age and geographic location. You
                    are responsible to read all Promotions rules to determine
                    whether or not you are eligible to participate. If you enter
                    any Promotion, you agree to abide by and to comply with all
                    Promotions Rules. Additional terms and conditions may apply
                    to purchases of goods or services on or through the
                    Services, which terms and conditions are made a part of this
                    Agreement by this reference.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Typographical Errors</h1>
            <p>
                <span>
                    In the event a product and/or service is listed at an
                    incorrect price or with incorrect information due to
                    typographical error, we shall have the right to refuse or
                    cancel any orders placed for the product and/ or service
                    listed at the incorrect price. We shall have the right to
                    refuse or cancel any such order whether or not the order has
                    been confirmed and your credit card charged. If your credit
                    card has already been charged for the purchase and your
                    order is canceled, we shall immediately issue a credit to
                    your credit card account or other payment account in the
                    amount of the charge.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Miscellaneous</h1>
            <p>
                <span>
                    If for any reason a court of competent jurisdiction finds
                    any provision or portion of these Terms and Conditions to be
                    unenforceable, the remainder of these Terms and Conditions
                    will continue in full force and effect. Any waiver of any
                    provision of these Terms and Conditions will be effective
                    only if in writing and signed by an authorized
                    representative of us. We will be entitled to injunctive or
                    other equitable relief (without the obligations of posting
                    any bond or surety) in the event of any breach or
                    anticipatory breach by you. We operate and control our
                    Service from our offices in . The Service is not intended
                    for distribution to or use by any person or entity in any
                    jurisdiction or country where such distribution or use would
                    be contrary to law or regulation. Accordingly, those persons
                    who choose to access our Service from other locations do so
                    on their own initiative and are solely responsible for
                    compliance with local laws, if and to the extent local laws
                    are applicable. These Terms and Conditions (which include
                    and incorporate our Privacy Policy) contains the entire
                    understanding, and supersedes all prior understandings,
                    between you and us concerning its subject matter, and cannot
                    be changed or modified by you. The section headings used in
                    this Agreement are for convenience only and will not be
                    given any legal import.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1>Disclaimer</h1>
            <p>
                <span>
                    We are not responsible for any content, code or any other
                    imprecision. We do not provide warranties or guarantees. In
                    no event shall we be liable for any special, direct,
                    indirect, consequential, or incidental damages or any
                    damages whatsoever, whether in an action of contract,
                    negligence or other tort, arising out of or in connection
                    with the use of the Service or the contents of the Service.
                    We reserve the right to make additions, deletions, or
                    modifications to the contents on the Service at any time
                    without prior notice.
                    <br />
                    <br />
                    Our Service and its contents are provided "as is" and "as
                    available" without any warranty or representations of any
                    kind, whether express or implied. We are a distributor and
                    not a publisher of the content supplied by third parties; as
                    such, our exercises no editorial control over such content
                    and makes no warranty or representation as to the accuracy,
                    reliability or currency of any information, content, service
                    or merchandise provided through or accessible via our
                    Service. Without limiting the foregoing, We specifically
                    disclaim all warranties and representations in any content
                    transmitted on or in connection with our Service or on sites
                    that may appear as links on our Service, or in the products
                    provided as a part of, or otherwise in connection with, our
                    Service, including without limitation any warranties of
                    merchantability, fitness for a particular purpose or
                    non-infringement of third party rights. No oral advice or
                    written information given by us or any of its affiliates,
                    employees, officers, directors, agents, or the like will
                    create a warranty. Price and availability information is
                    subject to change without notice. Without limiting the
                    foregoing, we do not warrant that our Service will be
                    uninterrupted, uncorrupted, timely, or error-free.
                </span>
            </p>
            <h1>Contact Us</h1>
            <p>
                <span>
                    Don't hesitate to contact us if you have any questions.
                </span>
            </p>
            <ul>
                <li>
                    <span>
                        Via Email:{" "}
                        <a href="https://visitappt.com/terms">
                            {Emails.supportEmail}
                        </a>
                    </span>
                </li>
            </ul>
        </div>
    );
};

const termsRu = () => {
    return (
        <div>
            <h1> Условия использования </h1>{" "}
            <p> Обновлено 8 декабря 2021 г. </p>
            <br /> <h1> Общие условия </h1>
            <p>
                <span>
                    Получая доступ и размещая заказ в{" "}
                    <a href="https://visitappt.com/terms">Visit</a>, вы
                    подтверждаете что вы согласны и связаны условиями услуга,
                    содержащаяся в Условиях, изложенных ниже. Эти условия
                    распространяются на весь веб-сайт и любую электронную почту.
                    или другой вид связи между вами и{" "}
                    <a href="https://visitappt.com/terms">Visit</a>.
                    <br />
                    <br />
                    Ни при каких обстоятельствах команда{" "}
                    <a href="https://visitappt.com/terms">Visit</a> не несет
                    ответственности за любые прямые, косвенные, особые,
                    случайные или косвенные ущерб, включая, помимо прочего,
                    потерю данных или прибыль, возникающая в результате
                    использования или невозможности использования материалы на
                    этом сайте, даже если команда{" "}
                    <a href="https://visitappt.com/terms">Visit</a> или
                    уполномоченный представитель уведомлен о возможности таких
                    повреждений. Если вы используете материалы с этого сайта
                    приводят к необходимости обслуживания, ремонт или
                    исправление оборудования или данных, вы принимаете на себя
                    любые затраты на это.
                    <br />
                    <br /> <a href="https://visitappt.com/terms">Visit</a> не
                    несет ответственности за последствия, которые может
                    произойти в процессе использования наших ресурсов. Мы
                    оставляем за собой право изменять цены и пересматривать
                    ресурсы политика использования в любой момент.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Лицензия </h1>
            <p>
                <span>
                    <a href="https://visitappt.com/terms"> Посещение </a>{" "}
                    предоставляет вам отзывную, неисключительную, не-
                    передаваемая ограниченная лицензия на загрузку, установку и
                    использование наш сервис строго в соответствии с условиями
                    настоящего Соглашение.
                    <br />
                    <br />
                    Эти Положения и условия представляют собой договор между
                    вами и {""}
                    <a href="https://visitappt.com/terms"> Visit </a> (в
                    настоящих Условиях использования именуется "
                    <a href="https://visitappt.com/terms"> Visit </a> "," нас
                    "," мы "или" наш "), поставщика {""}
                    <a href="https://visitappt.com/terms"> Visit </a> веб-сайт и
                    службы, доступные из {""}
                    <a href="https://visitappt.com/terms"> Visit </a> веб-сайт
                    (все вместе упоминаются в эти Положения и условия как
                    «Услуга <a href="https://visitappt.com/terms"> Visit </a>»).
                    <br />
                    <br />
                    Вы соглашаетесь соблюдать настоящие Положения и условия.
                    Если вы не согласны с этими Положениями и условиями,
                    пожалуйста, сделайте не использовать Сервис. В настоящих
                    Условиях использования «вы» относится как к вам как к
                    физическому лицу, так и к организации, которую вы
                    представлять. Если вы нарушите какое-либо из этих Положений
                    и условий, мы оставляем за собой право аннулировать вашу
                    учетную запись или заблокировать доступ в вашу учетную
                    запись без предварительного уведомления.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Определения и ключевые термины </h1>
            <p>
                <span> Для настоящих Условий: </span>
            </p>
            <p>
                <br />
            </p>
            <ul>
                <li>
                    <span>
                        <strong> Cookie: </strong> генерируется небольшой объем
                        данных. через веб-сайт и сохраняется в вашем
                        веб-браузере. Это используется чтобы идентифицировать
                        ваш браузер, предоставлять аналитику, помнить информация
                        о вас, такая как ваши языковые предпочтения или данные
                        для входа.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Компания </strong>: когда в этой политике
                        упоминается «Компания», «мы», «нас» или «наш», это
                        относится к
                        <a href="https://visitappt.com/terms"> Visit </a>,
                        который отвечает за вашу информацию в соответствии с
                        настоящей Политикой конфиденциальности.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Клиент: </strong> означает компанию,
                        организация или лицо, которое подписывается на
                        использование
                        <a href="https://visitappt.com/terms"> Visit </a>{" "}
                        службу, чтобы установить отношения с ваши потребители
                        или пользователей услуг.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Устройство </strong>: любое устройство,
                        подключенное к Интернету. например телефон, планшет,
                        компьютер или любое другое устройство который можно
                        использовать для посещения{" "}
                        <a href="https://visitappt.com/terms"> Посетить </a>
                        и пользоваться услугами.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> IP-адрес: </strong> все устройства,
                        подключенные к Интернету присвоен номер, известный как
                        Интернет протокол (IP) адрес. Эти числа обычно
                        назначается в географических блоках. IP-адрес часто
                        может использоваться для определения местоположения, из
                        которого подключение к Интернету.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Персонал: </strong> относится к этим лицам.
                        которые работают в{" "}
                        <a href="https://visitappt.com/terms"> Visit </a> или
                        работают по контракту выполнить услугу от имени одной из
                        сторон.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Личные данные: </strong> любая информация,
                        прямо, косвенно или в связи с другими информация -
                        включая личный идентификационный номер - позволяет
                        идентифицировать или идентифицировать физическое лицо.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Услуга: </strong> относится к предоставленной
                        услуге.
                        <a href="https://visitappt.com/terms"> Посетить </a>,
                        как описано в относительных условиях (если доступно) и
                        на этой платформе.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Сторонняя служба </strong>: рекламодатели,
                        спонсоры конкурсов, продвижение и маркетинг партнеров и
                        других лиц, которые предоставляют наш контент или чьи
                        продукты или услуги, которые, по нашему мнению, могут
                        вас заинтересовать.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Веб-сайт: </strong>{" "}
                        <a href="https://visitappt.com/terms"> Visit </a>, на
                        котором можно получить по этому URL-адресу:{" "}
                        <a href="https://visitappt.com/terms">
                            {" "}
                            https://visitappt.com{" "}
                        </a>
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Вы: </strong> физическое или юридическое лицо,
                        которое зарегистрировались в{" "}
                        <a href="https://visitappt.com/terms"> Visit </a>, чтобы
                        пользоваться Службами.
                        <br />
                    </span>
                </li>
            </ul>
            <p>
                <br />
            </p>
            <h1> Ограничения </h1>
            <p>
                <span>Вы соглашаетесь не делать и не позволяете другим:</span>
            </p>
            <ul>
                <li>
                    <span>
                        Лицензировать, продавать, сдавать в аренду, передавать,
                        распространять, передавать, размещать, передавать на
                        аутсорсинг, раскрывать или иным образом использовать
                        сервис в коммерческих целях или сделать платформу
                        доступны любой третьей стороне.
                    </span>
                </li>
                <li>
                    <span>
                        Изменять, создавать производные работы, разбирать,
                        расшифровывать, обратная компиляция или
                        реконструирование любой части услуга.
                    </span>
                </li>
                <li>
                    <span>
                        Удалять, изменять или скрывать любые уведомления о
                        правах собственности (включая любое уведомление об
                        авторском праве или товарном знаке) или его
                        аффилированные лица, партнеры, поставщики или лицензиары
                        сервис.
                    </span>
                </li>
            </ul>
            <p>
                <br />
            </p>
            <h1> Оплата </h1>
            <p>
                <span>
                    Если вы зарегистрируетесь в любом из наших планов регулярных
                    платежей, вы согласны оплачивать все сборы или сборы на свой
                    счет за Обслуживание в соответствии с тарифами, сборами и
                    выставлением счетов условия, действующие на момент уплаты
                    каждой комиссии или сбора и к оплате. Если иное не указано в
                    форме заказа, вы должны предоставить нам действующую
                    кредитную карту (Visa, MasterCard или любой другой принятый
                    нами эмитент) («Платеж Провайдер ») в качестве условия
                    подписки на Премиум план. Ваше соглашение с платежным
                    провайдером регулирует использование вами указанный счет
                    кредитной карты, и вы должны обратиться к это соглашение, а
                    не настоящие Условия для определения ваших прав и
                    обязательства по отношению к вашему поставщику платежей. От
                    предоставив нам номер вашей кредитной карты и связанные
                    платежную информацию, вы соглашаетесь с тем, что мы
                    уполномочены немедленно проверить информацию, а затем
                    выставить счет ваша учетная запись для всех сборов и сборов,
                    причитающихся и подлежащих уплате нам по настоящему
                    Соглашению и что никакие дополнительные уведомления или
                    согласия не обязательный. Вы соглашаетесь немедленно
                    уведомлять нас о любых изменениях в вашем платежном адресе
                    или кредитной карте, использованной для оплаты ниже. Мы
                    оставляем за собой право в любое время изменить его цены и
                    способы выставления счетов, либо сразу после публикации на
                    нашем Сайте или по электронной почте в адрес вашей
                    организации администратор (ы). Любые гонорары адвокатам,
                    судебные издержки или другие расходы, понесенные при
                    взыскании непогашенных бесспорных суммы должны быть оплачены
                    вами. Между вами и нами не будет существовать никаких
                    контрактов на использование Сервиса. пока мы не примем ваш
                    заказ подтверждающим электронным письмом, SMS / MMS
                    сообщение или другие подходящие средства связи. Ты несут
                    ответственность за любые сторонние сборы, которые вы можете
                    понести при использовании Сервиса.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Политика возврата и возврата </h1>
            <p>
                <span>
                    Благодарим за покупки с нами. Мы ценим то, что вы любят
                    покупать то, что мы строим. Мы также хотим убедиться у вас
                    будет полезный опыт, пока вы исследуете, оценка и покупка
                    нашей продукции.
                    <br />
                    Как и в любом случае с покупками, есть условия и условия,
                    которые применяются к сделкам в нашей компании. Что ж будьте
                    настолько краткими, насколько позволят наши адвокаты.
                    Главное чтобы помните, что, разместив заказ или совершив
                    покупку от нас, вы соглашаетесь с условиями вместе с нашей
                    Конфиденциальностью Политика.
                    <br />
                    Если по какой-либо причине Вы не полностью удовлетворены
                    любые товары или услуги, которые мы предоставляем, не
                    стесняйтесь свяжитесь с нами и мы обсудим любой интересующий
                    вас вопрос переживает с нашим продуктом.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Ваши предложения </h1>
            <p>
                <span>
                    Любые отзывы, комментарии, идеи, улучшения или предложения
                    (совместно именуемые «Предложения»), предоставленные вами
                    нам вместе с уважение к услуге остается единственной и
                    исключительной собственность нас. Мы можем свободно
                    использовать, копировать, изменять, публиковать или
                    распространять Предложения для любых целей и любым способом
                    без каких-либо кредитов или какой-либо компенсации вам.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Ваше согласие </h1>
            <p>
                <span>
                    Мы обновили наши Условия, чтобы предоставить вам полная
                    прозрачность в том, что происходит при посещении наш сайт и
                    как он используется. Используя наш сервис, регистрируя
                    учетную запись или совершая покупку, вы настоящим
                    соглашаетесь с нашими Условиями использования.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Ссылки на другие веб-сайты </h1>
            <p>
                <span>
                    Наш сервис может содержать ссылки на другие веб-сайты,
                    которые не управляется нами. Если вы нажмете на стороннюю
                    ссылку, вы будут перенаправлены на сайт этой третьей
                    стороны. Мы настоятельно рекомендуем Вам необходимо
                    ознакомиться с Условиями использования каждого сайта, на
                    котором вы визит. Мы не контролируем и не несем
                    ответственности за содержание, Положения и условия или
                    практику любых сторонние сайты или сервисы.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Файлы cookie </h1>
            <p>
                <span>
                    Мы используем файлы cookie, чтобы идентифицировать области
                    нашего веб-сайта, которые вы были в гостях. Cookie - это
                    небольшой фрагмент хранимых данных. на вашем компьютере или
                    мобильном устройстве через ваш веб-браузер. Мы использовать
                    файлы cookie для повышения производительности и
                    функциональности наши услуги, но не являются необходимыми
                    для их использования. Тем не мение, без этих файлов cookie
                    некоторые функции, такие как видео, могут станут недоступны,
                    или вам потребуется ввести свой данные для входа каждый раз,
                    когда вы посещаете нашу платформу, как мы не сможете
                    вспомнить, что вы вошли в систему ранее. В большинстве
                    веб-браузеров можно отключить использование файлов cookie.
                    Однако, если вы отключите файлы cookie, вы не сможете
                    получить доступ к функциям на нашем веб-сайте правильно или
                    вообще. Мы никогда не помещайте в файлы cookie личную
                    информацию.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Изменения в наших условиях </h1>
            <p>
                <span>
                    Вы признаете и соглашаетесь с тем, что мы можем прекратить
                    (навсегда или временно) предоставление Сервиса (или любых
                    функций в Сервис) вам или пользователям в целом на нашем
                    единственном по своему усмотрению, без предварительного
                    уведомления. Вы можете перестать использовать Сервис в любое
                    время. Вам не нужно специально сообщить нам, когда вы
                    прекратите использование Сервиса. Вы признаете и
                    соглашаетесь с тем, что если мы отключим доступ к вашей
                    учетной записи, вы можете быть заблокированным от доступа к
                    Сервису, ваша учетная запись детали или любые файлы или
                    другие материалы, которые содержатся в вашем аккаунте. Если
                    мы решим изменить наши Условия и Условия, мы опубликуем эти
                    изменения на этой странице и / или обновить дату изменения
                    Условий ниже.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Изменения в нашем сервисе </h1>
            <p>
                <span>
                    Мы оставляем за собой право изменять, приостанавливать или
                    прекращать, временно или постоянно, услуга или любая услуга
                    для которые он связывает, с уведомлением или без него и без
                    ответственность перед вами.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Обновления нашей службы </h1>
            <p>
                <span>
                    Время от времени мы можем предоставлять улучшения или
                    улучшения характеристик / функциональности сервиса, который
                    может включать исправления, исправления ошибок, обновления,
                    обновления и другие модификации («Обновления»). Обновления
                    могут изменять или удалить определенные функции и / или
                    функции услуга. Вы соглашаетесь с тем, что мы не обязаны (i)
                    предоставлять какие-либо Обновления, или (ii) продолжать
                    предоставлять или включать любые особенности и / или
                    функциональные возможности службы для ты. Вы также
                    соглашаетесь с тем, что все Обновления будут (i) считаться
                    составлять неотъемлемую часть услуги, и (ii) в соответствии
                    с условиями настоящего Соглашения.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Сторонние службы </h1>
            <p>
                <span>
                    Мы можем отображать, включать или делать доступными
                    сторонние контент (включая данные, информацию, приложения и
                    другие продукты, услуги) или предоставлять ссылки на
                    сторонние веб-сайты или услуги («Сторонние услуги»). Вы
                    признаете и согласны с тем, что мы не несем ответственности
                    за третьих лиц Услуги, включая их точность, полноту,
                    своевременность, действительность, соблюдение авторских
                    прав, законность, порядочность, качество или любой другой их
                    аспект. Мы - нет принимают на себя и не несут
                    ответственности за вы или любое другое физическое или
                    юридическое лицо для Третьей стороны Услуги. Сторонние
                    сервисы и ссылки на них предоставляется исключительно для
                    вашего удобства, и вы получаете доступ и использовать их
                    исключительно на свой страх и риск и с учетом таких третьих
                    условия сторон.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Срок действия и прекращение </h1>
            <p>
                <span>
                    Настоящее Соглашение остается в силе до тех пор, пока не
                    будет расторгнуто ты или мы. Мы можем по собственному
                    усмотрению в любое время и по любой причине или без таковой,
                    приостановить или прекратить действие настоящего Соглашения
                    с предварительным уведомлением или без него. Действие
                    настоящего Соглашения прекращается немедленно, без
                    предварительного уведомления от нас, в случае, если вы не
                    соблюдаете какое-либо положение настоящего Соглашения. Ты
                    также может расторгнуть настоящее Соглашение, удалив услугу
                    и все его копии с вашего компьютера. По окончании настоящего
                    Соглашения, вы должны прекратить любое использование службы
                    и удалите все копии сервиса со своего компьютера.
                    Прекращение действия настоящего Соглашения не ограничивает
                    наши права или средства правовой защиты по закону или по
                    справедливости в случае нарушения вы (в течение срока
                    действия настоящего Соглашения) любой из ваших обязательства
                    по настоящему Соглашению.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Срок действия и прекращение </h1>
            <p>
                <span>
                    Если вы являетесь владельцем авторских прав или его агентом
                    и считают, что любой наш материал является нарушением ваши
                    авторские права, свяжитесь с нами, указав следующая
                    информация: (а) физический или электронный подпись
                    правообладателя или лица, уполномоченного действовать от его
                    имени; (б) идентификация материала, который утверждается,
                    что нарушает авторские права; (c) ваша контактная
                    информация, включая ваш адрес, номер телефона и электронную
                    почту; (г) ваше заявление о том, что вы добросовестно
                    полагаете, что использование материала не разрешено
                    авторским правом собственники; и (e) заявление о том, что
                    информация в уведомление является точным, и, под страхом
                    наказания за лжесвидетельство, вы уполномочены действовать
                    от имени владельца.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Компенсация </h1>
            <p>
                <span>
                    Вы соглашаетесь освободить и удержать нас и наших родителей,
                    дочерние компании, филиалы, должностные лица, сотрудники,
                    агенты, партнеры и лицензиары (если таковые имеются)
                    защищены от каких-либо претензий или требование, включая
                    разумные гонорары адвокатам, из-за или возникшие в
                    результате вашего: (а) использования услуги; (б) нарушение
                    настоящего Соглашения или любого закона или постановления;
                    или (c) нарушение любых прав третьих лиц.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Отсутствие гарантий </h1>
            <p>
                <span>
                    Услуга предоставляется «КАК ЕСТЬ» и «ПО ДОСТУПНОСТИ». и со
                    всеми неисправностями и дефектами без каких-либо гарантий
                    своего рода. В максимальной степени, разрешенной действующим
                    законодательством, мы, от своего имени и от имени наших
                    аффилированных лиц и наши соответствующие лицензиары и
                    поставщики услуг, прямо отказывается от всех гарантий,
                    явных, подразумеваемых, установленный законом или иным
                    образом, в отношении услуги, включая все подразумеваемые
                    гарантии товарности, пригодности для определенной цели,
                    названия и ненарушения прав, и гарантии, которые могут
                    возникнуть в процессе ведения дел, конечно
                    производительности, использования или торговой практики. Без
                    ограничений в отношении вышеизложенного мы не даем никаких
                    гарантий или обязательств, и не делает никаких заявлений о
                    том, что служба соответствовать вашим требованиям, достигать
                    желаемых результатов, быть совместимы или работают с любым
                    другим программным обеспечением, веб-сайтами, системы или
                    службы, работают без перебоев, соответствуют любым стандарты
                    производительности или надежности, или быть безошибочным,
                    или что любые ошибки или дефекты могут или будут исправлены.
                    <br />
                    <br />
                    Не ограничивая вышесказанное, ни мы, ни какой-либо поставщик
                    делает любые заявления или гарантии любого рода, явные или
                    подразумевается: (i) в отношении работы или доступности
                    сервис или информацию, контент и материалы, или продукты,
                    включенные в него; (ii) что услуга будет бесперебойно или
                    безошибочно; (iii) что касается точности, надежность или
                    актуальность любой информации или контента предоставляется
                    через сервис; или (iv) услуга, ее серверы, контент или
                    электронные письма, отправленные от или от имени мы свободны
                    от вирусов, скриптов, троянских коней, червей, вредоносные
                    программы, бомбы замедленного действия или другие
                    вредоносные компоненты. Немного юрисдикции не допускают
                    исключения или ограничения о подразумеваемых гарантиях или
                    ограничениях применимых законные права потребителя, поэтому
                    некоторые или все вышеперечисленное исключения и ограничения
                    могут не относиться к вам.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Ограничение ответственности </h1>
            <p>
                <span>
                    Несмотря на любой ущерб, который вы можете понести, весь
                    ответственность нас и любого из наших поставщиков в
                    соответствии с любым положением настоящего Соглашения и ваше
                    исключительное средство правовой защиты в отношении всех
                    вышеизложенное ограничивается суммой, фактически уплаченной
                    вам за службу. В максимальной степени, разрешенной
                    действующему законодательству, ни при каких обстоятельствах
                    мы или наши поставщики не будем несет ответственность за
                    любые особые, случайные, косвенные или косвенный ущерб
                    (включая, но не ограничиваясь к, убытки за упущенную выгоду,
                    за потерю данных или другие информация, о прерывании
                    бизнеса, о травмах, за потерю конфиденциальности, возникшую
                    в результате или каким-либо образом связанную с
                    использование или невозможность использования службы,
                    сторонней программное обеспечение и / или стороннее
                    оборудование, используемое с услугой, или иным образом в
                    связи с любым положением настоящего Соглашение), даже если
                    мы или какой-либо поставщик были уведомлены о возможность
                    таких повреждений, и даже если средство правовой защиты не
                    сработает своего основного назначения. Некоторые штаты /
                    юрисдикции не разрешить исключение или ограничение случайных
                    или косвенные убытки, поэтому указанное выше ограничение или
                    исключение может не относиться к вам.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Делимость </h1>
            <p>
                <span>
                    Если какое-либо положение настоящего Соглашения считается не
                    имеющий исковой силы или недействительный, такое положение
                    будет изменено и интерпретируется для достижения целей
                    такого положения в максимально возможной степени в
                    соответствии с действующим законодательством и остальные
                    положения сохранят полную силу.
                    <br />
                    <br />
                    Настоящее Соглашение вместе с Политикой конфиденциальности и
                    любыми другими другие юридические уведомления,
                    опубликованные нами в Сервисах, должны составляют полное
                    соглашение между вами и нами относительно Услуг. Если
                    какое-либо положение настоящего Соглашения признан
                    недействительным судом компетентной юрисдикции,
                    недействительность такого положения не влияет на
                    действительность остальных положений настоящего Соглашения,
                    которые должны остаются в полной силе и действии. Нет отказа
                    от каких-либо условий настоящее Соглашение считается
                    дальнейшим или продолжающимся отказ от такого условия или
                    любого другого условия, и наша неспособность заявлять о
                    каких-либо правах или положениях настоящего Соглашения не
                    должны представляют собой отказ от такого права или
                    положения. ВЫ И НАС СОГЛАШАЕТЕСЬ, ЧТО ЛЮБАЯ ПРИЧИНА
                    ДЕЙСТВИЙ, ВЫЗЫВАЮЩАЯСЯ ИЛИ СВЯЗАННАЯ С УСЛУГИ ДОЛЖНЫ НАЧАТЬ
                    В ТЕЧЕНИЕ ОДНОГО (1) ГОДА ПОСЛЕ ПРИЧИНА ДЕЙСТВИЙ НАЧИСЛЕНИЕ.
                    ИНАЧЕ, ТАКОЙ ПРИЧИНОЙ ДЕЙСТВИЯ ЯВЛЯЕТСЯ ПОСТОЯННО ОБОЛОЧЕНО.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Отказ </h1>
            <p>
                <span>
                    За исключением случаев, предусмотренных в настоящем
                    документе, неиспользование права или требовать исполнения
                    обязательства по этому Соглашение не влияет на способность
                    стороны осуществлять такое право или требовать такого
                    исполнения в любое время после этого отказ от нарушения не
                    может считаться отказ от любого последующего нарушения.
                    <br />
                    <br />
                    Никаких перерывов в тренировках и никаких задержек в
                    тренировках на часть любой стороны, любое право или любая
                    власть в соответствии с этим Соглашение действует как отказ
                    от этого права или полномочий. Ни одноразовое, ни частичное
                    осуществление какого-либо права или полномочия по настоящему
                    Соглашению препятствуют дальнейшему осуществлению этого или
                    любое другое право, предоставленное здесь. В случае конфликт
                    между настоящим Соглашением и любой применимой покупкой или
                    другие условия, условия настоящего Соглашения имеют
                    преимущественную силу.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Поправки к настоящему Соглашению </h1>
            <p>
                <span>
                    Мы оставляем за собой право по собственному усмотрению
                    изменять или заменить настоящее Соглашение в любое время.
                    Если ревизия материал мы предоставим уведомление как минимум
                    за 30 дней до любые новые условия, вступающие в силу. Что
                    представляет собой материал изменение будет определяться по
                    нашему собственному усмотрению. От продолжать получать
                    доступ или использовать наш сервис после любых изменений
                    вступают в силу, вы соглашаетесь соблюдать пересмотренные
                    условия. Если вы не согласны с новыми условиями, вы не
                    являетесь больше не авторизован использовать наш сервис.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Полнота соглашения </h1>
            <p>
                <span>
                    Соглашение представляет собой полное соглашение между вами.
                    и нас в отношении использования вами службы и заменяет все
                    предшествующие и одновременные письменные или устные
                    соглашения между ты и мы. На вас могут распространяться
                    дополнительные условия и условия, которые применяются при
                    использовании или покупке других услуги от нас, которые мы
                    предоставим вам в то время такого использования или покупки.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Обновления наших условий </h1>
            <p>
                <span>
                    Мы можем изменить нашу Службу и политику, и нам может
                    потребоваться вносить изменения в настоящие Условия, чтобы
                    они точно отражали наш Сервис и политика. Если иное не
                    предусмотрено законом, мы уведомим вас (например, через наш
                    Сервис) перед мы вносим изменения в настоящие Условия и даем
                    вам возможность рассмотреть их до того, как они вступят в
                    силу. Тогда, если вы продолжать использовать Сервис, вы
                    будете связаны обновленные Условия. Если вы не хотите
                    соглашаться на эти или какие-либо обновленные Условия, вы
                    можете удалить свою учетную запись.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Интеллектуальная собственность </h1>
            <p>
                <span>
                    Наша платформа и все ее содержимое, функции и
                    функциональность (включая, помимо прочего, всю информацию,
                    программное обеспечение, текст, дисплеи, изображения, видео
                    и аудио, а также дизайн, их выбор и расположение),
                    принадлежат нам, его лицензиары или другие поставщики таких
                    материалов и защищены международным авторским правом,
                    товарным знаком, патентом, коммерческая тайна и другая
                    интеллектуальная собственность или собственность законы о
                    правах. Материал нельзя копировать, изменять,
                    воспроизводится, загружается или распространяется любым
                    способом в целом или частично, без явного предварительного
                    письменного разрешения нам, если и за исключением случаев,
                    прямо предусмотренных в настоящих Условия и положения. Любое
                    несанкционированное использование материала запрещено.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Соглашение об арбитраже </h1>
            <p>
                <span>
                    Этот раздел применяется к любому спору, ЗА ИСКЛЮЧЕНИЕМ ЭТОГО
                    ВКЛЮЧАЙТЕ СПОР, КАСАЮЩИЙСЯ ПРЕТЕНЗИИ ОБ ИНЪЮНКТАХ ИЛИ РАВНАЯ
                    ПОМОЩЬ ОТНОСИТЕЛЬНО ПРИНЯТИЯ ИЛИ ДЕЙСТВИТЕЛЬНОСТИ ПРАВА НА
                    ИНТЕЛЛЕКТУАЛЬНУЮ СОБСТВЕННОСТЬ ВАШЕГО ИЛИ Visit. Срок «Спор»
                    означает любой спор, действие или другое разногласие. между
                    вами и нами в отношении Услуг или этого соглашение, будь то
                    в контракте, гарантии, деликте, статуте, постановление,
                    постановление или любое другое юридическое или справедливое
                    основание. «Спор» будет иметь самое широкое значение.
                    разрешено законом.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Уведомление о споре </h1>
            <p>
                <span>
                    В случае спора вы или мы должны предоставить другому
                    Уведомление о споре, которое представляет собой письменное
                    заявление, устанавливающее укажите имя, адрес и контактную
                    информацию сторона, давшая это, факты, послужившие поводом
                    для спора, и запрошенная помощь. Вы должны отправить любое
                    уведомление о споре по электронной почте:. Мы отправим вам
                    любое уведомление о споре отправьте письмо на ваш адрес,
                    если он у нас есть, или иным образом на ваш адрес
                    электронной почты. Вы и мы постараемся решить любые спор
                    через неформальные переговоры в течение шестидесяти (60)
                    дней с даты отправки Уведомления о споре. После шестидесяти
                    (60) дней, вы или мы можем начать арбитраж.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Обязательный арбитраж </h1>
            <p>
                <span>
                    Если вы и мы не решим спор путем неофициальной переговоры,
                    любые другие усилия по разрешению спора будут проводится
                    исключительно путем обязательного арбитража, как описано в
                    эта секция. Вы отказываетесь от права на судебный процесс
                    (или участвовать в качестве члена группы или класса) все
                    споры в суд перед судьей или присяжными. Спор подлежит
                    разрешению путем обязательного арбитража в соответствии с
                    коммерческим арбитражные правила Американской арбитражной
                    ассоциации. Любая из сторон может добиваться временного или
                    предварительного судебного запрета. судебная защита от
                    любого суда компетентной юрисдикции, поскольку необходимо
                    для защиты прав стороны или собственности в ожидании
                    завершение арбитража. Все без исключения законные,
                    бухгалтерский учет и другие расходы, сборы и расходы,
                    понесенные выигравшая сторона несет не преобладающую
                    вечеринка.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Материалы и конфиденциальность </h1>
            <p>
                <span>
                    Если вы отправляете или публикуете какие-либо идеи,
                    творческие предложения, дизайн, фотографии, информация,
                    рекламные объявления, данные или предложения, включая идеи
                    для новых или улучшенные продукты, услуги, функции,
                    технологии или рекламных акций, вы прямо соглашаетесь с тем,
                    что такие материалы будут автоматически считаться
                    неконфиденциальными и не является собственностью и станет
                    нашей исключительной собственностью без какой-либо
                    компенсации или кредита вам вообще. Мы и наши аффилированные
                    лица не имеют никаких обязательств в отношении такие
                    материалы или публикации и могут использовать идеи,
                    содержащиеся в такие материалы или публикации для любых
                    целей на любом носителе в бессрочность, включая, но не
                    ограничиваясь, развитие, производство и маркетинг продуктов
                    и услуг с использованием такие идеи.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Акции </h1>
            <p>
                <span>
                    Время от времени мы можем включать конкурсы, акции,
                    розыгрыши или другие мероприятия («Акции»), требующие вы
                    должны предоставить материалы или информацию о себе.
                    Обратите внимание, что все Акции могут регулироваться
                    отдельными правила, которые могут содержать определенные
                    квалификационные требования, такие как ограничения по
                    возрасту и географическому положению. Ты обязаны прочитать
                    все правила рекламных акций, чтобы определить имеете ли вы
                    право на участие. Если вы войдете любой Акции, вы
                    соглашаетесь соблюдать и соблюдать все Правила акций. Могут
                    применяться дополнительные условия. к покупкам товаров или
                    услуг на или через Услуги, положения и условия которых
                    являются частью настоящего Согласие по этой ссылке.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Типографические ошибки </h1>
            <p>
                <span>
                    В случае, если продукт и / или услуга указаны на неверная
                    цена или неверная информация из-за опечатка, мы имеем право
                    отказать или отменить любые заказы, размещенные на продукт и
                    / или услугу указаны по неверной цене. Мы будем иметь право
                    отказаться или отменить любой такой заказ, независимо от
                    того, был подтвержден, и с вашей кредитной карты снята
                    оплата. Если ваш кредит с карты уже была снята оплата за
                    покупку и ваш заказ отменен, сразу оформим кредит на счет
                    вашей кредитной карты или другой платежный счет в размер
                    заряда.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Разное </h1>
            <p>
                <span>
                    Если по какой-либо причине суд компетентной юрисдикции
                    сочтет любое положение или часть настоящих Положений и
                    условий должны быть неисполнимой, оставшаяся часть настоящих
                    Условий продолжит действовать в полную силу. Любой отказ от
                    любых положения настоящих Условий будут действовать только
                    если письменно и подписано уполномоченным представитель нас.
                    Мы будем иметь право на судебный запрет или другие средства
                    правовой защиты по справедливости (без обязательств по
                    отправке любой залог или поручительство) в случае любого
                    нарушения или упреждающее нарушение вами. Мы работаем и
                    контролируем наши Сервис из наших офисов в г. Сервис не
                    предназначен для распространения или использования любым
                    физическим или юридическим лицом в любом юрисдикция или
                    страна, в которой такое распространение или использование
                    будет противоречить закону или постановлению.
                    Соответственно, эти лица кто выбирает доступ к нашему
                    Сервису из других мест, делают это по собственной инициативе
                    и несут полную ответственность за соблюдение местных
                    законов, если и в той мере, в какой местные законы
                    применимы. Эти Положения и условия (которые включают и
                    включить нашу Политику конфиденциальности) содержит все
                    понимание и отменяет все предыдущие договоренности, между
                    вами и нами в отношении его предмета, и не может быть
                    изменены или модифицированы вами. Заголовки разделов,
                    используемые в это Соглашение предназначено только для
                    удобства и не будет учитывая любой легальный импорт.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Заявление об ограничении ответственности </h1>
            <p>
                <span>
                    Мы не несем ответственности за любой контент, код или любые
                    другие неточность. Мы не даем никаких гарантий. В ни при
                    каких обстоятельствах мы не несем ответственности за
                    какие-либо особые, прямые, косвенный, косвенный или
                    случайный ущерб или любой любые убытки, будь то в результате
                    действия контракта, небрежность или другой деликт, возникший
                    из или в связи с использованием Сервиса или содержимого
                    Сервиса. Мы оставляем за собой право вносить дополнения,
                    удаления или изменения содержания Сервиса в любое время без
                    предварительного уведомления.
                    <br />
                    <br />
                    Наш Сервис и его содержимое предоставляются «как есть» и
                    «как есть». доступны "без каких-либо гарантий или заявлений
                    о каких-либо вид, явный или подразумеваемый. Мы дистрибьютор
                    и не издатель контента, предоставленного третьими сторонами;
                    в виде Таким образом, мы не можем редактировать такой
                    контент и не дает никаких гарантий или заверений
                    относительно точности, надежность или актуальность любой
                    информации, контента, услуги или товары, предоставленные или
                    доступные через наши Услуга. Не ограничивая вышеизложенное,
                    мы специально отказываемся от всех гарантий и заявлений в
                    любом содержании передается на нашем Сервисе или в связи с
                    ним или на сайтах которые могут отображаться в виде ссылок в
                    нашем Сервисе или в продуктах предоставляется как часть или
                    иным образом в связи с нашим Сервис, включая, помимо
                    прочего, любые гарантии товарность, пригодность для
                    определенной цели или ненарушение прав третьих лиц. Никаких
                    устных советов или письменная информация, предоставленная
                    нами или любым из ее аффилированных лиц, сотрудники,
                    должностные лица, директора, агенты и т. п. будут создать
                    гарантию. Информация о цене и наличии есть Может быть
                    изменено без уведомления. Не ограничивая вышеизложенного, мы
                    не гарантируем, что наш Сервис будет бесперебойный,
                    исправный, своевременный или безошибочный.
                </span>
            </p>
            <h1> Свяжитесь с нами </h1>
            <p>
                <span>
                    Не стесняйтесь обращаться к нам, если у вас есть какие-либо
                    вопросы.
                </span>
            </p>
            <ul>
                <li>
                    <span>
                        По электронной почте:{" "}
                        <a href="https://visitappt.com/terms">
                            {" "}
                            {Emails.supportEmail}{" "}
                        </a>
                    </span>
                </li>
            </ul>
        </div>
    );
};

const termsUk = () => {
    return (
        <div>
            <h1> Умови використання </h1>
            {""}
            <p> Оновлено 8 грудня 2021 р. </p>
            <br /> <h1> Загальні умови </h1>
            <p>
                <span>
                    Отримуючи доступ та розміщуючи замовлення в{" "}
                    <a href="https://visitappt.com/terms">Visit</a>, ви
                    підтверджуєте що ви згодні та пов'язані умовами послуга, що
                    міститься в Умови, викладені нижче. Ці умови поширюються на
                    весь веб-сайт та будь-яку електронну пошту. або інший вид
                    зв'язку між вами та{" "}
                    <a href="https://visitappt.com/terms">Visit</a>.
                    <br />
                    <br />
                    За жодних обставин команда{" "}
                    <a href="https://visitappt.com/terms">Visit</a> не несе
                    відповідальності за будь-які прямі, непрямі, особливі,
                    випадкові або непрямі збитки, включаючи, крім іншого, втрату
                    даних або прибуток, що виникає в результаті використання або
                    неможливості використання матеріали на цьому сайті, навіть
                    якщо команда <a href="https://visitappt.com/terms">Visit</a>{" "}
                    або уповноважений представника повідомлено про можливість
                    таких пошкоджень. Якщо ви використовуєте матеріали з цього
                    сайту призводять до необхідності обслуговування, ремонт чи
                    виправлення обладнання або даних, ви берете на себе будь-які
                    витрати на це.
                    <br />
                    <br /> <a href="https://visitappt.com/terms">Visit</a> не
                    несе відповідальності за наслідки, які може статися в
                    процесі використання наших ресурсів. Ми залишаємо за собою
                    право змінювати ціни та переглядати ресурси політика
                    використання будь-якої момент.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Ліцензія </h1>
            <p>
                <span>
                    <a href="https://visitappt.com/terms"> Відвідування </a>{" "}
                    надає вам відгук, невиключна, не- передана обмежена ліцензія
                    на завантаження, встановлення та використання наш сервіс
                    суворо в відповідно до умов цієї Угоди.
                    <br />
                    <br />
                    Ці Положення та умови являють собою договір між вами та {""}
                    <a href="https://visitappt.com/terms"> Visit </a> (в цих
                    Умовах використання називається "
                    <a href="https://visitappt.com/terms"> Visit </a> "," нас
                    "," ми "або" наш "), постачальника {""}
                    <a href="https://visitappt.com/terms"> Visit </a> веб-сайт
                    та служби, доступні з {""}
                    <a href="https://visitappt.com/terms"> Visit </a> веб-сайт
                    (усі разом згадуються в ці Положення та умови як «Послуга{" "}
                    <a href="https://visitappt.com/terms"> Visit </a>»).
                    <br />
                    <br />
                    Ви погоджуєтесь дотримуватися цих Положень та умов. Якщо ви
                    не погоджуєтесь з цими Положеннями та умовами, будь ласка,
                    зробіть не використовувати сервіс. У справжніх Умови
                    використання «ви» ставляться як до вас як до фізичній особі,
                    так і до організації, яку ви представляти. Якщо ви порушите
                    якесь із цих Положень та умов, ми залишаємо за собою право
                    анулювати вашу обліковий запис або заблокувати доступ до
                    вашого облікового запису запис без попереднього
                    повідомлення.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Визначення та ключові терміни </h1>
            <p>
                <span> Для цих Умов: </span>
            </p>
            <p>
                <br />
            </p>
            <ul>
                <li>
                    <span>
                        <strong>Cookie:</strong> генерується невеликий обсяг
                        даних. через веб-сайт і зберігається у вашому
                        веб-браузер. Це використовується для ідентифікації ваш
                        браузер, надавати аналітику, пам'ятати інформація про
                        вас, така як ваші мовні уподобання або дані для входу.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Компанія</strong>: коли в цій політиці
                        згадується "Компанія", "ми", "нас" або "наш", це
                        відноситься до
                        <a href="https://visitappt.com/terms"> Visit </a>, який
                        відповідає за вашу інформацію відповідно до цієї
                        Політики конфіденційності.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Клієнт: </strong> означає компанію, організація
                        або особа, яка підписується на використання
                        <a href="https://visitappt.com/terms"> Visit </a>{" "}
                        службу, щоб встановити відносини з ваші споживачі чи
                        користувачів послуг.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Пристрій</strong>: будь-який пристрій,
                        підключене до Інтернету. наприклад телефон, планшет,
                        комп'ютер або будь-який інший пристрій який можна
                        використовувати для відвідування{" "}
                        <a href="https://visitappt.com/terms"> Відвідати </a>
                        та користуватися послугами.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> IP-адреса: </strong> всі пристрої, підключені
                        до Інтернету надано номер, відомий як Інтернет-протокол
                        (IP) адресу. Ці числа зазвичай призначається у
                        географічних блоках. IP-адреса часто може
                        використовуватися для визначення місця розташування,
                        якого підключення до Інтернету.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Персонал: </strong> відноситься до цих осіб.
                        які працюють у{" "}
                        <a href="https://visitappt.com/terms"> Visit </a> або
                        працюють по договору виконати послугу від імені однієї
                        із сторін.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Особисті дані: </strong> будь-яка інформація,
                        прямо, побічно чи у зв'язку з іншими інформація -
                        включаючи особистий ідентифікаційний номер - дозволяє
                        ідентифікувати або ідентифікувати фізичну особу.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong>Послуга:</strong> відноситься до наданої
                        послуги.
                        <a href="https://visitappt.com/terms"> Відвідати </a>,
                        як описано у відносних умовах (якщо є) і на цій
                        платформі.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Стороння служба </strong>: рекламодавці,
                        спонсори конкурсів, просування та маркетинг партнерів та
                        інших осіб, які надають наш контент чи чиї продукти чи
                        послуги, які, на нашу думку, можуть Вас зацікавити.
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Веб-сайт: </strong>{" "}
                        <a href="https://visitappt.com/terms"> Visit сайт </a>,
                        на яким можна отримати за цією URL-адресою:{" "}
                        <a href="https://visitappt.com/terms">
                            https://visitappt.com
                        </a>
                        <br />
                    </span>
                </li>
                <li>
                    <span>
                        <strong> Ви: </strong> фізична або юридична особа, яке
                        зареєструвалися в{" "}
                        <a href="https://visitappt.com/terms"> Visit </a>, щоб
                        користуватись Службами.
                        <br />
                    </span>
                </li>
            </ul>
            <p>
                <br />
            </p>
            <h1> Обмеження </h1>
            <p>
                <span>Ви погоджуєтесь не робити і не дозволяєте іншим:</span>
            </p>
            <ul>
                <li>
                    <span>
                        Ліцензувати, продавати, здавати в оренду, передавати,
                        поширювати, передавати, розміщувати, передавати на
                        аутсорсинг, розкривати чи іншим чином використовувати
                        сервіс у комерційних цілях або зробити платформу
                        доступні будь-якій третій стороні.
                    </span>
                </li>
                <li>
                    <span>
                        Змінювати, створювати похідні роботи, розбирати,
                        розшифровувати, зворотна компіляція або реконструювання
                        будь-якої частини послуга.
                    </span>
                </li>
                <li>
                    <span>
                        Видаляти, змінювати або приховувати будь-які
                        повідомлення про права власності (включаючи будь-яке
                        повідомлення про авторське право або товарний знак) або
                        його афілійовані особи, партнери, постачальники або
                        ліцензіари Сервіс.
                    </span>
                </li>
            </ul>
            <p>
                <br />
            </p>
            <h1> Оплата </h1>
            <p>
                <span>
                    Якщо ви зареєструєтеся в будь-якому з наших регулярних
                    планів платежів, ви згодні сплачувати всі збори чи збори на
                    свій рахунок за Обслуговування відповідно до тарифів, зборів
                    та виставленням рахунків умови, що діють на момент сплати
                    кожної комісії або збору та до оплати. Якщо інше не вказано
                    формі замовлення, ви повинні надати нам діючу кредитну
                    картку (Visa, MasterCard або будь-який інший прийнятий нами
                    емітент) («Платеж Провайдер») як умова підписки на Преміум
                    план. Ваша угода з платіжним провайдером регулює
                    використання вами зазначений рахунок кредитної картки, і ви
                    повинні звернутися до цієї угоди, а не справжні Умови для
                    визначення ваших прав та зобов'язання щодо вашого
                    постачальника платежів. Від надавши нам номер вашої
                    кредитної картки та пов'язані платіжну інформацію, ви
                    погоджуєтесь з тим, що ми уповноважені негайно перевірити
                    інформацію, а потім виставити рахунок ваш обліковий запис
                    для всіх зборів та зборів, належних та підлягаючих сплаті
                    нам по справжньому Угоди та що жодні додаткові повідомлення
                    або згоди не є обов'язковим. Ви погоджуєтесь негайно
                    повідомляти нас про будь-які зміни у вашій платіжній адресі
                    або кредитної картки, використаної для оплати нижче. Ми
                    залишаємо за собою право в будь-який час змінити його ціни
                    та способи виставлення рахунків, або відразу після
                    публікації на нашому Сайті або електронною поштою на адресу
                    вашої організації адміністратор(и). Будь-які гонорари
                    адвокатам, судові витрати або інші витрати, понесені при
                    стягнення непогашених безперечних сум повинні бути оплачені
                    вами. Між вами і нами не існуватиме жодних контрактів на
                    користування Сервісом. поки ми не приймемо ваш замовлення
                    підтверджуючим електронним листом, SMS/MMS повідомлення або
                    інші відповідні засоби зв'язку. Ти несуть відповідальність
                    за будь-які сторонні збори, які ви можете понести під час
                    використання Сервісу.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Політика повернення та повернення </h1>
            <p>
                <span>
                    Дякуємо за покупки з нами. Ми цінуємо те, що ви любите
                    купувати те, що ми будуємо. Ми також хочемо переконатись у
                    вас буде корисний досвід, поки ви досліджуєте, оцінка та
                    покупка нашої продукції.
                    <br />
                    Як і в будь-якому випадку з покупками, є умови та умови, які
                    застосовуються до угод у нашій компанії. Що ж будьте
                    настільки короткими, як дозволять наші адвокати. Головне щоб
                    пам'ятайте, що, розмістивши замовлення чи здійснивши покупку
                    від нас, ви погоджуєтесь з умовами разом з нашою
                    Конфіденційністю Політика.
                    <br />
                    Якщо з якоїсь причини Ви не повністю задоволені будь-які
                    товари чи послуги, які ми надаємо, не соромтеся зв'яжіться з
                    нами і ми обговоримо будь-який, хто цікавить Вас питання
                    переживає з нашим продуктом.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Ваші пропозиції </h1>
            <p>
                <span>
                    Будь-які відгуки, коментарі, ідеї, покращення або пропозиції
                    (Спільно іменовані «Пропозиції»), надані вами нам разом з
                    повагою до послуги залишається єдиною і виняткову власність
                    нас. Ми можемо вільно використовувати, копіювати, змінювати,
                    публікувати або розповсюджувати Пропозиції для будь-яких
                    цілей та будь-яким способом без будь-яких кредитів або
                    будь-якої компенсації вам.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Ваша згода </h1>
            <p>
                <span>
                    Ми оновили наші Умови, щоб надати вам повну прозорість у
                    тому, що відбувається при відвідуванні наш сайт та як він
                    використається. Використовуючи наш сервіс, реєструючи
                    обліковий запис або купуючи, ви справжнім погоджуєтесь з
                    нашими Умовами використання.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Посилання на інші веб-сайти </h1>
            <p>
                <span>
                    Наш сервіс може містити посилання на інші веб-сайти, які не
                    керується нами. Якщо ви натиснете на сторонню посилання, ви
                    будуть перенаправлені на сайт цієї третьої сторони. Ми
                    настійно рекомендуємо Вам необхідно ознайомитись з Умовами
                    використання кожного сайту, на якому ви візит. Ми не
                    контролюємо та не несемо відповідальності за зміст,
                    Положення та умови або практику будь-яких сторонні сайти чи
                    сервіси.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Файли cookie </h1>
            <p>
                <span>
                    Ми використовуємо файли cookie для ідентифікації області
                    нашого веб-сайту ви були в гостях. Cookie - це невеликий
                    фрагмент даних, що зберігаються. на вашому комп'ютері або
                    мобільний пристрій через ваш веб-браузер. Ми використовувати
                    файли cookie для підвищення продуктивності та
                    функціональності наші послуги, але не є необхідними їх
                    використання. Тим не менше, без цих файлів cookie деякі
                    функції, такі як відео, можуть бути недоступні, або вам
                    потрібно буде ввести свої дані для входу щоразу, коли ви
                    відвідуєте нашу платформу, як ми не зможете згадати, що ви
                    увійшли до системи раніше. У більшості веб-браузерів можна
                    вимкнути за допомогою файлів cookie. Однак, якщо ви вимкнете
                    файли cookie, ви не зможете отримати доступ до функцій на
                    нашому веб-сайті правильно або взагалі. Ми ніколи не
                    поміщайте в файли cookie особисту інформацію.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Зміни в наших умовах </h1>
            <p>
                <span>
                    Ви визнаєте та погоджуєтеся з тим, що ми можемо припинити
                    (назавжди чи тимчасово) надання Сервісу (або будь-яких
                    функцій в Сервіс) вам чи користувачам в цілому на нашому
                    єдиному на свій розсуд, без попереднього повідомлення. Ви
                    можете перестати використовувати Сервіс у будь-яке час. Вам
                    не потрібно спеціально повідомити нас, коли ви припиніть
                    використання Сервісу. Ви визнаєте і погоджуєтесь з тим, що
                    якщо ми відключимо доступ до вашої облікового запису, ви
                    можете бути заблокованим від доступу до Сервісу, ваш
                    обліковий запис деталі або будь-які файли або інші
                    матеріали, які містяться у вашому обліковому записі. Якщо ми
                    вирішимо змінити наші Умови та Умови, ми опублікуємо ці
                    зміни на цій сторінці та/або оновити дату зміни Умов нижче.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Зміни в нашому сервісі </h1>
            <p>
                <span>
                    Ми залишаємо за собою право змінювати, зупиняти або
                    припиняти, тимчасово чи постійно, послуга чи будь-яка
                    послуга для яких він пов'язує, з повідомленням або без нього
                    і без відповідальність перед вами.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Оновлення нашої служби </h1>
            <p>
                <span>
                    Час від часу ми можемо надавати покращення або покращення
                    характеристик / функціональності сервісу, який може включати
                    виправлення, виправлення помилок, оновлення, оновлення та
                    інші модифікації («Оновлення»). Оновлення можуть змінювати
                    або видалити певні функції та/або функція послуга. Ви
                    погоджуєтесь з тим, що ми не зобов'язані (i) надавати
                    будь-які Оновлення, або (ii) продовжувати надавати або
                    включати будь-які особливості та/або функціональні
                    можливості служби для вас. Ви також погоджуєтесь з тим, що
                    всі Оновлення будуть (i) рахуватися складати невід'ємну
                    частину послуги, та (ii) відповідно з умовами цієї Угоди.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Сторонні служби </h1>
            <p>
                <span>
                    Ми можемо відображати, вмикати або робити доступними
                    сторонні контент (включаючи дані, інформацію, програми та
                    інші продукти, послуги) або надавати посилання на сторонні
                    веб-сайти або послуги («Приховані послуги»). Ви визнаєте та
                    згодні з тим, що ми не несемо відповідальності за третіх
                    осіб Послуги, включаючи їх точність, повноту, своєчасність,
                    дійсність, дотримання авторських прав, законність,
                    порядність, якість або будь-який інший їх аспект. Ми - ні
                    приймають на себе і не несуть відповідальності за ви або
                    будь-яке інше фізичне або юридична особа для третьої сторони
                    послуги. Сторонні сервіси та посилання на них надається
                    виключно для вашої зручності, і ви отримуєте доступ і
                    використовувати їх виключно на свій страх і ризик та з
                    урахуванням таких третіх умови сторін.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Строк дії та припинення </h1>
            <p>
                <span>
                    Ця Угода залишається чинною доти, доки буде розірвано ти чи
                    ми. Ми можемо з власного розсуд у будь-який час і з
                    будь-якої причини або без такої, призупинити або припинити
                    дію цієї Угоди із попереднім повідомленням або без нього.
                    Дія цієї Угоди припиняється негайно, без попереднього
                    повідомлення від нас, у разі, якщо ви не дотримуєтеся
                    будь-якого положення цієї Угоди. Ти також може розірвати цю
                    Угоду, видаливши послугу і всі його копії з комп'ютера.
                    Після закінчення цього Угоди, ви повинні припинити будь-яке
                    використання служби та видаліть усі копії сервісу зі свого
                    комп'ютера. Припинення дії цієї Угоди не обмежує наші права
                    або засоби правового захисту за законом або справедливості у
                    разі порушення ви (протягом терміну дії цієї Угоди)
                    будь-який з ваших зобов'язань за цією Угодою.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Строк дії та припинення </h1>
            <p>
                <span>
                    Якщо ви є власником авторських прав або його агентом і
                    вважають, що будь-який наш матеріал є порушенням ваші
                    авторські права, зв'яжіться з нами, вказавши наступна
                    інформація: (а) фізичний або електронний підпис
                    правовласника або особи, уповноваженої діяти від неї імені;
                    (б) ідентифікація матеріалу, що затверджується, що порушує
                    авторські права; (c) ваша контактна інформація, включаючи
                    вашу адресу, номер телефону та електронну пошту; (г) ваша
                    заява про те, що ви сумлінно гадаєте, що використання
                    матеріалу не дозволено авторським правом власники; та (e)
                    заяву про те, що інформація в повідомлення є точним, і, під
                    страхом покарання за лжесвідоцтво, ви уповноважені діяти від
                    імені власника.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Компенсація </h1>
            <p>
                <span>
                    Ви погоджуєтесь звільнити і утримати нас та наших батьків,
                    дочірні компанії, філії, посадові особи, співробітники,
                    агенти, партнери та ліцензіари (якщо такі є) захищені від
                    будь-яких претензій або вимоги, включаючи розумні гонорари
                    адвокатам, через або виникли в внаслідок вашого: (а)
                    використання послуги; (б) порушення цієї Угоди або
                    будь-якого закону або ухвали; або (c) порушення будь-яких
                    прав третіх осіб.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Відсутність гарантій </h1>
            <p>
                <span>
                    Послуга надається «ЯК Є» та «ЗА ДОСТУПНОЮ». і зі усіма
                    несправностями та дефектами без будь-яких гарантій свого
                    роду. максимальною мірою, дозволеною чинним законодавством,
                    ми, від свого імені та від імені наших афілійованих осіб та
                    наші відповідні ліцензіари та постачальники послуг прямо
                    відмовляється від усіх гарантій, явних, які маються на
                    увазі, встановлений законом чи іншим чином, щодо послуги,
                    включаючи всі, що маються на увазі гарантії товарності,
                    придатності для певної мети, назви та непорушення прав, та
                    гарантії, які можуть виникнути у процесі ведення справ,
                    звичайно продуктивність, використання або торгової практики.
                    Без обмежень щодо вищевикладеного ми не даємо жодних
                    гарантій або зобов'язань, і не робить жодних заяв про тому,
                    що служба відповідатиме вашим вимогам, досягати бажаних
                    результатів, бути сумісні або працюють з будь-яким іншим
                    програмним забезпеченням, веб-сайтами, системи або служби,
                    що працюють без перебоїв, відповідають будь-яким стандартам
                    продуктивності або надійності, або бути безпомилковим, або
                    що будь-які помилки чи дефекти можуть бути виправлені.
                    <br />
                    <br />
                    Не обмежуючи вищесказане, ні ми, ні будь-який постачальник
                    робить будь-які заяви або гарантії будь-якого роду, явні або
                    мається на увазі: (i) щодо роботи або доступності сервіс або
                    інформацію, контент та матеріали, або продукти, включені до
                    нього; (ii) що послуга буде безперебійно або безпомилково;
                    (iii) щодо точності, надійності або актуальність будь-якої
                    інформації чи контенту надається через обслуговування; або
                    (iv) послуга, її сервери, контент або електронні листи,
                    надіслані від або від імені, ми вільні від вірусів,
                    скриптів, троянських коней, черв'яків, шкідливі програми,
                    бомби уповільненої дії чи інші шкідливі компоненти Небагато
                    юрисдикції не допускають виключення або обмеження про
                    передбачені гарантії або обмеженнях застосовних законні
                    права споживача, тому деякі або всі вищезазначені винятки та
                    обмеження можуть не ставитись до вас.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Обмеження відповідальності </h1>
            <p>
                <span>
                    Незважаючи на будь-який збиток, який ви можете зазнати, весь
                    відповідальність нас та будь-якого з наших постачальників у
                    відповідно до будь-якого положення цієї Угоди та вашої
                    винятковий засіб правового захисту щодо всіх вищевикладене
                    обмежується сумою, фактично сплаченою вам за службу.
                    Максимально дозволеною чинному законодавству, за жодних
                    обставин ми або наші постачальники не буде несе
                    відповідальності за будь-які особливі, випадкові, непрямі
                    або непрямі збитки (включаючи, але не обмежуючись до, збитки
                    за втрачену вигоду, за втрату даних або інші відомості про
                    переривання бізнесу, про травми, за втрату конфіденційності,
                    що виникла в результаті або якимось чином пов'язану з
                    використання або неможливість використання служби, стороннє
                    програмне забезпечення та/або стороннє обладнання, що
                    використовується з послугою, або іншим чином зв'язку з
                    будь-яким положенням цієї Угоди), навіть якщо ми або
                    будь-який постачальник були повідомлені про можливість таких
                    пошкоджень, і навіть якщо засіб правового захисту не спрацює
                    свого основного призначення. Деякі штати / юрисдикції не
                    дозволити виключення або обмеження випадкових або непрямі
                    збитки, тому зазначене вище обмеження або виняток може не
                    ставитись до вас.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Ділімість </h1>
            <p>
                <span>
                    Якщо будь-яке положення цієї Угоди вважається не має позовну
                    силу або недійсний, таке положення буде змінено та
                    інтерпретується для досягнення цілей такого становища
                    максимально можливою мірою в відповідно до чинного
                    законодавства та інші становища збережуть повну силу.
                    <br />
                    <br />
                    Ця Угода разом з Політикою конфіденційності та будь-якими
                    іншими юридичними повідомленнями, опубліковані нами в
                    Сервісах, повинні становити повне угоду між вами та нами
                    щодо Послуг. Якщо будь-яке положення цієї Угоди визнано
                    недійсним судом компетентної юрисдикції, недійсність такого
                    становища не впливає на дійсність інших положень цієї Угоди,
                    які мають залишатися у повній силі та дії. Ні відмови від
                    будь-яких умов ця Угода вважається подальшим або
                    продовжуючим відмова від такої умови або будь-якої іншої
                    умови, і наша нездатність заявляти про будь-яких прав або
                    положень цієї Угоди не повинні являти собою відмову від
                    такого права або становища. ВИ І НАС ПОГОДЖУЄТЕСЯ, ЩО
                    БУДЬ-ЯКА ПРИЧИНА ДІЙ, ЩО ВИКЛИКАЄ АБО ЗВ'ЯЗАНА З ПОСЛУГИ
                    ПОВИННІ ПОЧАТИ ПРОТЯГОМ ОДНОГО (1) РОКУ ПІСЛЯ ПРИЧИНУ ДІЙ
                    НАЧИСЛЕННЯ. Інакше, такою причиною ДІЇ є ПОСТОЯННО
                    ОБОЛОЧЕНО.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Відмова </h1>
            <p>
                <span>
                    За винятком випадків, передбачених у цьому документ,
                    невикористання права або вимагати виконання зобов'язання
                    щодо цього Угода не впливає на здатність сторони здійснювати
                    таке право або вимагати такого виконання у будь-який час
                    після цього відмова від порушення не може вважатися відмова
                    від будь-якого подальшого порушення.
                    <br />
                    <br />
                    Ніяких перерв у тренуваннях і жодних затримок у тренуваннях
                    на частину будь-якої сторони, будь-яке право або будь-яка
                    влада відповідно до цього Угода діє як відмова від цього
                    права чи повноважень. Ні одноразове, ні часткове здійснення
                    будь-якого права або повноваження по справжньому Угоди
                    перешкоджають подальшому здійсненню цього або будь-яке інше
                    право, надане тут. У разі конфлікт між цією Угодою та
                    будь-якою застосовною покупкою або інші умови, умови цієї
                    Угоди мають переважну силу.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Поправки до цієї Угоди </h1>
            <p>
                <span>
                    Ми залишаємо за собою право на власний розсуд змінювати або
                    замінити цю Угоду у будь-який час. Якщо ревізія матеріал ми
                    надамо повідомлення як мінімум за 30 днів до будь-яких нових
                    умов, що набирають чинності. Що являє собою матеріал зміна
                    буде визначатися за нашому власному розсуду. Від
                    продовжувати отримувати доступ або використовувати наш
                    сервіс після будь-яких змін набувають чинності, ви
                    погоджуєтеся дотримуватись переглянутих умови. Якщо ви не
                    згодні з новими умовами, ви не більше не авторизований
                    використовувати наш сервіс.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Повнота угоди </h1>
            <p>
                <span>
                    Угода є повною угодою між вами. і нас щодо використання вами
                    служби та замінює все попередні та одночасні письмові або
                    усні угоди між ти та ми. На вас можуть поширюватися
                    додаткові умови та умови, що застосовуються при використання
                    або купівлі інших послуг від нас, які ми надамо вам на той
                    час такого використання або покупки.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Оновлення наших умов </h1>
            <p>
                <span>
                    Ми можемо змінити нашу Службу та політику, і нам може
                    потрібно вносити зміни до цих Умов, щоб вони точно відбивали
                    наш Сервіс та політика. Якщо інше не передбачено законом, ми
                    повідомимо вас (наприклад, через наш Сервіс) перед внесенням
                    змін до цих Умов та надаємо вам можливість розглянути їх до
                    того, як вони вступлять у силу. Тоді, якщо ви продовжувати
                    використовувати Сервіс, ви будете пов'язані оновлені Умови.
                    якщо ви не хочете погоджуватися на ці або якісь оновлені
                    Умови, ви Ви можете видалити свій обліковий запис.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Інтелектуальна власність </h1>
            <p>
                <span>
                    Наша платформа та весь її вміст, функції та функціональність
                    (включаючи, крім іншого, всю інформацію, програмне
                    забезпечення, текст, дисплеї, зображення, відео та аудіо, а
                    також дизайн, їх вибір та розташування), належать нам, його
                    ліцензіари або інші постачальники таких матеріалів та
                    захищені міжнародним авторським правом, товарним знаком,
                    патентом, комерційна таємниця та інша інтелектуальна
                    власність або власність закони про правах. Матеріал не можна
                    копіювати, змінювати, відтворюється, завантажується або
                    розповсюджується будь-яким способом в цілому або частково,
                    без явного попереднього письмового дозволу нам, якщо і за
                    винятком випадків, прямо передбачених цими Умовами та
                    положеннями. Будь-яке несанкціоноване використання матеріалу
                    заборонено.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Угода про арбітраж </h1>
            <p>
                <span>
                    Цей розділ застосовується до будь-якої суперечки, ЗА
                    ВИКЛЮЧЕННЯМ ЦЬОГО ВКЛЮЧАЙТЕ СУПЕРЕЧКА, ЩОДО ПРЕТЕНЗІЇ ПРО
                    ІН'ЮНКТИ АБО РІВНА ДОПОМОГА ЩОДО ПРИНЯТТЯ АБО ДІЙСНОСТІ
                    ПРАВА НА ІНТЕЛЕКТУАЛЬНУ ВЛАСНІСТЬ ВАШОГО АБО Visit. Термін
                    «Суперечка» означає будь-яку суперечку, дію чи іншу
                    розбіжність. між вами та нами щодо Послуг або цієї угоди,
                    будь то у контракті, гарантії, делікті, статуті, постанові,
                    постанову чи будь-яку іншу юридичну чи справедливу
                    заснування. «Суперечка» матиме найширше значення. дозволено
                    законом.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Сповіщення про суперечку </h1>
            <p>
                <span>
                    У випадку спору ви або ми маємо надати іншому Повідомлення
                    про суперечку, що є письмовим заяву, яка встановлює вкажіть
                    ім'я, адресу та контактну інформацію сторона, яка дала це,
                    факти, що стали приводом для спору, та запитана допомога. Ви
                    повинні надіслати будь-яке повідомлення про суперечку
                    електронною поштою:. Ми надішлемо вам будь-яке повідомлення
                    про суперечку надішліть лист на вашу адресу, якщо він у нас
                    є, або іншим чином на вашу адресу електронної пошти. Ви і ми
                    постараємося вирішити будь-які суперечки через неформальні
                    переговори протягом шістдесяти (60) днів з дати відправлення
                    Повідомлення про суперечку. Після шістдесяти (60) днів, ви
                    або ми можемо розпочати арбітраж.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Обов'язковий арбітраж </h1>
            <p>
                <span>
                    Якщо ви і ми не вирішимо суперечки шляхом неофіційної
                    переговори, будь-які інші зусилля щодо вирішення спору
                    будуть проводитися виключно шляхом обов'язкового арбітражу,
                    як описано в ця секція. Ви відмовляєтеся від права на
                    судовий процес (або брати участь як член групи або класу)
                    все суперечки до суду перед суддею чи присяжними. Суперечка
                    підлягає дозволу шляхом обов'язкового арбітражу відповідно
                    до комерційним арбітражним правилом Американської
                    арбітражної асоціації. Будь-яка зі сторін може домагатися
                    тимчасового або попередньої судової заборони. судовий захист
                    від будь-якого суду компетентної юрисдикції, оскільки
                    необхідно для захисту прав боку чи власності в очікуванні
                    завершення арбітражу. Усі без винятку законні,
                    бухгалтерський облік та інші витрати, збори та витрати,
                    понесені сторона, що виграла, несе не переважну вечірка.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Матеріали та конфіденційність </h1>
            <p>
                <span>
                    Якщо ви відправляєте або публікуєте будь-які ідеї, творчі
                    пропозиції, дизайн, фотографії, інформація, рекламні
                    оголошення, дані чи пропозиції, включаючи ідеї для нових або
                    покращені продукти, послуги, функції, технології або
                    рекламних акцій, ви прямо погоджуєтесь з тим, що такі
                    матеріали будуть автоматично рахуватися неконфіденційними і
                    не є власністю та стане нашою винятковою власністю без
                    будь-якої компенсації чи кредиту вам взагалі. Ми та наші
                    афілійовані особи не мають жодних зобов'язань щодо таких
                    матеріали або публікації та можуть використовувати ідеї, що
                    містяться в такі матеріали або публікації для будь-яких
                    цілей на будь-якому носії в безстроковість, включаючи, але
                    не обмежуючись, розвиток, виробництво та маркетинг продуктів
                    та послуг з використанням таких ідей.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Акції </h1>
            <p>
                <span>
                    Іноді ми можемо включати конкурси, акції, розіграші або інші
                    заходи («Акції»), які ви вимагали повинні надати матеріали
                    чи інформацію про себе. Зверніть увагу, що всі Акції можуть
                    регулюватися окремими правилами, які можуть містити певні
                    кваліфікаційні вимоги, такі як обмеження щодо віку та
                    географічному положенню. Ти зобов'язаний прочитати всі
                    правила рекламних акцій, щоб визначити чи маєте ви
                    декларація про участь. Якщо ви увійдете будь-якої Акції, ви
                    погоджуєтеся дотримуватись та дотримуватись всіх Правил
                    акцій. Можуть застосовуватись додаткові умови. до покупок
                    товарів або послуг на або через Послуги, положення та умови
                    яких є частиною цієї Згоди за цим посиланням.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Друкарські помилки </h1>
            <p>
                <span>
                    У випадку, якщо продукт та/або послуга вказані на невірну
                    ціна або неправильна інформація через помилку, ми маємо
                    право відмовити або скасувати будь-які замовлення, розміщені
                    на продукті та / або послугу вказано за невірною ціною. Ми
                    матимемо право відмовитися або скасувати будь-яке таке
                    замовлення, незалежно від того, був підтверджений, і з вашої
                    кредитної картки знято оплата. Якщо ваш кредит з картки вже
                    було знято оплату за покупку і ваше замовлення скасовано,
                    відразу оформимо кредит на рахунок вашої кредитної картки
                    або інший платіжний рахунок у розмір заряду.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Різне </h1>
            <p>
                <span>
                    Якщо з якоїсь причини суд має компетентну юрисдикцію визнає
                    будь-яке положення або частину цих Положень та умов повинні
                    бути нездійсненною, частина справжніх Умов продовжить діяти
                    на повну силу. Будь-яка відмова від будь-яких положень цих
                    Умов діятимуть тільки якщо письмово та підписано
                    уповноваженим представником нас. Ми матимемо право на судову
                    заборону чи інші кошти правового захисту ти за
                    справедливістю (без зобов'язань щодо надсилання будь-якої
                    застави або поруки) у разі будь-якого порушення чи
                    попереджувальне порушення вами. Ми працюємо та контролюємо
                    наші Сервіс з наших офісів у м. Сервіс призначений для
                    поширення або використання будь-яким фізичною або юридичною
                    особою в будь-якій юрисдикції або країна, в якій таке
                    поширення чи використання суперечитиме закону чи постанові.
                    Відповідно, ці особи хто обирає доступ до нашого Сервісу з
                    інших місць роблять це з власної ініціативи та несуть повну
                    відповідальність за дотримання місцевих законів, якщо й тією
                    мірою, якою місцеві закони застосовні. Ці Положення та умови
                    (які включають та включити нашу Політику конфіденційності)
                    містить все розуміння і скасовує всі попередні домовленості,
                    між вами та нами щодо його предмета, і не може бути змінені
                    чи модифіковані вами. Заголовки розділів використовувані в
                    цю Угоду призначено тільки для зручності та не буде
                    враховуючи будь-який легальний імпорт.
                </span>
            </p>
            <p>
                <br />
            </p>
            <h1> Заява про обмеження відповідальності </h1>
            <p>
                <span>
                    Ми не несемо відповідальності за будь-який контент, код чи
                    будь-які інші неточність. Ми не даємо жодних гарантій. У
                    жодному разі яких обставин ми не несемо відповідальності за
                    будь-які особливі, прямі, непрямий, непрямий або випадкові
                    збитки або будь-які збитки, будь то в результаті дії
                    контракту, недбалість або інший делікт, що виник або у
                    зв'язку з використанням Сервісу або вмісту Сервіси. Ми
                    залишаємо за собою право вносити доповнення, видалення або
                    зміни змісту Сервісу у будь-який час без попереднього
                    повідомлення.
                    <br />
                    <br />
                    Наш Сервіс та його вміст надаються «як є» та "як є".
                    доступні "без будь-яких гарантій або заяв про будь-який вид,
                    явний чи мається на увазі. Ми дистриб'ютор та не видавець
                    контенту, наданого третіми сторонами; у вигляді Таким чином,
                    ми не можемо редагувати такий контент і не дає жодних
                    гарантій чи запевнень щодо точності, надійності чи
                    актуальності будь-якої інформації, контенту, послуги або
                    товари, надані або доступні через наші послуги. Не обмежуючи
                    вищевикладене, ми спеціально відмовляємося від усіх гарантій
                    та заяв у будь-якому змісті передається на нашому Сервісі
                    або у зв'язку з ним або на сайтах які можуть відображатися у
                    вигляді посилань у нашому Сервісі або в продуктах надається
                    як частина або інакше у зв'язку з нашим Сервіс, включаючи,
                    крім іншого, будь-які гарантії товарність, придатність для
                    певної мети чи непорушення прав третіх осіб. Жодних усних
                    порад або письмова інформація, надана нами або будь-яким з
                    її афілійованих осіб, працівники, посадові особи, директори,
                    агенти тощо створити гарантію. Інформація про ціну та
                    наявність Є Можливо змінено без попередження. Не обмежуючи
                    вищевикладеного, ми не гарантуємо, що наш Сервіс буде
                    безперебійним, справний, своєчасний чи безпомилковий.
                </span>
            </p>
            <h1> Зв'яжіться з нами </h1>
            <p>
                <span>
                    Не соромтеся звертатися до нас, якщо у вас є будь-які
                    питання.
                </span>
            </p>
            <ul>
                <li>
                    <span>
                        Електронною поштою:{" "}
                        <a href="https://visitappt.com/terms">
                            {" "}
                            {Emails.supportEmail}{" "}
                        </a>
                    </span>
                </li>
            </ul>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
    },
}));

export default function TermsConditionsScreen() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="secondary">
                <Tabs value={value} onChange={handleChange} variant="fullWidth">
                    <Tab label="English" />
                    <Tab label="Русский" />
                    <Tab label="Українська" />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {termsEn()}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {termsRu()}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {termsUk()}
            </TabPanel>
        </div>
    );
}
