import React from "react";
import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cssLabel: {
        color: theme.palette.secondary.main,
    },

    cssOutlinedInput: {
        borderRadius: 20,
        "&$cssFocused $notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
        },
    },
    cssOutlinedInputRedHighlighted: {
        color: theme.palette.error.main,
        borderRadius: 20,
        "&$cssFocused $notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
        },
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: "1px",
        borderColor: `${theme.palette.secondary.main} !important`,
    },
}));

export interface TextInputItem {
    value: string;
    error: string;
}

export default function TextInput({
    errorText,
    label,
    value,
    onChange,
    ...props
}) {
    const classes = useStyles();
    return (
        <TextField
            autoComplete={props.autoComplete}
            fullWidth
            label={label}
            InputLabelProps={{
                classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                },
            }}
            value={value}
            onChange={onChange}
            helperText={
                props.infoText !== "" && errorText === ""
                    ? props.infoText
                    : errorText
            }
            InputProps={{
                readOnly: props.readOnly,
                classes: {
                    root: props.inputRedHighlighted
                        ? classes.cssOutlinedInputRedHighlighted
                        : classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                },
            }}
            error={errorText !== ""}
            variant="outlined"
            multiline={props.multiline}
        />
    );
}
